import { Component, OnInit } from "@angular/core";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { CredentialsService } from "src/app/auth/credentials.service";
import { MembersService } from "../members.service";
import {
  NgxNotificationMsgService,
  NgxNotificationStatusMsg,
} from "ngx-notification-msg";

@Component({
  selector: "app-listing",
  templateUrl: "./listing.component.html",
  styleUrls: ["./listing.component.scss"],
})
export class ListingComponent implements OnInit {
  columns = [];
  ColumnMode = ColumnMode;
  _subscriberData: any;
  public membersData: Array<any> = [];
  public response: any;
  public response2: any;

  totalItems = 0;
  page = 1;
  filters: {
    page: number;
    count: number;
    search: string;
    role: string;
    isDeleted: Boolean;
  } = {
    page: 1,
    count: 10,
    search: "",
    role: "",
    isDeleted: false,
  };

  constructor(
    private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private credentialService: CredentialsService,
    private router: Router,
    private memberService: MembersService
  ) {}

  ngOnInit() {
    // this.filters.addedBy = this.credentialService.credentials.id;
    // this.filters.manager_id = this.credentialService.credentials.manager_id;
    if (this._activateRouter.snapshot.params["page"]) {
      this.filters.page = JSON.parse(
        this._activateRouter.snapshot.params["page"]
      );
      this.page = this.filters.page;
      console.log(
        this.filters.page,
        typeof this._activateRouter.snapshot.params["page"]
      );
      Object.assign(this.filters, { page: this.filters.page });
      this.getAllMembers();
    } else {
      this.page = 1;
      this.getAllMembers();
    }
  }

  getAllMembers() {
    this.spinner.show();
    // this.isLoading = true;
    // if (this.role) {
    //   Object.assign(this.filters, { role: this.role });
    // }
    this._subscriberData = this.memberService
      .getAllMembers(this.filters)
      .subscribe(
        (response) => {
          if (response.data.length == 0) {
            this.membersData = [];
            this.totalItems = response.total;
            // this.isLoading = false;
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [response.message],
            });
          } else {
            this.membersData = response.data.map((data) => {
              return {
                id: data._id,
                name: data.fullName,
                email: data.email,
                lastlogin: data.lastlogin,
                date: data.createdAt,
                role:
                  data.role == "user"
                    ? "User"
                    : data.role == "guest"
                    ? "Guest"
                    : data.role == "sub_admin_1"
                    ? "Sub Admin 1"
                    : data.role == "sub_admin"
                    ? "Sub Admin"
                    : data.role == "sub_admin_2"
                    ? "Sub Admin 2"
                    : data.role == "sub_admin_3"
                    ? "Sub Admin 3"
                    : "Admin",
                createdAt: data.createdAt,
                deletedAt: data.deletedAt,
                status: data.status,
              };
            });
            this.totalItems = response.total;
            // this.isLoading = false;
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [error],
          });
        }
      );
  }

  setPage(e) {
    console.log(e);
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: this.page });
    // let route = '/companies/companies/' + this.page;
    // this.router.navigate([route]);
    this.getAllMembers();
  }

  searchValue() {
    this.page = 1;
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getAllMembers();
  }

  clearValue() {
    this.page = 1;
    this.filters.search = "";
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getAllMembers();
  }
  clearRoleValue() {
    this.page = 1;
    this.filters.role = "";
    Object.assign(this.filters, {
      page: this.page,
      role: this.filters.role,
    });
    this.getAllMembers();
  }
  // changeStatus(data, ID) {
  //   let Status = '';
  //   let message = 'Do you want to activate this leave?';
  //   if (data == 'active') {
  //     Status = "deactive";
  //     message = 'Do you want to deactivate this leave?';
  //   } else {
  //     Status = "active";
  //   }

  //   if (confirm(message)) {
  //     this.spinner.show();
  //     this.leaveService.status(ID, 'users', Status).subscribe((res: any) => {
  //       if (res.success) {
  //         this.response2 = res;
  //         this.toastr.success(res.message);
  //         this.getAllMembers();
  //       } else {
  //         this.toastr.error(res.error.message, 'Error');
  //       }
  //       this.spinner.hide();
  //     }, err => {
  //       this.spinner.hide();
  //       this.toastr.error('There are some errors, please try again after some time !', 'Error');
  //     });
  //   }
  // }

  //  /* Function use to remove Crop with crop id */
  //  remove(ID) {
  //   if (confirm("Do you want to cancel this leave?")) {
  //     this.spinner.show();

  //     let obj = {
  //       id: ID,
  //       model: 'users'
  //     }

  //     this.leaveService.deleteRecord(obj).subscribe((res: any) => {
  //       if (res.success) {
  //         this.response = res;
  //         this.toastr.success('Leave Removed Successfully');
  //       } else {
  //         this.toastr.error('Unable to delete at the moment, Please try again later', 'Error');
  //       }
  //       this.getAllMembers();
  //       this.spinner.hide();
  //     }, err => {
  //       this.spinner.hide();
  //       this.toastr.error('There are some errors, please try again after some time !', 'Error');
  //     });
  //   }
  // }

  // edit(ID, type) {
  //   // this.companiesService.actionType.next(type)
  //   // this.companiesService.activePage.next(this.page)
  //   let route = '/leaves/edit/' + ID;
  //   this.router.navigate([route]);
  // }

  // viewUser(leaveId) {
  //   let route = '/leaves/list/' + leaveId + '/' + this.page;
  //   this.router.navigate([route]);
  // }

  view(companyID) {
    // this.companiesService.actionType.next(type)
    let route = "/member/view/" + companyID + "/" + this.page;
    this.router.navigate([route]);
  }
  add() {
    let route = "/member/add/";
    // this.companiesService.actionType.next('active')
    this.router.navigate([route]);
  }
  edit(ID) {
    console.log(ID);
    // this.companiesService.actionType.next(type)
    // this.companiesService.activePage.next(this.page)
    let route = "/member/edit/" + ID + "/" + this.page;
    this.router.navigate([route]);
  }

  /* Function use to remove Crop with crop id */
  deleteRecord(ID) {
    if (confirm("Do you want to delete this member?")) {
      this.spinner.show();

      let obj = {
        id: ID,
      };

      this.memberService.deleteMember(obj).subscribe(
        (res: any) => {
          if (res.success) {
            this.response = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.message],
            });
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.error.message],
            });
          }
          this.getAllMembers();
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: "",
            messages: [err],
          });
        }
      );
    }
  }

  changeStatus(data, ID) {
    let Status = "";
    let message = "Do you want to activate this  member?";
    if (data == "active") {
      Status = "deactive";
      message = "Do you want to deactivate this  member?";
    } else {
      Status = "active";
    }

    if (confirm(message)) {
      this.spinner.show();
      this.memberService.status(ID, "users", Status).subscribe(
        (res: any) => {
          if (res.success) {
            this.response2 = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.message],
            });

            this.getAllMembers();
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: "",
              messages: [res.error.message],
            });
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [err],
          });
        }
      );
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this._subscriberData) {
      this._subscriberData.unsubscribe();
    }
  }
}
