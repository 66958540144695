import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ListingComponent } from "./listing/listing.component";
import { AddUpdateMembersComponent } from "./add-update-members/add-update-members.component";
import { ViewMembersComponent } from "./view-members/view-members.component";
import { AllPostsComponent } from "./all-posts/all-posts.component";

const routes: Routes = [
  {
    path: "",
    data: {
      title: "member",
    },
    children: [
      {
        path: "",
        component: ListingComponent,
        data: {
          title: "List",
        },
      },
      {
        path: "list",
        component: ListingComponent,
        data: {
          title: "List",
        },
      },
      {
        path: "add",
        component: AddUpdateMembersComponent,
        data: {
          title: "List",
        },
      },
      {
        path: "posts/:id",
        component: AllPostsComponent,
        data: {
          title: "List",
        },
      },

      {
        path: "view/:id/:page",
        component: ViewMembersComponent,
        data: {
          title: "view",
        },
      },
      {
        path: "view/:id",
        component: ViewMembersComponent,
        data: {
          title: "view",
        },
      },
      {
        path: "edit/:id",
        component: AddUpdateMembersComponent,
      },
      {
        path: "edit/:id/:page",
        component: AddUpdateMembersComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MembersRoutingModule {}
