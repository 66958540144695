import { Component, OnInit } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CredentialsService } from 'src/app/auth/credentials.service';
import { PointsMngmtService } from '../points-mngmt.service';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {

  columns = [];
  ColumnMode = ColumnMode;
  rankData=[]
  totalRanks=0
  _subscriberData: any;
  public pointsData: Array<any> = [];
  public badgesData: Array<any> = [];
  public response: any;
  public response2: any;
  type='points'
  _host=environment.url

  totalItems=0
  totalBadges=0
  page = 1;
  filters: { page: number; count: number; search: string; role:string,isDeleted:Boolean} = {
    page: 1,
    count: 10,
    search: '',
    role: 'user',
    isDeleted: false
  };

  constructor(
    private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private router: Router,
    private pointsMngmtService: PointsMngmtService
  ) { }

  ngOnInit() {
    // this.filters.addedBy = this.credentialService.credentials.id;
    // this.filters.manager_id = this.credentialService.credentials.manager_id;
    if (this._activateRouter.snapshot.params['page']) {

      this.filters.page = JSON.parse(this._activateRouter.snapshot.params['page']);
      this.page = this.filters.page;
      console.log(this.filters.page, typeof (this._activateRouter.snapshot.params['page']))
      Object.assign(this.filters, { page: this.filters.page });
      this.changeTab(this.type)

      // this.getAllPoints();
    } else {
      this.page = 1
      this.changeTab(this.type)
      // this.getAllPoints();
    }
  }

  getAllPoints() {
    this.spinner.show();
    // this.isLoading = true;
    // if (this.role) {
    //   Object.assign(this.filters, { role: this.role });
    // }
    this._subscriberData = this.pointsMngmtService.getAllPoints(this.filters).subscribe((response) => {
      if (response.data.length == 0) {
        this.pointsData = [];
        this.totalItems = response.total;
        // this.isLoading = false;
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: '',
          messages: [response.message]
       });
      } else {
        this.pointsData = response.data.map(data => {
          let point_related_to=""
          if(data.point_related_to=='ambassador'){
            point_related_to="Ambassador"
          }
          if(data.point_related_to=='modder'){
            point_related_to="Modder"
          }
          if(data.point_related_to=='networker'){
            point_related_to="Networker"
          }
          // if(data.point_related_to=='group_points'){
          //   point_related_to="Group Points"
          // }
          return {
            id: data._id,
            title: data.title,
            action: data.action,
            points: data.points,
            point_related_to:point_related_to,
            createdAt: data.createdAt,
            deletedAt: data.deletedAt,
            status: data.status
          }
        });
        this.totalItems = response.total;
        // this.isLoading = false;
        this.spinner.hide();
      }
    },
    error=>{
      this.spinner.hide();
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: '',
        messages: [error]
     });
    }
    );
  }
  getAllRankPoints() {
    this.spinner.show();
    // this.isLoading = true;
    // if (this.role) {
    //   Object.assign(this.filters, { role: this.role });
    // }
    this._subscriberData = this.pointsMngmtService.getAllRankPoints(this.filters).subscribe((response) => {
      if (response.data.length == 0) {
        this.pointsData = [];
        this.totalItems = response.total;
        // this.isLoading = false;
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: '',
          messages: [response.message]
       });
      } else {
        this.rankData = response.data.map(data => {
          // let point_related_to=""
          // if(data.point_related_to=='ambassador'){
          //   point_related_to="Ambassador"
          // }
          // if(data.point_related_to=='modder'){
          //   point_related_to="Modder"
          // }
          // if(data.point_related_to=='networker'){
          //   point_related_to="Networker"
          // }
          // if(data.point_related_to=='group_points'){
          //   point_related_to="Group Points"
          // }
          return {
            id: data._id,
            name: data.name,
            image: data.image,
            action: data.action,
            gift:data.gift ? data.gift :'NA',
            points: data.points,
            createdAt: data.createdAt,
            deletedAt: data.deletedAt,
            status: data.status
          }
        });
        this.totalRanks = response.total;
        // this.isLoading = false;
        this.spinner.hide();
      }
    },
    error=>{
      this.spinner.hide();
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: '',
        messages: [error]
     });
    }
    );
  }

  getAllBadges() {
    this.spinner.show();
    // this.isLoading = true;
    // if (this.role) {
    //   Object.assign(this.filters, { role: this.role });
    // }
    this._subscriberData = this.pointsMngmtService.getAllBadges(this.filters).subscribe((response) => {
      if (response.data.length == 0) {
        this.badgesData = [];
        this.totalBadges = response.total;
        // this.isLoading = false;
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: '',
          messages: [response.message]
       });
      } else {
        this.badgesData = response.data.map(data => {
          let badge_for = "";
						if (data.badge_for == "ambassador") {
							badge_for = "Ambassador";
						}
						if (data.badge_for == "networker") {
							badge_for = "Networker";
						}
						if (data.badge_for == "modder") {
							badge_for = "Modder";
						}
						if (data.badge_for == "profile_points") {
							badge_for = "Profile Points";
						}
          return {
            id: data._id,
            name: data.name,
            image: data.image,
            action: data.action,
            points: data.points,
            badge_for: badge_for,
            createdAt: data.createdAt,
            deletedAt: data.deletedAt,
            status: data.status
          }
        });
        this.totalBadges = response.total;
        // this.isLoading = false;
        this.spinner.hide();
      }
    },
    error=>{
      this.spinner.hide();
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: '',
        messages: [error]
     });
    }
    );
  }

  setPage(e) {
    console.log(e,"page");
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: e.offset + 1 });
    if(this.type=='badge'){
      this.getAllBadges();
    }
    if(this.type=='points'){
      this.getAllPoints();
    }
    if(this.type=='rank-badge'){
      this.getAllRankPoints();
    }
    // let route = '/companies/companies/' + this.page;
    // this.router.navigate([route]);
    // this.getAllPoints();
  }

  changeTab(type){
    this.type=type
    this.page = 1;
    Object.assign(this.filters, { page: this.page});
    if(type=='badge'){
      this.getAllBadges();
    }
    if(type=='points'){
      this.getAllPoints();
    }
    if(type=='rank-badge'){
      this.getAllRankPoints();
    }
  }

  searchValue() {
    this.page = 1;
    Object.assign(this.filters, { page: this.page, search: this.filters.search });
    // this.getAllPoints();
    this.changeTab(this.type)
  }

  clearValue() {
    this.page = 1;
    this.filters.search = '';
    Object.assign(this.filters, { page: this.page, search: this.filters.search });
    // this.getAllPoints();
    this.changeTab(this.type)
  }

  view(companyID, type) {
    let route = '/points/view/' + companyID + '/' + this.page;
    this.router.navigate([route]);
  }
  add() {
    let route = '/points/add/';
    this.router.navigate([route]);
  }
  edit(ID) {
    console.log(ID);
    let route = '/points/edit/' + ID + '/' + this.page;
    this.router.navigate([route]);
  }
  editBadge(ID) {
    console.log(ID);
    let route = '/points/badge/edit/' + ID + '/' + this.page;
    this.router.navigate([route]);
  }
  editRankBadge(ID) {
    console.log(ID);
    let route = '/points/rank-badge/edit/' + ID + '/' + this.page;
    this.router.navigate([route]);
  }

    /* Function use to remove Crop with crop id */
    deleteRecord(ID) {
      if (confirm("Do you want to delete this point?")) {
        this.spinner.show();
  
        let obj = {
          id: ID,
          model: 'users'
        }
  
        this.pointsMngmtService.deleteRecord(obj).subscribe((res: any) => {
          if (res.success) {
            this.response = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
           });
           
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.error.message]
           });
             }
          this.getAllPoints();
          this.spinner.hide();
        }, err => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
            messages: [err]
         });
        });
      }
    }

    changeStatus(data, ID) {
      let Status = '';
      let message = 'Do you want to activate this  point?';
      if (data == 'active') {
        Status = "deactive";
        message = 'Do you want to deactivate this  member?';
      } else {
        Status = "active";
      }
      let Data={
        id: ID,
        model:'points',
        status:Status
      }
  
      if (confirm(message)) {
        this.spinner.show();
        this.pointsMngmtService.status(Data).subscribe((res: any) => {
          if (res.success) {
            this.response2 = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
           });
           
            this.getAllPoints();
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [res.error.message]
           });
            
          }
          this.spinner.hide();
        }, err => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [err]
         });
            });
      }
    }


  ngOnDestroy(): void {
    if(this._subscriberData){
      this._subscriberData.unsubscribe();
    }
  }
}
