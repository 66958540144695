import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingComponent } from './listing/listing.component';


import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PointsMngmtRoutingModule } from './points-mngmt-routing.module';
import { AddUpdateComponent } from './add-update/add-update.component';
import { ViewComponent } from './view/view.component';
import { BadgeAddUpdateComponent } from './badge-add-update/badge-add-update.component';
import { AddUpdateRankBadgeComponent } from './add-update-rank-badge/add-update-rank-badge.component';

@NgModule({
  declarations: [AddUpdateComponent, ListingComponent, ViewComponent, BadgeAddUpdateComponent, AddUpdateRankBadgeComponent],
  imports: [
    CommonModule,
    PointsMngmtRoutingModule,
     FormsModule, 
     ReactiveFormsModule,
     NgxDatatableModule,
     NgxSpinnerModule
  ]
})
export class PointsMngmtModule { }
