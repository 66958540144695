import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RvCompaniesRoutingModule } from './rv-companies-routing.module';
import { RvCompanyListingComponent } from './rv-company-listing/rv-company-listing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CustomMaterialModule } from '../custom-material/custom-material.module';
import { SharedModule } from '../shared/shared.module';
import { ViewDetailsComponent } from './view-details/view-details.component';


@NgModule({
  declarations: [RvCompanyListingComponent, ViewDetailsComponent],
  imports:[
    CommonModule,
    RvCompaniesRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxSpinnerModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    CustomMaterialModule,
    SharedModule
  ]
})
export class RvCompaniesModule { }
