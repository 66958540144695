import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PinnedPostMgmtRoutingModule } from './pinned-post-mgmt-routing.module';
import { AddUpdatePinnedPostComponent } from './add-update-pinned-post/add-update-pinned-post.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [AddUpdatePinnedPostComponent],
  imports: [
    CommonModule,
    PinnedPostMgmtRoutingModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
  ]
})
export class PinnedPostMgmtModule { }
