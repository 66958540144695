import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../auth.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  @ViewChild("content", { static: false }) private content;
  public loginForm: FormGroup;
  submitted = false;
  _loginObservable: any;
  closeResult: string;
  public otpForm: FormGroup;
  model: any;

  constructor(
    private formBuilder: FormBuilder,
    // notifierService: NotifierService,
    private router: Router,
    private authService: AuthService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService
  ) {
    // this.notifier = notifierService;
    this.createForm();
  }

  createForm() {
    this.loginForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%. +-]+@[a-z0-9.-]+\\.[a-z]{2,4}.$"),
        ],
      ],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  ngOnInit() {}

  onSubmit() {
    this.submitted = true;
    if (!this.loginForm.invalid) {
      this.spinner.show();
      this._loginObservable = this.authService
        .login(this.loginForm.value)
        .subscribe(
          (res) => {
            if (res.success) {
              this.spinner.hide();
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.SUCCESS,
                header: "",
                messages: [res.message],
              });
              this.submitted = false;
              this.open(this.content);
              // this._bs.setUserData(res.data);

              // if(res.data.role.includes('company'))
              // this.router.navigate(['/dashboard']);
              //  this.spinner.hide();
            } else {
              this.spinner.hide();
              console.log("here===", res.error.message);
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.FAILURE,
                header: "",
                messages: [res.error.message],
              });
              // this.toastr.error(res.error.message, 'Error');
              // this.spinner.hide();
            }

            // if (this.rememberMe == true) {
            //   localStorage.setItem("remember", this.adminLoginForm.value.email);
            // } else {
            //   localStorage.removeItem('remember');
            // }
          },
          (error) => {
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: "",
              messages: [error],
            });

            // this.toastr.error(error);
          }
        );
    }
  }

  resendOtp() {
    this.spinner.show();
    this._loginObservable = this.authService
      .login(this.loginForm.value)
      .subscribe(
        (res) => {
          if (res.success) {
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.message],
            });
            this.submitted = false;
            //  this.open(this.content);
            // this._bs.setUserData(res.data);

            // if(res.data.role.includes('company'))
            // this.router.navigate(['/dashboard']);
            //  this.spinner.hide();
          } else {
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: "",
              messages: [res.error.message],
            });
            // this.toastr.error(res.error.message, 'Error');
            // this.spinner.hide();
          }

          // if (this.rememberMe == true) {
          //   localStorage.setItem("remember", this.adminLoginForm.value.email);
          // } else {
          //   localStorage.removeItem('remember');
          // }
        },
        (error) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [error],
          });

          // this.toastr.error(error);
        }
      );
  }
  
  open(content) {
    this.createOtpForm();
    this.model = this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
    });
    this.model.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  get g() {
    return this.otpForm.controls;
  }

  createOtpForm() {
    this.otpForm = this.formBuilder.group({
      otp: ["", Validators.required],
    });
  }

  onSubmitOtp() {
    this.submitted = true;
    if (!this.otpForm.invalid) {
      let data = {
        email: this.loginForm.value.email,
        otp: this.otpForm.value.otp,
      };
      this.spinner.show();
      this._loginObservable = this.authService.submitOtp(data).subscribe(
        (res) => {
          if (res.success) {
            //   this.ngxNotificationMsgService.open({
            //     status: NgxNotificationStatusMsg.SUCCESS,
            //     header: '',
            //     messages: [res.message]
            //  });
            this.submitted = false;
            this.model.close();
            this.router.navigate(["/dashboard"]);
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: "",
              messages: [res.error.message],
            });
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [error],
          });
          // this.spinner.hide();
          // this.toastr.error(error);
        }
      );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy(): void {
    if (this._loginObservable) {
      this._loginObservable.unsubscribe();
    }
  }
}
