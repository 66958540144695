import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PointsMngmtService {

  private _baseUrl = environment.url;

  constructor(
    private httpClient: HttpClient
  ) { }

  /**
* @method
* @name addManager
* @description
* Authenticates the user.
* Request body:json {
       'email': string,
       'firstName': string,
       'lastName': string,
       'mobileNo': integer
* }
* @param context The Add manager parameters.
* @return Promise.
*/
addPoint(context) {
  return this.httpClient.post(this._baseUrl + `add/point`, context).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  );
}

addRank(context) {
  return this.httpClient.post(this._baseUrl + `add/rank`, context).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  );
}

getAllPoints(param?){
  let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl + 'points', { params: params }).pipe(
          map((response: any) => {
            return response;
          }),
          catchError(this.handleError)
        )
}

get(id) {
  return this.httpClient.get(this._baseUrl + 'point?id=' + id).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}

updatePoint(data) {
  return this.httpClient.put(this._baseUrl + `point/update`, data).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  );
}

updateRank(context) {
  return this.httpClient.put(this._baseUrl + `rank/update`, context).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  );
}

getBadge(id) {
  return this.httpClient.get(this._baseUrl + 'badge?id=' + id).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}

getRankBadge(id) {
  return this.httpClient.get(this._baseUrl + 'rank?id=' + id).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}

updateBadge(data) {
  return this.httpClient.put(this._baseUrl + `badge/update`, data).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  );
}
addBadge(context) {
  return this.httpClient.post(this._baseUrl + `add/badge`, context).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  );
}
uploadImage(fileToUpload: File,type) {

  let params = '?modelName='+type
  const formData: FormData = new FormData();
  formData.append('file', fileToUpload, fileToUpload.name);
  return this.httpClient.post(this._baseUrl + 'product/uploadimage?modelName='+type, formData).pipe(
    map((response: any) => {
      console.log(response);
      return response;
    }),
    catchError(this.handleError)
  )
}

removeImage(data,modal){
  return this.httpClient.delete(this._baseUrl + `removeImage?name=`+data+'&model='+modal).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  );
}
getAllBadges(param?){
  let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl + 'badges', { params: params }).pipe(
          map((response: any) => {
            return response;
          }),
          catchError(this.handleError)
        )
}
getAllRankPoints(param?){
  let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl + 'ranks', { params: params }).pipe(
          map((response: any) => {
            return response;
          }),
          catchError(this.handleError)
        )
}
deleteRecord(param?) {
  let params=this.getParams(param)
  return this.httpClient.delete(this._baseUrl + 'point/delete', { params: params }).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}
status(data) {
  return this.httpClient.put(this._baseUrl + 'point/status/change', data ).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}

// deleteRecord(param?) {
//   return this.httpClient.put(this._baseUrl + 'delete', param ).pipe(
//     map((response: any) => {
//       return response;
//     }),
//     catchError(this.handleError)
//   )
// }


// status(id, model, status) {
//   let url = this._baseUrl + 'update/status?id=' + id + '&model=' + model + '&status=' + status;

//   return this.httpClient.put(url, {}).pipe(
//     map((response: any) => {
//       return response;
//     }),
//     catchError(this.handleError)
//   )
// }


getParams(parameters) {
  let params = new HttpParams();
  Object.keys(parameters).map((key) => {
    params = params.set(key, parameters[key]);
  })
  return params;
}

handleError(error: HttpErrorResponse) {
  // console.log(error);
  if (error.error.code == 401) {
    return throwError(error.error.message);
  } else if (error.error.code == 404) {
    return throwError(error.error.message);
  } else if (error.error.code == 400) {
    return throwError(error.error.message);
  }
  return throwError(
    'Something bad happened; please try again later.');
}
}

