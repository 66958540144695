import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { PointsMngmtService } from '../points-mngmt.service';

@Component({
  selector: 'app-add-update',
  templateUrl: './add-update.component.html',
  styleUrls: ['./add-update.component.scss']
})
export class AddUpdateComponent implements OnInit {

  public Form: FormGroup;
  submitted = false;
  _roleObservable:any;
  user:any;
  token:any;
  ID:any;
  userRole:any;
  fileToUpload:any;
  dImg = '/assets/img/placeholder.jpg';
  imageBase:any;
  isEdit = false;
  private _formObservable: any;
  pointId: any;
  headerTitle: string;
  submitTitle: string;
  
  constructor(
    private formBuilder: FormBuilder,
    private router:Router,
    private spinner: NgxSpinnerService,
    private pointsMngmtService: PointsMngmtService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute
  ) {this.createForm(); }

  ngOnInit() {
    
    this.pointId = this._activateRouter.snapshot.params['id'];
    // this.pageNo = this._activateRouter.snapshot.params['page'];

    if (this.pointId) {
      this.Form.controls['title'].disable();
      this.Form.controls['action'].disable();
      this.Form.controls['point_related_to'].disable();
      this.headerTitle = 'Update Point';
      this.submitTitle = 'Update'
      this.spinner.show();
      this.pointsMngmtService.get(this.pointId).subscribe((res: any) => {
        if (res.success) {
        
          this.Form.patchValue({
            title: res.data.title,
            action: res.data.action,
            points: res.data.points,
      point_related_to:res.data.point_related_to

          });
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
         });
        }
        this.spinner.hide();
      },
        error => {
          this.spinner.hide();

          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [error]
         });
        });
    } else {
      this.headerTitle = 'Add Point';
      this.submitTitle = 'Add'
    }

  }


  createForm() {
		this.Form = this.formBuilder.group({
      title: ['', [Validators.required, Validators.pattern(/^((?!\s{2,}).)*$/)]],
      action: ['', [Validators.required, Validators.pattern(/^((?!\s{2,}).)*$/)]],
      points: ['', [Validators.required]],
      point_related_to: ['']
    });
  }
  get f() { return this.Form.controls; }

  onSubmit() {
    this.submitted = true;
    if (!this.Form.invalid) {
      this.spinner.show();
      if(this.pointId){
        let data= this.Form.value;
        data['id'] = this.pointId;
        this._formObservable = this.pointsMngmtService.updatePoint(data).subscribe(res => {
          if (res.success) {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
           });
           this.router.navigate(['/points']);
          } else {
          
           this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
         });
          }     
          this.spinner.hide();   
        },
          error => {
             this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [error]
           });
          }
        );
      }else{
        this._formObservable = this.pointsMngmtService.addPoint(this.Form.value).subscribe(res => {
          if (res.success) {
          
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
           });
           this.router.navigate(['/points']);
          } else {
          
           this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
         });
          }     
          this.spinner.hide();   
        },
          error => {
             this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [error]
           });
          }
        );
      }
     
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this._formObservable){
      this._formObservable.unsubscribe();
    }
  }

}
