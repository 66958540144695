import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class BehaviorService {

    public userData: BehaviorSubject<object> = new BehaviorSubject<object>({});

    constructor() { }


    // setUserData(value) {
    //     let user: object;
    // let userObject = { user: value };
    // console.log(userObject,"fgjkfjkfhjkghjk")
    // this.user.next(userObject);
    // return {};
    // }

    // setUserData(data) {
    //     console.log('user...',data);
    //     this.user.next(data);
    // }
    setUserData(data) {
        this.userData.next(data);
    }

    getUserData() {
        return this.userData.asObservable();
    }
    unsetUser() {
        this.userData.next({});
        return {};
      }

   

   


}
