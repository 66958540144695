import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddUpdateCategoryComponent } from './add-update-category/add-update-category.component';
import { ListingCategoryComponent } from './listing-category/listing-category.component';
import { ViewCategoryComponent } from './view-category/view-category.component';


const routes: Routes = [
  {
    path: '',
    data: {
      title: 'category'
    },
    children: [
     {
        path: '',
        component: ListingCategoryComponent,
        data: {
          title: 'List'
        }
      },
      {
        path: 'list',
        component: ListingCategoryComponent,
        data: {
          title: 'List'
        }
      },
         {
        path: 'add',
        component: AddUpdateCategoryComponent,
        data: {
          title: 'List'
        }
      },
      {
        path: 'view/:id/:page',
        component: ViewCategoryComponent,
        data: {
          title: 'view'
        }
      },
      {
        path: 'edit/:id',
        component: AddUpdateCategoryComponent
      },{
        path: 'edit/:id/:page',
        component: AddUpdateCategoryComponent
      }
      ]
      }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CategoryRoutingModule { }
