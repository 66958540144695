import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MembersService {

  private _baseUrl = environment.url;

  constructor(
    private httpClient: HttpClient
  ) { }

  /**
* @method
* @name addManager
* @description
* Authenticates the user.
* Request body:json {
       'email': string,
       'firstName': string,
       'lastName': string,
       'mobileNo': integer
* }
* @param context The Add manager parameters.
* @return Promise.
*/
addMmember(context) {
  return this.httpClient.post(this._baseUrl + `add/user`, context).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  );
}

getAllMembers(param?){
  let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl + 'users', { params: params }).pipe(
          map((response: any) => {
            return response;
          }),
          catchError(this.handleError)
        )
}

get(id) {
  return this.httpClient.get(this._baseUrl + 'admin/userDetail?id=' + id).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}

updateMmember(data) {
  return this.httpClient.put(this._baseUrl + `editProfile`, data).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  );
}

getWithParams(path, param?) {
  let params = this.getParams(param);
  return this.httpClient.get(this._baseUrl + path, { params: params }).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  );
}
deleteRecord(param?) {
  // let params = new HttpParams();
  // if (param) {
  //   for (let key of Object.keys(param)) {
  //     params = params.set(key, param[key])
  //   }
  // }
  return this.httpClient.put(this._baseUrl + 'delete', param ).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}
  deleteMember(param?) {
  let params = new HttpParams();
  if (param) {
    for (let key of Object.keys(param)) {
      params = params.set(key, param[key])
    }
  }
  return this.httpClient.delete(this._baseUrl + 'user/delete', { params: params } ).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
  }

  deletePost(param?) {
  let params = new HttpParams();
  if (param) {
    for (let key of Object.keys(param)) {
      params = params.set(key, param[key])
    }
  }
  return this.httpClient.get(this._baseUrl + 'post/delete', {params:params} ).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}

deleteGroupPost(param?) {
  let params = new HttpParams();
  if (param) {
    for (let key of Object.keys(param)) {
      params = params.set(key, param[key])
    }
  }
  return this.httpClient.delete(this._baseUrl + 'group/post/delete', {params:params} ).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}
  deleteModPost(param?) {
  let params = new HttpParams();
  if (param) {
    for (let key of Object.keys(param)) {
      params = params.set(key, param[key])
    }
  }
  return this.httpClient.delete(this._baseUrl + 'modpost/delete', {params:params} ).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}
  getallPosts(param?) {
    let params = this.getParams(param);
    return this.httpClient
      .get(this._baseUrl + "friendprofile/modposts", { params: params })
      .pipe(
        map((response: any) => {
          // this.credentialsService.setCredentials(response.data);
          return response;
        }),
        catchError(this.handleError)
      );
  }

status(id, model, status) {
  let url = this._baseUrl + 'update/status?id=' + id + '&model=' + model + '&status=' + status;

  return this.httpClient.put(url, {}).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}


getParams(parameters) {
  let params = new HttpParams();
  Object.keys(parameters).map((key) => {
    params = params.set(key, parameters[key]);
  })
  return params;
}

getQuotes(param?) {
  let params = this.getParams(param);
  return this.httpClient
    .get(this._baseUrl + "quoteforms", { params: params })
    .pipe(
      map((response: any) => {
        // this.credentialsService.setCredentials(response.data);
        return response;
      }),
      catchError(this.handleError)
    );
}

viewQuotes(param?) {
  return this.httpClient
    .get(this._baseUrl + `quoteform?id=${param}`)
    .pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
}


handleError(error: HttpErrorResponse) {
  // console.log(error);
  if (error.error.code == 401) {
    return throwError(error.error.message);
  } else if (error.error.code == 404) {
    return throwError(error.error.message);
  } else if (error.error.code == 400) {
    return throwError(error.error.message);
  }
  return throwError(
    'Something bad happened; please try again later.');
}
}
