import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportsListingComponent } from './reports-listing/reports-listing.component';
import { ViewReportComponent } from './view-report/view-report.component';


const routes: Routes = [
  {
    path: '',
    data: {
      title: ''
    },
    children: [
     {
        path: '',
        component: ReportsListingComponent,
        data: {
          title: 'List'
        }
      },
      {
        path: 'list',
        component: ReportsListingComponent,
        data: {
          title: 'List'
        }
      },
      //    {
      //   path: 'add',
      //   component: AddUpdateMembersComponent,
      //   data: {
      //     title: 'List'
      //   }
      // },
      {
        path: 'view/:id/:page',
        component:ViewReportComponent,
        data: {
          title: 'view'
        }
      }
      // },
      // {
      //   path: 'edit/:id',
      //   component: AddUpdateMembersComponent
      // },{
      //   path: 'edit/:id/:page',
      //   component: AddUpdateMembersComponent
      // }
      ]
      }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportSectionRoutingModule { }
