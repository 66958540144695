import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ModpostsRoutingModule } from "./modposts-routing.module";
import { DeletedModpostsComponent } from "./deleted-modposts/deleted-modposts.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [DeletedModpostsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModpostsRoutingModule,
    NgxDatatableModule,
    NgxSpinnerModule,
  ],
})
export class ModpostsModule {}
