import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddUpdateRankBadgeComponent } from './add-update-rank-badge/add-update-rank-badge.component';
import { AddUpdateComponent } from './add-update/add-update.component';
import { BadgeAddUpdateComponent } from './badge-add-update/badge-add-update.component';
import { ListingComponent } from './listing/listing.component';
import { ViewComponent } from './view/view.component';


const routes: Routes = [
  {
    path: '',
    data: {
      title: 'point'
    },
    children: [
     {
        path: '',
        component: ListingComponent,
        data: {
          title: 'List'
        }
      },
      {
        path: 'list',
        component: ListingComponent,
        data: {
          title: 'List'
        }
      },
         {
        path: 'badge/add',
        component: BadgeAddUpdateComponent,
        data: {
          title: 'List'
        }
      },
         {
        path: 'rank-badge/add',
        component: AddUpdateRankBadgeComponent,
        data: {
          title: 'List'
        }
      },
      {
        path: 'rank-badge/edit/:id',
        component: AddUpdateRankBadgeComponent
      },{
        path: 'rank-badge/edit/:id/:page',
        component: AddUpdateRankBadgeComponent
      },
      {
        path: 'view/:id/:page',
        component: ViewComponent,
        data: {
          title: 'view'
        }
      },
      {
        path: 'edit/:id',
        component: AddUpdateComponent
      },{
        path: 'edit/:id/:page',
        component: AddUpdateComponent
      },
      {
        path: 'badge/edit/:id',
        component: BadgeAddUpdateComponent
      },{
        path: 'badge/edit/:id/:page',
        component: BadgeAddUpdateComponent
      }
      ]
      }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PointsMngmtRoutingModule { }
