import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "src/app/auth/credentials.service";
import { GroupListingService} from "../group-listing.service"
import {
  NgxNotificationMsgService,
  NgxNotificationStatusMsg,
} from "ngx-notification-msg";

@Component({
   selector: 'app-group-file',
  templateUrl: './group-file.component.html',
  styleUrls: ['./group-file.component.scss']
})
export class GroupFileComponent implements OnInit {
  Allgroup = [];
  @Output() reload = new EventEmitter<boolean>();
  @Input() groupId: any
  @Input() allgroupFiles:any
  @Input() groupFiles: any
   @Input() filesTotal :any
  columns = [];
  ColumnMode = ColumnMode;
  page: number;
  filters: {
    page: number;
    count: number;
    search: string;
    role: string;
    isDeleted: Boolean;
  } = {
    page: 1,
    count: 10,
    search: "",
    role: "",
    isDeleted: false,
  };

  constructor(  private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private credentialService: CredentialsService,
    private router: Router,
    private groupservice: GroupListingService) { 
     console.log(this.groupFiles,"groupFiles")
    console.log(this.groupId,"group id in group post")
  // this.getGroupPosts() 
  
     }

  ngOnInit() {
       
 

  }

  
  viewGroup(slug) {
    console.log("testdata",slug)
    // this.companiesService.actionType.next(type)
    let route = "/groups/"+slug ;
    this.router.navigate([route]);
  }
  getGroupPosts() {
    this.spinner.show();
    let data = this.filters
    data['groupId']=this.groupId
    this.groupservice.getGroupPosts(this.filters).subscribe(
      (res: any) => {
        
        this.groupFiles=res.data.map((data) => {
          console.log(data.isPrivate,"data.isPrivate")
           return {
            id: data.id,
            name: data.name,
            createdAt: data.createdAt,
            addedBy: data.userDetails.fullName,
            addedById:data.userDetails.id
          };
        
        });
         
        this.filesTotal = res.total;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();

        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: "",
          messages: [error],
        });
      }
    );
  }

   deleteGroupDoc(doc,id) {
     let index= this.groupFiles.findIndex((element, index) => {
  if (element.id == id) {
    return true
  }
})
     if (confirm("Do you want to delete this File?")) {
        this.groupFiles.splice(index, 1);
    let data = {
      groupdocuments: this.groupFiles,
    };
        let object = {
      imageName: doc,
      modelName: "group",
    };
    this.groupservice.deleteImage(object).subscribe(
      (res: any) => {
        if (res.success) {
          // this.groupImage = "";
          this.updateGroup(data, this.groupId);
          this.spinner.hide();
        } else {
          this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: "",
          messages: [res.error.message],
        });
        }
        this.spinner.hide();
      },
      (err) => {
         this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: "",
          messages: [err],
        });
      }
    );
    }
   }
  updateGroup(data,id) {
    this.groupservice.updateGroup(data, id).subscribe(
      (res: any) => {
        if (res.success) {
          this.reload.emit(true);
          this.spinner.hide();
        } else {
          this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: "",
          messages: [res.error.message],
        });
        }
        this.spinner.hide();
      },
      (err) => {
         this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: "",
          messages: [err],
        });
      }
    );
    }
  filterDatatable(event){
  // get the value of the key pressed and make it lowercase
  let val = event.toLowerCase();
  // get the amount of columns in the table
  let colsAmt = 3;
  // get the key names of each column in the dataset
  let keys = Object.keys(this.allgroupFiles[0]);
  // assign filtered matches to the active datatable
  if (val != '' && val != null && val != null) {
     this.groupFiles = this.allgroupFiles.filter(function(item){
    // iterate through each row's column data
    for (let i=0; i<colsAmt; i++){
      // check for a match
      if (item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 || !val){
        // found match, return true to add to result set
        return true;
      }
    }
      
     });
      this.filesTotal = this.groupFiles.length;
  }
  else {
        
    this.groupFiles = this.allgroupFiles
     this.filesTotal = this.groupFiles.length;
  }
 
  // whenever the filter changes, always go back to the first page
  // this.table.offset = 0;
}
  setPage(e) {
    console.log(e);
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: this.page });
  }
}
