import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { NgxSpinnerService } from 'ngx-spinner';
import { CredentialsService } from 'src/app/auth/credentials.service';
import { GroupListingService } from '../group-listing.service';

@Component({
  selector: 'app-view-group',
  templateUrl: './view-group.component.html',
  styleUrls: ['./view-group.component.scss']
})
export class ViewGroupComponent implements OnInit {
  slug: any
  groupId: any
  totalPost: any = 0
  totalMember: any = 0
  Allgroupmembers = []
  groupFiles = []
  filesTotal=0
  groupDetail: any
  Allgroupposts=[]
  constructor(
    private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private credentialService: CredentialsService,
    private router: Router,
    private groupservice: GroupListingService) {
    this.slug = this._activateRouter.snapshot.paramMap.get('id');
      if (this.slug) {
      this.getGroupDetail()
    }
   }

  ngOnInit() {
  
  }
  getGroupDetail() {
    this.spinner.show();
    // let data = {
    //   id: this.groupId,
    // };
    let data = {
      slug: this.slug,
    };
      this.groupservice.getGroupDetail(data).subscribe(
        (res: any) => {
          if (res.success) {
            this.groupDetail = res.data;
            this.groupId = res.data.id;
            this.groupFiles = res.data.groupdocuments
            this.filesTotal = res.data.groupdocuments.length
            
            console.log(this.groupFiles, "group groupFiles in group post")
            this.getGroupPosts() 
            this.getGroupMembers()
            this.spinner.hide();
          } else {
           this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [res.error.message],
          });
          }
          this.spinner.hide();
        },
        (err) => {
          this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: "",
          messages: [err],
        });
          this.spinner.hide();
        }
      );
  }
  reloadData(reload) {
    if (reload) {
      this.getGroupDetail()
    }
  }
   getGroupPosts() {
    this.spinner.show();
     let data ={}
    data['groupId']=this.groupId
    this.groupservice.getGroupPosts(data).subscribe(
      (res: any) => {
        
        this.Allgroupposts=res.data.map((data) => {
          console.log(data.isPrivate,"data.isPrivate")
           return {
            id: data.id,
            name: data.name,
            createdAt: data.createdAt,
            addedBy: data.userDetails.fullName,
            addedById:data.userDetails.id
          };
        
        });
        this.totalPost = res.total;
      },
      (error) => {
        this.spinner.hide();

        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: "",
          messages: [error],
        });
      }
    );
   }
  getGroupMembers() {
    this.spinner.show();
    let data ={}
    data['groupid']=this.groupId
    this.groupservice.getGroupMembers(data).subscribe(
      (res: any) => {
        
        this.Allgroupmembers=res.data.map((data) => {
          console.log(data.isPrivate,"data.isPrivate")
          return {
            groupid: this.groupId,
            slug: data.userDetails.slug,
              //  groupType: "Private",
            role:data.userRole=='user' ? 'Member' : (data.userRole=='admin' && data.groupDetails.addedBy== data.userId)? 'Super Admin' :'Admin',
            name: data.userDetails.fullName,
            id: data.userId,
          };
        
        });
        this.totalMember = res.total;
      },
      (error) => {
        this.spinner.hide();

        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: "",
          messages: [error],
        });
      }
    );
  }

}
