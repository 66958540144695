import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RolesMagmtRoutingModule } from './roles-magmt-routing.module';
import { EditRolesComponent } from './edit-roles/edit-roles.component';
import { ListingRolesComponent } from './listing-roles/listing-roles.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ViewRolesComponent } from './view-roles/view-roles.component';


@NgModule({
  declarations: [EditRolesComponent, ListingRolesComponent, ViewRolesComponent],
  imports: [
    CommonModule,
    RolesMagmtRoutingModule,
    FormsModule, 
     ReactiveFormsModule,
     NgxDatatableModule,
     NgxSpinnerModule
  
  ]
})
export class RolesMagmtModule { }
