import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private _baseUrl = environment.url;

  constructor(
    private httpClient: HttpClient
  ) { }

  /**
* @method
* @name addManager
* @description
* Authenticates the user.
* Request body:json {
       'email': string,
       'firstName': string,
       'lastName': string,
       'mobileNo': integer
* }
* @param context The Add manager parameters.
* @return Promise.
*/
addCategory(context) {
  return this.httpClient.post(this._baseUrl + `category`, context).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  );
}

getAllCategories(param?){
  let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl + 'mainCategories', { params: params }).pipe(
          map((response: any) => {
            return response;
          }),
          catchError(this.handleError)
        )
}

getAllsubCategories(param?){
  let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    console.log(params)
    return this.httpClient.get(this._baseUrl + 'categories', { params: params }).pipe(
          map((response: any) => {
            return response;
          }),
          catchError(this.handleError)
        )
}

// uploadImage(object:File) {
//   const formData: FormData = new FormData();
//   formData.append('file', object, object.name);
//   // let param = this.getParams(data);
//   return this.httpClient.post(this._baseUrl + 'product/uploadimage?modelName=category', formData).pipe(
//     map((response: any) => {
//       return response;
//     }),
//     catchError(this.handleError)
//   )
// }
uploadImage(fileToUpload: File,type) {

  let params = '?modelName='+type
  const formData: FormData = new FormData();
  formData.append('file', fileToUpload, fileToUpload.name);
  // formData.append('modelName',type);
  return this.httpClient.post(this._baseUrl + 'product/uploadimage?modelName=category', formData).pipe(
    map((response: any) => {
      console.log(response);
      return response;
    }),
    catchError(this.handleError)
  )
}
deleteImage(param?) {
  let params = new HttpParams();
  if (param) {
    for (let key of Object.keys(param)) {
      params = params.set(key, param[key])
    }
  }
  return this.httpClient.delete(this._baseUrl + 'Image/delete', { params: params }).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}

get(id) {
  return this.httpClient.get(this._baseUrl + 'category?id=' + id).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}

updateCategory(data) {
  return this.httpClient.put(this._baseUrl + `category`, data).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  );
}


deleteRecord(param?) {
  // let params = new HttpParams();
  // if (param) {
  //   for (let key of Object.keys(param)) {
  //     params = params.set(key, param[key])
  //   }
  // }
  return this.httpClient.put(this._baseUrl + 'delete', param ).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}

status(id, model, status) {
  let url = this._baseUrl + 'update/status?id=' + id + '&model=' + model + '&status=' + status;

  return this.httpClient.put(url, {}).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  )
}


getParams(parameters) {
  let params = new HttpParams();
  Object.keys(parameters).map((key) => {
    params = params.set(key, parameters[key]);
  })
  return params;
}

handleError(error: HttpErrorResponse) {
  // console.log(error);
  if (error.error.code == 401) {
    return throwError(error.error.message);
  } else if (error.error.code == 404) {
    return throwError(error.error.message);
  } else if (error.error.code == 400) {
    return throwError(error.error.message);
  }
  return throwError(
    'Something bad happened; please try again later.');
}
}
