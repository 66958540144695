// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-all-posts',
//   templateUrl: './all-posts.component.html',
//   styleUrls: ['./all-posts.component.scss']
// })
// export class AllPostsComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }
import { Component, OnInit } from "@angular/core";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { CredentialsService } from "src/app/auth/credentials.service";
import { MembersService } from "../members.service";
import {
  NgxNotificationMsgService,
  NgxNotificationStatusMsg,
} from "ngx-notification-msg";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-all-posts",
  templateUrl: "./all-posts.component.html",
  styleUrls: ["./all-posts.component.scss"],
})
export class AllPostsComponent implements OnInit {
  columns = [];
  ColumnMode = ColumnMode;
  userid: any;
  _subscriberData: any;
  public posts: Array<any> = [];
  allposts: Array<any> = [];
  public response: any;
  public response2: any;
  _frontUrl = environment.front_url;
  totalItems = 0;
  page = 1;
  filters: { page: number; count: number; search: string; isDeleted: Boolean } =
    {
      page: 1,
      count: 10,
      search: "",
      isDeleted: false,
    };

  constructor(
    private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private credentialService: CredentialsService,
    private router: Router,
    private memberService: MembersService
  ) {}

  ngOnInit() {
    // this.filters.addedBy = this.credentialService.credentials.id;
    // this.filters.manager_id = this.credentialService.credentials.manager_id;
    if (this._activateRouter.snapshot.params["page"]) {
      this.filters.page = JSON.parse(
        this._activateRouter.snapshot.params["page"]
      );
      this.page = this.filters.page;
      Object.assign(this.filters, { page: this.filters.page });
      this.getallPosts();
    }
    if (this._activateRouter.snapshot.params["id"]) {
      this.userid = this._activateRouter.snapshot.params["id"];
      this.getallPosts();
    } else {
      this.page = 1;
      this.getallPosts();
    }
  }

  getallPosts() {
    this.spinner.show();
    // this.isLoading = true;
    if (this.userid) {
      Object.assign(this.filters, { userid: this.userid });
    }
    this._subscriberData = this.memberService
      .getallPosts(this.filters)
      .subscribe(
        (response) => {
          if (response.data.length > 0) {
            this.posts = [];
            this.totalItems = response.total;
            this.posts = response.data.map((cat) => {
              // console.log(
              //   new Date(cat.updatedAt).getTime(),
              //   this.today.getTime()
              // );

              return {
                id: cat.id,
                slug: cat.slug,
                image:
                  cat.activityType == "modPost"
                    ? cat.afterImages && cat.afterImages.length > 0
                      ? cat.afterImages
                      : cat.beforeImages && cat.beforeImages.length > 0
                      ? cat.beforeImages
                      : []
                    : cat.image,
                post: cat.activityType == "normalPost" ? cat.post : cat.name,
                addedByName: cat.addedBy ? cat.addedBy : "",
                addedByImage: cat.addedBy ? cat.addedBy.image : "",
                status: cat.status,
                likes: cat.likesTotal,
                comments: cat.commentTotal,
                totalShare: cat.totalShare,
                likestatus: cat.likestatus,
                // isValidTime:this._sharedService.secondsToDhms(cat.isValidTime),
                choice1: cat.choice1,
                isVoted: cat.isVoted,
                isVotedValue: cat.isVotedValue
                  ? cat.isVotedValue.toString()
                  : "",
                choice2: cat.choice2,
                choice3: cat.choice3,
                choice4: cat.choice4,
                totalChoice1: cat.totalChoice1,
                totalChoice2: cat.totalChoice2,
                totalChoice3: cat.totalChoice3,
                totalChoice4: cat.totalChoice4,
                // time: this.pageService.timeDiffCalc(
                //   new Date(cat.updatedAt).getTime(),
                //   this.today.getTime()
                // ),
                createdAt: cat.createdAt,
                updatedAt: cat.updatedAt,
                activityType: cat.activityType,
              };
            });
            this.allposts = this.posts;

            // this.isLoading = false;

            // this.ngxNotificationMsgService.open({
            //   status: NgxNotificationStatusMsg.SUCCESS,
            //   header: '',
            //   messages: [response.message]
            // });
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [error],
          });
        }
      );
  }
  // filters results
  filterDatatable(event) {
    // get the value of the key pressed and make it lowercase
    let val = event.target.value.toLowerCase();
    // get the amount of columns in the table
    let colsAmt = 3;
    // get the key names of each column in the dataset
    let keys = Object.keys(this.allposts[0]);
    // assign filtered matches to the active datatable
    if (val != "" && val != null && val != null) {
      this.posts = this.allposts.filter(function (item) {
        // iterate through each row's column data
        for (let i = 0; i < colsAmt; i++) {
          // check for a match
          if (
            item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 ||
            !val
          ) {
            // found match, return true to add to result set
            return true;
          }
        }
      });
      this.totalItems = this.posts.length;
    } else {
      this.totalItems = this.allposts.length;
      this.posts = this.allposts;
    }

    // whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
  }
  deletePost(ID, type) {
    if (confirm("Do you want to delete this post?")) {
      this.spinner.show();

      let obj = {
        id: ID,
        // model: 'users'
      };
      if (type == "normalPost") {
        this.memberService.deletePost(obj).subscribe(
          (res: any) => {
            if (res.success) {
              this.response = res;
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.SUCCESS,
                header: "",
                messages: [res.message],
              });
            } else {
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.SUCCESS,
                header: "",
                messages: [res.error.message],
              });
            }
            this.getallPosts();
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [err],
            });
          }
        );
      } else if (type == "modPost") {
        this.memberService.deleteModPost(obj).subscribe(
          (res: any) => {
            if (res.success) {
              this.response = res;
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.SUCCESS,
                header: "",
                messages: [res.message],
              });
            } else {
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.SUCCESS,
                header: "",
                messages: [res.error.message],
              });
            }
            this.getallPosts();
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [err],
            });
          }
        );
      } else {
        this.memberService.deleteGroupPost(obj).subscribe(
          (res: any) => {
            if (res.success) {
              this.response = res;
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.SUCCESS,
                header: "",
                messages: [res.message],
              });
            } else {
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.SUCCESS,
                header: "",
                messages: [res.error.message],
              });
            }
            this.getallPosts();
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [err],
            });
          }
        );
      }
    }
  }

  setPage(e) {
    console.log(e);
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: this.page });
    // let route = '/companies/companies/' + this.page;
    // this.router.navigate([route]);
    this.getallPosts();
  }

  searchValue() {
    this.page = 1;
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getallPosts();
  }

  clearValue() {
    this.page = 1;
    this.filters.search = "";
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getallPosts();
  }

  // changeStatus(data, ID) {
  //   let Status = '';
  //   let message = 'Do you want to activate this leave?';
  //   if (data == 'active') {
  //     Status = "deactive";
  //     message = 'Do you want to deactivate this leave?';
  //   } else {
  //     Status = "active";
  //   }

  //   if (confirm(message)) {
  //     this.spinner.show();
  //     this.leaveService.status(ID, 'users', Status).subscribe((res: any) => {
  //       if (res.success) {
  //         this.response2 = res;
  //         this.toastr.success(res.message);
  //         this.getAllMembers();
  //       } else {
  //         this.toastr.error(res.error.message, 'Error');
  //       }
  //       this.spinner.hide();
  //     }, err => {
  //       this.spinner.hide();
  //       this.toastr.error('There are some errors, please try again after some time !', 'Error');
  //     });
  //   }
  // }

  //  /* Function use to remove Crop with crop id */
  //  remove(ID) {
  //   if (confirm("Do you want to cancel this leave?")) {
  //     this.spinner.show();

  //     let obj = {
  //       id: ID,
  //       model: 'users'
  //     }

  //     this.leaveService.deleteRecord(obj).subscribe((res: any) => {
  //       if (res.success) {
  //         this.response = res;
  //         this.toastr.success('Leave Removed Successfully');
  //       } else {
  //         this.toastr.error('Unable to delete at the moment, Please try again later', 'Error');
  //       }
  //       this.getAllMembers();
  //       this.spinner.hide();
  //     }, err => {
  //       this.spinner.hide();
  //       this.toastr.error('There are some errors, please try again after some time !', 'Error');
  //     });
  //   }
  // }

  // edit(ID, type) {
  //   // this.companiesService.actionType.next(type)
  //   // this.companiesService.activePage.next(this.page)
  //   let route = '/leaves/edit/' + ID;
  //   this.router.navigate([route]);
  // }

  // viewUser(leaveId) {
  //   let route = '/leaves/list/' + leaveId + '/' + this.page;
  //   this.router.navigate([route]);
  // }

  view(companyID) {
    // this.companiesService.actionType.next(type)
    let route = "/member/view/" + companyID + "/" + this.page;
    this.router.navigate([route]);
  }
  add() {
    let route = "/member/add/";
    // this.companiesService.actionType.next('active')
    this.router.navigate([route]);
  }
  edit(ID) {
    console.log(ID);
    // this.companiesService.actionType.next(type)
    // this.companiesService.activePage.next(this.page)
    let route = "/member/edit/" + ID + "/" + this.page;
    this.router.navigate([route]);
  }

  /* Function use to remove Crop with crop id */
  deleteRecord(ID) {
    if (confirm("Do you want to delete this member?")) {
      this.spinner.show();

      let obj = {
        id: ID,
      };

      this.memberService.deleteMember(obj).subscribe(
        (res: any) => {
          if (res.success) {
            this.response = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.message],
            });
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.error.message],
            });
          }
          this.getallPosts();
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: "",
            messages: [err],
          });
        }
      );
    }
  }

  changeStatus(data, ID) {
    let Status = "";
    let message = "Do you want to activate this  member?";
    if (data == "active") {
      Status = "deactive";
      message = "Do you want to deactivate this  member?";
    } else {
      Status = "active";
    }

    if (confirm(message)) {
      this.spinner.show();
      this.memberService.status(ID, "users", Status).subscribe(
        (res: any) => {
          if (res.success) {
            this.response2 = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.message],
            });

            this.getallPosts();
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: "",
              messages: [res.error.message],
            });
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [err],
          });
        }
      );
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this._subscriberData) {
      this._subscriberData.unsubscribe();
    }
  }
}
