import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ProductsService } from '../products.service';

@Component({
  selector: 'app-view-products',
  templateUrl: './view-products.component.html',
  styleUrls: ['./view-products.component.scss']
})
export class ViewProductsComponent implements OnInit {
  productId: any;
  productData:any;
  public _host = environment.url;
  constructor(
    private productService: ProductsService,
    private spinner: NgxSpinnerService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute
  ) { }

  ngOnInit() {
    this.productId = this._activateRouter.snapshot.params['id'];
    this.productDetail();
  }

  productDetail(){
    this.spinner.show();
    this.productService.get(this.productId).subscribe((res: any) => {
      if (res.success) {
      this.productData = res.data;
        
      } else {
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [res.error.message]
       });
      }
      this.spinner.hide();
    },
      error => {
        this.spinner.hide();
  
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [error]
       });
      });
  }

}
