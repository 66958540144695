import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RenovationListingComponent } from './renovation-listing/renovation-listing.component';
import { ViewQuoteComponent } from './view-quote/view-quote.component';


const routes: Routes = [
  {
    path:'',
    component:RenovationListingComponent
  },
  {
    path:'quote-details/:id',
    component:ViewQuoteComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RenovationQuoteRoutingModule { }
