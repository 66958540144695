import { Component, OnInit,ViewChild } from '@angular/core';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions,ChartConfiguration, ChartType } from 'chart.js';
import * as Chart from 'chart.js';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  constructor(
    private spinner: NgxSpinnerService,
    private userService: DashboardService,
    private router: Router ) {}
    DashData: any=[]
    dashdata1:any=[]
    ChartArr:any=[]
    totalMembers:any
    totalGroup:any
    totalModData:any
    totalPostData:any
    dummydata:any
    dashdata2:any=[]
  ngOnInit() {
      this.userService.getDashboardData().subscribe((response) => {
        this.DashData.push(response);
        this.DashData.forEach((data)=>{
            this.totalMembers=data.totalMembers;    
            this.totalModData=data.totalModData;
            this.totalPostData=data.totalPostData;
            this.totalGroup=data.totalGroup;
            this.dashdata1=Object.values(data ); 
        })
        
        var removeValFrom = [0, 1];
        this.dashdata1 = this.dashdata1.filter(function(value, index) {
            return removeValFrom.indexOf(index) == -1;
        })
        var myChart = new Chart("myChart", {
            type: 'bar',
            data: {
                labels: ['Members', 'Active Members', 'Inactive Members', 'Mod Records', 'Post', 'Groups','Group Records','Post Reports','Comments','Likes','Favorites','Subscribers','Contact Enqiries'],
                datasets: [{
                    label: '# of Votes',
                    data: this.dashdata1,
                    
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(75, 192, 192, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                        'rgba(75, 192, 192, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            
            options: {
                scales: {
                  
                }
            },
          
        });
      });

//   var lineChart = new Chart("lineChart", {
//     type: 'line',
//     data: {
//         labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
//         datasets: [{
//             label: '# of Votes',
//             data: [12, 150, 3, 5, 2, 3],
//             backgroundColor: [
//                 'rgba(255, 99, 132, 0.2)',
//                 'rgba(54, 162, 235, 0.2)',
//                 'rgba(255, 206, 86, 0.2)',
//                 'rgba(75, 192, 192, 0.2)',
//                 'rgba(153, 102, 255, 0.2)',
//                 'rgba(255, 159, 64, 0.2)'
//             ],
//             borderColor: [
//                 'rgba(255, 99, 132, 1)',
//                 'rgba(54, 162, 235, 1)',
//                 'rgba(255, 206, 86, 1)',
//                 'rgba(75, 192, 192, 1)',
//                 'rgba(153, 102, 255, 1)',
//                 'rgba(255, 159, 64, 1)'
//             ],
//             borderWidth: 1
//         }]
//     },
//     options: {
//         scales: {
          
//         }
//     }
// });
// var lineChart1 = new Chart("lineChart1", {
//   type: 'line',
//   data: {
//       labels: ['totalComments', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
//       datasets: [{
//           label: '# of Votes',
//           data: [12, 150, 3, 5, 2, 3],
//           backgroundColor: [
              
//               'pink',
//           ],
//           borderColor: [
//               'Purple',
//               'rgba(54, 162, 235, 1)',
//               'Orange',
//               'rgba(75, 192, 192, 1)',
//               'rgba(153, 102, 255, 1)',
//               'rgba(255, 159, 64, 1)'
//           ],
//           borderWidth: 1
//       }]
//   },
//   options: {
//       scales: {
        
//       }
//   }
// });
  }






  // getDashboardData(){
  //   this.spinner.show();
   
  //   this.userService.getDashboardData(this.type).subscribe((response) => {
  
  //     this.favLabel = []
  //     this.favSum =[]
  //     this.orderData = []
  //     this.orderLabels = []
  //     this.reviewLabel = []
  //     this.reviewSum=[]
  //     this.lineChartLabels= []
  //     this.lineChartData = []

  //     this.favlineChartLabels= []
  //     this.favlineChartData = []

  //     this.reviewlineChartData=[]
  //     this.reviewlineChartLabels = []

  //     this.barChartData =[]
  //     this.barChartLabels = []

  //     this.vendorbarChartLabels = []
  //     this.vandorbarChartData =[]
     
  //     if(this.type == 'yearly'){
  //       if (response.totalOrders && response.totalOrders.totalresults.length > 0) {

  //         response.totalOrders.totalresults.forEach(element => {
  //           this.lineChartLabels.push(element.year)           
  //           this.orderData.push(element.sum)
  //         });
  //         this.lineChartData.push({ data: this.orderData, label: 'Total Order' })

  
        
          
          
  //       }else{
  //         this.isOrder = false
  //       }

  //       if (response.totalProductfavorites && response.totalProductfavorites.response.length > 0) {
  //         this.isFav = true
  //         response.totalProductfavorites.response.forEach(element => {
  //           this.favlineChartLabels.push(element.year)           
  //           this.favSum.push(element.totalFavProduct)
  //         });    
  //         this.favlineChartData.push({ data: this.favSum, label: 'Total Favourite' })
          
         
          
          
  //       }else{
  //         this.isFav =false
        
  //       }

  //       if (response.totalProductReviews && response.totalProductReviews.response.length > 0) {
  //         this.isReview = true
  //         response.totalProductReviews.response.forEach(element => {
  //           console.log(element)
  //           this.reviewlineChartLabels.push(element.year)           
  //           this.reviewSum.push(element.totalProductReviews)
  //         });
  //         console.log( this.reviewSum," this.reviewSum")
  //         this.reviewlineChartData.push({ data: this.reviewSum, label: 'Total Review' })
          
           
          
          
  //       }else{
  //         this.isReview =false
  //         this.reviewlineChartData.push({ data: this.reviewSum, label: 'Total Review' })
  //       }

  //       if (response.totalUsers && response.totalUsers.response.length > 0) {
         
  //         response.totalUsers.response.forEach(element => {      
  //           this.barChartLabels.push(element.year)           
  //           this.userSum.push(element.totalProductReviews)
  //         });       
  //         this.barChartData.push({ data: this.userSum, label: 'Total Users' })
          
           
          
          
  //       }

  //       if (response.totalVendors && response.totalVendors.response.length > 0) {
         
  //         response.totalVendors.response.forEach(element => {      
  //           this.vendorbarChartLabels.push(element.year)           
  //           this.vendorSum.push(element.totalProductReviews)
  //         });       
  //         this.vandorbarChartData.push({ data: this.vendorSum, label: 'Total Vendors' })
          
           
          
          
  //       }
  //     }else if(this.type == 'monthly'){
  //       if (response.totalOrders && response.totalOrders.totalresults.length > 0) {

  //         response.totalOrders.totalresults.forEach(element => {
  //           let month
  //           if(element.month == 1){
  //             month = "Jan"
  //           }else if(element.month == 2){
  //             month = "Feb"
  //           }else if(element.month == 3){
  //             month = "Mar"
  //           }else if(element.month == 4){
  //             month = "Apr"
  //           }else if(element.month == 5){
  //             month = "May"
  //           }else if(element.month == 6){
  //             month = "Jun"
  //           }else if(element.month == 7){
  //             month = "Jul"
  //           }else if(element.month == 8){
  //             month = "Aug"
  //           }else if(element.month == 9){
  //             month = "Sep"
  //           }else if(element.month == 10){
  //             month = "Oct"
  //           }else if(element.month == 11){
  //             month = "Nov"
  //           }else if(element.month == 12){
  //             month = "Dec"
  //           }            
  //           this.lineChartLabels.push(month+'/'+element.year)           
  //           this.orderData.push(element.sum)
  //         });
  //         this.lineChartData.push({ data: this.orderData, label: 'Total Order' })
  
        
          
          
  //       }else{
  //         this.isOrder = false
  //       }

  //       if (response.totalProductfavorites && response.totalProductfavorites.response.length > 0) {
  //         this.isFav = true
  //         response.totalProductfavorites.response.forEach(element => {
  //           let month
  //           if(element.month == 1){
  //             month = "Jan"
  //           }else if(element.month == 2){
  //             month = "Feb"
  //           }else if(element.month == 3){
  //             month = "Mar"
  //           }else if(element.month == 4){
  //             month = "Apr"
  //           }else if(element.month == 5){
  //             month = "May"
  //           }else if(element.month == 6){
  //             month = "Jun"
  //           }else if(element.month == 7){
  //             month = "Jul"
  //           }else if(element.month == 8){
  //             month = "Aug"
  //           }else if(element.month == 9){
  //             month = "Sep"
  //           }else if(element.month == 10){
  //             month = "Oct"
  //           }else if(element.month == 11){
  //             month = "Nov"
  //           }else if(element.month == 12){
  //             month = "Dec"
  //           }
  //           this.favlineChartLabels.push(month+'/'+element.year)           
  //           this.favSum.push(element.totalFavProduct)
  //         });
  //         this.favlineChartData.push({ data: this.favSum, label: 'Total Favourite' })
  
        
         
          
          
  //       }else{
  //         this.isFav =false
           
  //       }

  //       if (response.totalProductReviews && response.totalProductReviews.response.length > 0) {
  //         this.isReview = true
  //         response.totalProductReviews.response.forEach(element => {
  //           let month
  //           if(element.month == 1){
  //             month = "Jan"
  //           }else if(element.month == 2){
  //             month = "Feb"
  //           }else if(element.month == 3){
  //             month = "Mar"
  //           }else if(element.month == 4){
  //             month = "Apr"
  //           }else if(element.month == 5){
  //             month = "May"
  //           }else if(element.month == 6){
  //             month = "Jun"
  //           }else if(element.month == 7){
  //             month = "Jul"
  //           }else if(element.month == 8){
  //             month = "Aug"
  //           }else if(element.month == 9){
  //             month = "Sep"
  //           }else if(element.month == 10){
  //             month = "Oct"
  //           }else if(element.month == 11){
  //             month = "Nov"
  //           }else if(element.month == 12){
  //             month = "Dec"
  //           }
  //           this.reviewlineChartLabels.push(month+'/'+element.year)           
  //           this.reviewSum.push(element.totalProductReviews)
  //         });
  //         this.reviewlineChartData.push({ data: this.reviewSum, label: 'Total Reviews' })
  
       
          
          
  //       }else{         
  //         this.reviewlineChartData.push({ data: this.reviewSum, label: 'Total Review' })
  //       }
       

  //       if (response.totalUsers && response.totalUsers.response.length > 0) {
        
  //         response.totalUsers.response.forEach(element => {
  //           let month
  //           if(element.month == 1){
  //             month = "Jan"
  //           }else if(element.month == 2){
  //             month = "Feb"
  //           }else if(element.month == 3){
  //             month = "Mar"
  //           }else if(element.month == 4){
  //             month = "Apr"
  //           }else if(element.month == 5){
  //             month = "May"
  //           }else if(element.month == 6){
  //             month = "Jun"
  //           }else if(element.month == 7){
  //             month = "Jul"
  //           }else if(element.month == 8){
  //             month = "Aug"
  //           }else if(element.month == 9){
  //             month = "Sep"
  //           }else if(element.month == 10){
  //             month = "Oct"
  //           }else if(element.month == 11){
  //             month = "Nov"
  //           }else if(element.month == 12){
  //             month = "Dec"
  //           }
  //           this.barChartLabels.push(month+'/'+element.year)        
  //           this.userSum.push(element.totalProductReviews)
  //         });       
  //         this.barChartData.push({ data: this.userSum, label: 'Total Users' })          
          
  //       }

  //       if (response.totalVendors && response.totalVendors.response.length > 0) {
        
  //         response.totalVendors.response.forEach(element => {
  //           let month
  //           if(element.month == 1){
  //             month = "Jan"
  //           }else if(element.month == 2){
  //             month = "Feb"
  //           }else if(element.month == 3){
  //             month = "Mar"
  //           }else if(element.month == 4){
  //             month = "Apr"
  //           }else if(element.month == 5){
  //             month = "May"
  //           }else if(element.month == 6){
  //             month = "Jun"
  //           }else if(element.month == 7){
  //             month = "Jul"
  //           }else if(element.month == 8){
  //             month = "Aug"
  //           }else if(element.month == 9){
  //             month = "Sep"
  //           }else if(element.month == 10){
  //             month = "Oct"
  //           }else if(element.month == 11){
  //             month = "Nov"
  //           }else if(element.month == 12){
  //             month = "Dec"
  //           }
  //           this.vendorbarChartLabels.push(month+'/'+element.year)        
  //           this.vendorSum.push(element.totalProductReviews)
  //         });       
  //         this.vandorbarChartData.push({ data: this.vendorSum, label: 'Total Vendors' })          
          
  //       }
  //     }else if(this.type == 'weekly'){
  //       if (response.totalOrders && response.totalOrders.totalresults.length > 0) {

  //         response.totalOrders.totalresults.forEach(element => {
  //           let month
  //           if(element.month == 1){
  //             month = "Jan"
  //           }else if(element.month == 2){
  //             month = "Feb"
  //           }else if(element.month == 3){
  //             month = "Mar"
  //           }else if(element.month == 4){
  //             month = "Apr"
  //           }else if(element.month == 5){
  //             month = "May"
  //           }else if(element.month == 6){
  //             month = "Jun"
  //           }else if(element.month == 7){
  //             month = "Jul"
  //           }else if(element.month == 8){
  //             month = "Aug"
  //           }else if(element.month == 9){
  //             month = "Sep"
  //           }else if(element.month == 10){
  //             month = "Oct"
  //           }else if(element.month == 11){
  //             month = "Nov"
  //           }else if(element.month == 12){
  //             month = "Dec"
  //           }            
  //           this.lineChartLabels.push(element.day+'/'+month+'/'+element.year)           
  //           this.orderData.push(element.sum)
  //         });
  //         this.lineChartData.push({ data: this.orderData, label: 'Total Order' })
  
        
          
          
  //       }else{
  //         this.isOrder = false
  //       }

  //       if (response.totalProductfavorites && response.totalProductfavorites.response.length > 0) {
  //         this.isFav = true
  //         response.totalProductfavorites.response.forEach(element => {
  //           let month
  //           if(element.month == 1){
  //             month = "Jan"
  //           }else if(element.month == 2){
  //             month = "Feb"
  //           }else if(element.month == 3){
  //             month = "Mar"
  //           }else if(element.month == 4){
  //             month = "Apr"
  //           }else if(element.month == 5){
  //             month = "May"
  //           }else if(element.month == 6){
  //             month = "Jun"
  //           }else if(element.month == 7){
  //             month = "Jul"
  //           }else if(element.month == 8){
  //             month = "Aug"
  //           }else if(element.month == 9){
  //             month = "Sep"
  //           }else if(element.month == 10){
  //             month = "Oct"
  //           }else if(element.month == 11){
  //             month = "Nov"
  //           }else if(element.month == 12){
  //             month = "Dec"
  //           }
  //           this.favlineChartLabels.push(element.day+'/'+month+'/'+element.year)           
  //           this.favSum.push(element.totalFavProduct)
  //         });
  //         this.favlineChartData.push({ data: this.favSum, label: 'Total Favourite' })
  
        
         
          
          
  //       }else{
  //         this.isFav =false
           
  //       }

  //       if (response.totalProductReviews && response.totalProductReviews.response.length > 0) {
  //         this.isReview = true
  //         response.totalProductReviews.response.forEach(element => {
  //           let month
  //           if(element.month == 1){
  //             month = "Jan"
  //           }else if(element.month == 2){
  //             month = "Feb"
  //           }else if(element.month == 3){
  //             month = "Mar"
  //           }else if(element.month == 4){
  //             month = "Apr"
  //           }else if(element.month == 5){
  //             month = "May"
  //           }else if(element.month == 6){
  //             month = "Jun"
  //           }else if(element.month == 7){
  //             month = "Jul"
  //           }else if(element.month == 8){
  //             month = "Aug"
  //           }else if(element.month == 9){
  //             month = "Sep"
  //           }else if(element.month == 10){
  //             month = "Oct"
  //           }else if(element.month == 11){
  //             month = "Nov"
  //           }else if(element.month == 12){
  //             month = "Dec"
  //           }
  //           this.reviewlineChartLabels.push(element.day+'/'+month+'/'+element.year)           
  //           this.reviewSum.push(element.totalProductReviews)
  //         });
  //         this.reviewlineChartData.push({ data: this.reviewSum, label: 'Total Reviews' })
  
       
          
          
  //       }

  //       if (response.totalUsers && response.totalUsers.response.length > 0) {         
  //         response.totalUsers.response.forEach(element => {
  //           let month
  //           if(element.month == 1){
  //             month = "Jan"
  //           }else if(element.month == 2){
  //             month = "Feb"
  //           }else if(element.month == 3){
  //             month = "Mar"
  //           }else if(element.month == 4){
  //             month = "Apr"
  //           }else if(element.month == 5){
  //             month = "May"
  //           }else if(element.month == 6){
  //             month = "Jun"
  //           }else if(element.month == 7){
  //             month = "Jul"
  //           }else if(element.month == 8){
  //             month = "Aug"
  //           }else if(element.month == 9){
  //             month = "Sep"
  //           }else if(element.month == 10){
  //             month = "Oct"
  //           }else if(element.month == 11){
  //             month = "Nov"
  //           }else if(element.month == 12){
  //             month = "Dec"
  //           }
  //           this.barChartLabels.push(element.day+'/'+month+'/'+element.year)         
  //           this.userSum.push(element.totalProductReviews)
  //         });       
  //         this.barChartData.push({ data: this.userSum, label: 'Total Users' })          
          
       
          
          
  //       }

  //       if (response.totalVendors && response.totalVendors.response.length > 0) {         
  //         response.totalVendors.response.forEach(element => {
  //           let month
  //           if(element.month == 1){
  //             month = "Jan"
  //           }else if(element.month == 2){
  //             month = "Feb"
  //           }else if(element.month == 3){
  //             month = "Mar"
  //           }else if(element.month == 4){
  //             month = "Apr"
  //           }else if(element.month == 5){
  //             month = "May"
  //           }else if(element.month == 6){
  //             month = "Jun"
  //           }else if(element.month == 7){
  //             month = "Jul"
  //           }else if(element.month == 8){
  //             month = "Aug"
  //           }else if(element.month == 9){
  //             month = "Sep"
  //           }else if(element.month == 10){
  //             month = "Oct"
  //           }else if(element.month == 11){
  //             month = "Nov"
  //           }else if(element.month == 12){
  //             month = "Dec"
  //           }
  //           this.vendorbarChartLabels.push(element.day+'/'+month+'/'+element.year)         
  //           this.vendorSum.push(element.totalProductReviews)
  //         });       
  //         this.vandorbarChartData.push({ data: this.vendorSum, label: 'Total Vendors' })          
          
       
          
          
  //       }
        
  //     }
    
  //     this.spinner.hide();
  //   });
  // }

}

