import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ContestMgmtService } from "../contest-mgmt.service";
import {
  NgxNotificationMsgService,
  NgxNotificationStatusMsg,
} from "ngx-notification-msg";

import { environment } from "src/environments/environment";
import { DatePipe } from "@angular/common";
declare const tinymce;

@Component({
  selector: "app-add-update",
  templateUrl: "./add-update.component.html",
  styleUrls: ["./add-update.component.scss"],
})
export class AddUpdateComponent implements OnInit {
  @ViewChild("txtedit", { static: false }) textinput;
  @ViewChild("votingtxtedit", { static: false }) votingtextinput;
  @ViewChild("myInput", { static: false }) myInputVariable;
  @ViewChild("myInput2", { static: false }) myInputVariable2;
  @ViewChild("myInput3", { static: false }) myInputVariable3;
  public Form: FormGroup;
  submitted = false;
  _roleObservable: any;
  user: any;
  token: any;
  ID: any;
  userRole: any;
  fileToUpload: any;
  dImg = "/assets/img/placeholder.jpg";
  imageBase: any;
  isEdit = false;
  private _formObservable: any;
  ContestId: any;
  headerTitle: string;
  submitTitle: string;
  pageNo = 1;
  minDate = new Date();
  minVoteDate = new Date();
  public _host = environment.url;
  submissionDeadlinedate: any = new Date();
  contestImage: any;
  contestFacebookImage: any;
  today: any;
  sponsorImage: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private contestMgmtService: ContestMgmtService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute,
    private datepipe: DatePipe
  ) {
    this.createForm();
    let date = new Date();
    this.today = date.toISOString();
    // console.log('this.today',this.today);
  }

  ngOnInit() {
    this.setConfigEditor();
    this.setVotingConfigEditor();
    this.ContestId = this._activateRouter.snapshot.params["id"];
    this.pageNo = this._activateRouter.snapshot.params["page"];

    this.getContest();
  }
  getContest() {
    console.log("in get contest");
    if (this.ContestId) {
      this.headerTitle = "Update Contest";
      this.submitTitle = "Update";
      this.spinner.show();
      this.contestMgmtService.get(this.ContestId).subscribe(
        (res: any) => {
          if (res.success) {
            this.Form.patchValue({
              title: res.data.title,
              details: res.data.details,
              couponPartnerExist: res.data.couponPartnerExist,
              submissionDeadline: res.data.submissionDeadline,
              votingDeadline: res.data.votingDeadline,
              votingDescription: res.data.votingDescription,
              couponPartnerName: res.data.couponPartnerName,
              couponPartnerWebsiteURL: res.data.couponPartnerWebsiteURL,
              couponPartnerPercentage: res.data.couponPartnerPercentage,
              couponCode: res.data.couponCode,
              isNavigation: res.data.isNavigation,
              optIn: res.data.optIn,
              prize1st: res.data.prize1st,
              prize2nd: res.data.prize2nd,
              prize3rd: res.data.prize3rd,
              contestfeaturedImage: res.data.contestfeaturedImage,
              facebookMetaImage: res.data.facebookMetaImage,
              isSponsor: res.data.isSponsor,
              sponsorLink: res.data.sponsorLink,
              sponsorDescription: res.data.sponsorDescription,
              sponsorButtonText: res.data.sponsorButtonText,
              sponsorLogo: res.data.sponsorLogo,
              // department_id: dept,
              // manager_id: res.data.manager_id?res.data.manager_id.id:''
            });
            this.sponsorImage = res.data.sponsorLogo;
            this.contestImage = res.data.contestfeaturedImage;
            this.contestFacebookImage = res.data.facebookMetaImage;
            this.sponsorExistChange();
            // let date =
            //   res.data.submissionDeadline.getFullYear() +
            //   "-" +
            //   (res.data.submissionDeadline.getMonth() + 1) +
            //   "-" +
            //   res.data.submissionDeadline.getDate();
            // this.minDate = new Date(date);
            //          this.setConfigEditor()
            // this.setVotingConfigEditor()

            if (tinymce.get("txtarea")) {
              setTimeout(() => {
                tinymce.get("txtarea").setContent(res.data.details);
              }, 2000);
              // tinymce.get("txtarea").setContent(res.data.details);
            }
            if (tinymce.get("votingtxtarea")) {
              setTimeout(() => {
                tinymce
                  .get("votingtxtarea")
                  .setContent(res.data.votingDescription);
              }, 3000);
              // tinymce
              //   .get("votingtxtarea")
              //   .setContent(res.data.votingDescription);
            }
            setTimeout(() => {
              this.spinner.hide();
            }, 2000);
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: "",
              messages: [res.error.message],
            });
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();

          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [error],
          });
        }
      );
    } else {
      this.headerTitle = "Add Contest";
      this.submitTitle = "Add";
      this.spinner.hide();
    }
  }
  uploadImage(files: FileList, type) {
    console.log(type, "type");
    this.fileToUpload = files.item(0);

    this.spinner.show();
    this.contestMgmtService
      .uploadImage(this.fileToUpload, "contests")
      .subscribe(
        (res: any) => {
          if (res.success) {
            if (type == "facebookimage") {
              this.contestFacebookImage = res.data.fullpath;
              setTimeout(function () {
                this.Form.patchValue({
                  facebookMetaImage: res.data.fullpath,
                });
              }, 40000000);
              this.Form.patchValue({
                facebookMetaImage: res.data.fullpath,
              });
              this.myInputVariable2.nativeElement.value = "";
            } else if (type == "sponsorImage") {
              this.sponsorImage = res.data.fullpath;
              setTimeout(function () {
                this.Form.patchValue({
                  sponsorLogo: res.data.fullpath,
                });
              }, 40000000);
              this.Form.patchValue({
                sponsorLogo: res.data.fullpath,
              });
              this.myInputVariable3.nativeElement.value = "";
            } else {
              this.contestImage = res.data.fullpath;
              setTimeout(function () {
                this.Form.patchValue({
                  contestfeaturedImage: res.data.fullpath,
                });
              }, 40000000);
              this.Form.patchValue({
                contestfeaturedImage: res.data.fullpath,
              });
              this.myInputVariable.nativeElement.value = "";
            }
          } else {
            window.scrollTo(0, 0);
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: "",
              messages: [res.error.message],
            });
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [err],
          });
        }
      );
  }

  removeImage(image, type) {
    if (type == "sponsorImage") {
      this.Form.controls.sponsorLogo.setValue("");
    } else if (type == "facebookimage") {
      this.Form.controls.facebookMetaImage.setValue("");
    } else {
      this.Form.controls.contestfeaturedImage.setValue("");
    }

    let object = {
      imageName: image,
      modelName: "contests",
    };
    this.contestMgmtService.deleteImage(object).subscribe(
      (res: any) => {
        if (res.success) {
          if (type == "facebookimage") {
            this.contestFacebookImage = "";
          } else if (type == "sponsorImage") {
            this.sponsorImage = "";
          } else {
            this.contestImage = "";
          }

          this.spinner.hide();
        } else {
          // this.toastr.error(res.error.message, 'Error');
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  selectedDate(date) {
    console.log(date, "date");
    this.minVoteDate = date;
    let diff = false;
    if (this.Form.get("votingDeadline").value != "") {
      diff = this.checkdiffExistBwDates(
        date,
        this.Form.get("votingDeadline").value
      );
      if (diff) {
        this.Form.get("votingDeadline").reset();
      }
    }
    // let month = String(date.getMonth() + 1);
    // let day = String(date.getDate());
    // let year = date.getFullYear();

    // if (parseInt(month, 10) < 10) {
    //   month = "0" + month.toString();
    // }
    // if (parseInt(day, 10) < 10) {
    //   day = "0" + day.toString();
    // }

    // this.submissionDeadlinedate = `${year}-${month}-${day}`;
  }
  checkdiffExistBwDates(subdate, votedate) {
    var countDownDate = new Date(subdate).getTime();
    // Get today's date and time
    var now = new Date(votedate).getTime();
    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    let diffExist = false;
    if (days > 0 || hours > 0 || minutes > 0 || seconds > 0) {
      diffExist = true;
    }
    return diffExist;
  }
  createForm() {
    this.Form = this.formBuilder.group({
      title: ["", [Validators.required]],
      isNavigation: [false, [Validators.required]],
      optIn: [false, [Validators.required]],
      details: ["", [Validators.required]],
      submissionDeadline: ["", [Validators.required]],
      votingDeadline: ["", [Validators.required]],
      votingDescription: ["", [Validators.required]],
      couponPartnerExist: [false],
      couponPartnerName: [""],
      couponPartnerWebsiteURL: [
        "",
        [
          Validators.pattern(
            "^https://www(.+)|www./(.+)|http://www(.+)|http://(.+)|https://(.+)"
          ),
        ],
      ],
      couponPartnerPercentage: [""],
      couponCode: [""],
      prize1st: ["", [Validators.required]],
      prize2nd: [""],
      prize3rd: [""],
      contestfeaturedImage: [""],
      facebookMetaImage: ["", Validators.required],
      isSponsor: [false],
      sponsorLogo: [""],
      sponsorLink: [""],
      sponsorDescription: [""],
      sponsorButtonText: [""],
    });
  }
  get f() {
    return this.Form.controls;
  }

  onSubmit() {
    console.log(this.Form.value, "this.Form.value");
    console.log(this.Form.controls, "this.Form.controls");
    this.Form.patchValue({
      details: tinymce.get("txtarea").getContent(),
      votingDescription: tinymce.get("votingtxtarea").getContent(),
    });
    this.submitted = true;
    if (!this.Form.invalid) {
      this.spinner.show();
      if (this.ContestId) {
        let data = this.Form.value;
        data["id"] = this.ContestId;
        this._formObservable = this.contestMgmtService
          .updateContest(data)
          .subscribe(
            (res) => {
              if (res.success) {
                this.ngxNotificationMsgService.open({
                  status: NgxNotificationStatusMsg.SUCCESS,
                  header: "",
                  messages: [res.message],
                });
                this.router.navigate(["/contests"]);
              } else {
                this.ngxNotificationMsgService.open({
                  status: NgxNotificationStatusMsg.FAILURE,
                  header: "",
                  messages: [res.error.message],
                });
              }
              this.spinner.hide();
            },
            (error) => {
              this.spinner.hide();
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.FAILURE,
                header: "",
                messages: [error],
              });
            }
          );
      } else {
        this._formObservable = this.contestMgmtService
          .addContest(this.Form.value)
          .subscribe(
            (res) => {
              if (res.success) {
                this.ngxNotificationMsgService.open({
                  status: NgxNotificationStatusMsg.SUCCESS,
                  header: "",
                  messages: [res.message],
                });
                this.router.navigate(["/contests"]);
              } else {
                this.ngxNotificationMsgService.open({
                  status: NgxNotificationStatusMsg.FAILURE,
                  header: "",
                  messages: [res.error.message],
                });
              }
              this.spinner.hide();
            },
            (error) => {
              this.spinner.hide();
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.FAILURE,
                header: "",
                messages: [error],
              });
            }
          );
      }
    }
  }
  setConfigEditor() {
    console.log("in set detail config");
    tinymce.remove();
    tinymce.init({
      selector: "#txtarea",
      base_url: "/tinymce",
      suffix: ".min",
      branding: false,
      toolbar_mode: "wrap",
      menubar: false,
      content_style:
        "body { color: #495057;font-size: 14px;font-family: 'Open Sans',sans-serif; list-style-position: inside;}",
      plugins: "link image table lists autoresize emoticons",
      toolbar:
        "h1 h2 | bold italic underline | alignleft aligncenter alignright | table image  bullist numlist link | emoticons ",

      height: 300,
      object_resizing: ":not(table)",
      image_title: false,
      /* enable automatic uploads of images represented by blob or data URIs*/
      automatic_uploads: true,
      file_picker_types: "image",
      default_link_target: "_blank",
      target_list: false,
      link_title: false,
      images_upload_url: this._host + "uploadnormalImages",
      emoticons_append: {
        custom_mind_explode: {
          keywords: ["brain", "mind", "explode", "blown"],
          char: "🤯",
        },
      },
    });
  }
  setVotingConfigEditor() {
    console.log("in set voting config");
    // tinymce.remove();
    tinymce.init({
      selector: "#votingtxtarea",
      base_url: "/tinymce",
      suffix: ".min",
      branding: false,
      toolbar_mode: "wrap",
      menubar: false,
      content_style:
        "body { color: #495057;font-size: 14px;font-family: 'Open Sans',sans-serif; list-style-position: inside;}",
      plugins: "link image table lists autoresize emoticons",
      toolbar:
        "h1 h2 | bold italic underline | alignleft aligncenter alignright | table image  bullist numlist link | emoticons ",
      height: 300,
      object_resizing: ":not(table)",
      image_title: false,
      /* enable automatic uploads of images represented by blob or data URIs*/
      automatic_uploads: true,
      file_picker_types: "image",
      default_link_target: "_blank",
      target_list: false,
      link_title: false,
      images_upload_url: this._host + "uploadnormalImages",
      emoticons_append: {
        custom_mind_explode: {
          keywords: ["brain", "mind", "explode", "blown"],
          char: "🤯",
        },
      },
    });
  }

  iscouponcheck: boolean = false;

  couponExistChange() {
    if (this.Form.get("couponPartnerExist").value == true) {
      this.Form.get("couponPartnerName").setValidators(Validators.required);
      this.Form.get("couponPartnerWebsiteURL").setValidators(
        Validators.required
      );
      this.Form.get("couponPartnerPercentage").setValidators(
        Validators.required
      );
      this.Form.get("couponCode").setValidators(Validators.required);
    } else {
      this.Form.get("couponPartnerName").clearValidators();
      this.Form.get("couponPartnerWebsiteURL").clearValidators();
      this.Form.get("couponPartnerPercentage").clearValidators();
      this.Form.get("couponCode").clearValidators();
      this.Form.get("couponPartnerName").reset();
      this.Form.get("couponPartnerWebsiteURL").reset();
      this.Form.get("couponPartnerPercentage").reset();
      this.Form.get("couponCode").reset();
      this.Form.controls.couponPartnerName.setValue("");
      this.Form.controls.couponPartnerWebsiteURL.setValue("");
      this.Form.controls.couponPartnerPercentage.setValue("");
      this.Form.controls.couponCode.setValue("");
      //  couponPartnerName: "",
      //   couponPartnerWebsiteURL: "",
      //   couponPartnerPercentage: "",
      // couponCode: "",
    }
    this.Form.get("couponPartnerName").updateValueAndValidity();
    this.Form.get("couponPartnerWebsiteURL").updateValueAndValidity();
    this.Form.get("couponPartnerPercentage").updateValueAndValidity();
    this.Form.get("couponCode").updateValueAndValidity();
  }
  sponsorExistChange() {
    if (this.Form.get("isSponsor").value == true) {
      this.Form.get("sponsorLogo").setValidators(Validators.required);
      this.Form.get("sponsorDescription").setValidators(Validators.required);
      this.Form.get("sponsorButtonText").setValidators(Validators.required);
      this.Form.get("sponsorLink").setValidators([
        Validators.required,
        Validators.pattern(
          "^https://www(.+)|www./(.+)|http://www(.+)|http://(.+)|https://(.+)"
        ),
      ]);
    } else {
      this.Form.get("sponsorLogo").clearValidators();
      this.Form.get("sponsorDescription").clearValidators();
      this.Form.get("sponsorButtonText").clearValidators();
      this.Form.get("sponsorLink").clearValidators();
      this.Form.get("sponsorLogo").reset();
      this.Form.get("sponsorDescription").reset();
      this.Form.get("sponsorButtonText").reset();
      this.Form.get("sponsorLink").reset();
      this.Form.controls.sponsorLogo.setValue("");
      this.Form.controls.sponsorDescription.setValue("");
      this.Form.controls.sponsorButtonText.setValue("");
      this.Form.controls.sponsorLink.setValue("");
      //  couponPartnerName: "",
      //   couponPartnerWebsiteURL: "",
      //   couponPartnerPercentage: "",
      // couponCode: "",
    }
    this.Form.get("sponsorDescription").updateValueAndValidity();
    this.Form.get("sponsorLogo").updateValueAndValidity();
    this.Form.get("sponsorButtonText").updateValueAndValidity();
    this.Form.get("sponsorLink").updateValueAndValidity();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this._formObservable) {
      this._formObservable.unsubscribe();
    }
  }
}
