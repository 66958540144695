import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  NgxNotificationMsgService,
  NgxNotificationStatusMsg,
} from "ngx-notification-msg";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { AdvertisementService } from "../advertisement.service";
// const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
const reg =
  "^https://www(.+)|www./(.+)|http://www(.+)|http://(.+)|https://(.+)";

@Component({
  selector: "app-add-update-advertise",
  templateUrl: "./add-update-advertise.component.html",
  styleUrls: ["./add-update-advertise.component.scss"],
})
export class AddUpdateAdvertiseComponent implements OnInit {
  public Form: FormGroup;
  submitted = false;
  _roleObservable: any;
  public _host = environment.url;
  user: any;
  token: any;
  ID: any;
  userRole: any;
  fileToUpload: any;
  dImg = "/assets/img/placeholder.jpg";
  imageBase: any;
  isEdit = false;
  private _formObservable: any;
  addId: any;
  headerTitle: string;
  submitTitle: string;
  userImage: any;
  mobileViewImage: any;
  @ViewChild("myInput", { static: false }) myInputVariable;
  @ViewChild("mobileInput", { static: false }) mobileInputVariable;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private advertiseService: AdvertisementService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.addId = this._activateRouter.snapshot.params["id"];
    // this.pageNo = this._activateRouter.snapshot.params['page'];

    if (this.addId) {
      this.headerTitle = "Update Advertisement";
      this.submitTitle = "Update";
      this.spinner.show();
      this.advertiseService.get(this.addId).subscribe(
        (res: any) => {
          if (res.success) {
            this.Form.patchValue({
              title: res.data.title ? res.data.title : "",
              position: res.data.position ? res.data.position : "",
              description: res.data.description,
              redirectURL: res.data.redirectURL,
              image: res.data.image,
            });
            this.userImage = res.data.image;
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: "",
              messages: [res.error.message],
            });
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();

          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [error],
          });
        }
      );
    } else {
      // this.Form.controls['email'].enable();
      this.headerTitle = "Add Advertisement";
      this.submitTitle = "Add";
    }
  }

  uploadImage(files: FileList, type) {
    this.fileToUpload = files.item(0);

    this.spinner.show();
    this.advertiseService
      .uploadImage(this.fileToUpload, "advertisement")
      .subscribe(
        (res: any) => {
          if (res.success) {
            if (type == "image") {
              this.userImage = res.data.imagePath[0];
              setTimeout(function () {
                this.Form.patchValue({ image: this.userImage });
              }, 40000000);
              this.Form.patchValue({ image: this.userImage });
              this.myInputVariable.nativeElement.value = "";
            } else {
              this.mobileViewImage = res.data.imagePath[0];
              setTimeout(function () {
                this.Form.patchValue({ mobileImage: this.mobileViewImage });
              }, 40000000);
              this.Form.patchValue({ mobileImage: this.mobileViewImage });
              this.mobileInputVariable.nativeElement.value = "";
            }
          } else {
            window.scrollTo(0, 0);
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: "",
              messages: [res.error.message],
            });
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [
              "There are some errors, please try again after some time !",
              "Error",
            ],
          });
        }
      );
  }

  removeImage(image, type) {
    if (type == "image") {
      this.Form.controls.image.setValue("");
      this.userImage = "";
    } else {
      this.Form.controls.mobileImage.setValue("");
      this.mobileViewImage = "";
    }

    let object = {
      imageName: image,
      modelName: "advertisement",
    };
    this.advertiseService.deleteImage(object).subscribe(
      (res: any) => {
        if (res.success) {
          if (type == "image") {
            this.userImage = "";
          } else {
            this.mobileViewImage = "";
          }
          this.spinner.hide();
        } else {
          // this.toastr.error(res.error.message, 'Error');
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  createForm() {
    this.Form = this.formBuilder.group({
      title: [
        "",
        [Validators.required, Validators.pattern(/^((?!\s{2,}).)*$/)],
      ],
      // description: ['', Validators.required],
      description: [""],
      image: ["", Validators.required],
      mobileImage: [""],
      redirectURL: ["", Validators.pattern(reg)],
      // redirectURL:['', [Validators.required,Validators.pattern(reg)]],
      position: ["", Validators.required],
    });
  }
  get f() {
    return this.Form.controls;
  }
  updatevalidation(position) {
    if (position == "center") {
      this.Form.get("mobileImage").setValidators(Validators.required);
    } else {
      // this.rvForm.get("year").reset();
      // this.rvForm.get("year").setValue("");
      // this.rvForm.get("model").reset();
      this.Form.get("mobileImage").clearValidators();
      this.Form.get("mobileImage").reset();
    }
    this.Form.get("mobileImage").updateValueAndValidity();
  }
  onSubmit() {
    this.submitted = true;
    console.log(this.Form);

    if (!this.Form.invalid) {
      this.spinner.show();
      if (this.addId) {
        let data = this.Form.value;
        // data['id'] = this.addId;

        this._formObservable = this.advertiseService
          .updateAdd(data, this.addId)
          .subscribe(
            (res) => {
              if (res.success) {
                this.ngxNotificationMsgService.open({
                  status: NgxNotificationStatusMsg.SUCCESS,
                  header: "",
                  messages: [res.message],
                });
                this.router.navigate(["/advertisements"]);
              } else {
                this.ngxNotificationMsgService.open({
                  status: NgxNotificationStatusMsg.FAILURE,
                  header: "",
                  messages: [res.error.message],
                });
              }
              this.spinner.hide();
            },
            (error) => {
              this.spinner.hide();
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.FAILURE,
                header: "",
                messages: [error],
              });
            }
          );
      } else {
        console.log(this.Form.value, " this.Form.value");
        let data = this.Form.value;

        this._formObservable = this.advertiseService.postAdd(data).subscribe(
          (res) => {
            if (res.success) {
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.SUCCESS,
                header: "",
                messages: [res.message],
              });
              this.router.navigate(["/advertisements"]);
            } else {
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.FAILURE,
                header: "",
                messages: [res.error.message],
              });
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: "",
              messages: [error],
            });
          }
        );
      }
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this._formObservable) {
      this._formObservable.unsubscribe();
    }
  }
}
