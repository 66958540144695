import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from 'src/app/auth/credentials.service';
import { BehaviorService } from 'src/app/shared/behavior.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public user: any = {};
  public userID: any;
  updateData: any;
  constructor(
    private credentials: CredentialsService,
    private router: Router,
    private _bs: BehaviorService
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      this.userID = this.user.id;
    }

    this._bs.getUserData().subscribe((res: any) => {
      this.updateData = res;
      if (res.firstName)
        localStorage.setItem("user", JSON.stringify(this.updateData));
    });
  }


  toggleBtn(){
    document.getElementById("layout").classList.toggle('fullLayout')
  }

  logout(){
    this.credentials.logout().subscribe(res => {
      this.router.navigate(['/']);
    });
    this._bs.unsetUser();
  }

}
