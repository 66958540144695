import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupListingService {
  private _baseUrl = environment.url;
  constructor(
    private httpClient: HttpClient
  ) { }
  getAllGroups(param?) {
    let params = this.getParams(param);
      return this.httpClient.get(this._baseUrl + 'allgroupslist',{params:params}).pipe(
            map((response: any) => {
              return response;
            }),
            catchError(this.handleError)
          )
  }
  getGroupPosts(param?) {
    let params = this.getParams(param);
      return this.httpClient.get(this._baseUrl + 'group/allpost',{params:params}).pipe(
            map((response: any) => {
              return response;
            }),
            catchError(this.handleError)
          )
  }
  getGroupMembers(param?) {
    let params = this.getParams(param);
      return this.httpClient.get(this._baseUrl + 'member/groups',{params:params}).pipe(
            map((response: any) => {
              return response;
            }),
            catchError(this.handleError)
          )
  }
 getGroupDetail(param?) {
    let params = this.getParams(param);
    return this.httpClient
      .get(this._baseUrl + "slug/group", {
        params: params,
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
 }
   deleteGroup(param?) {
    let params = this.getParams(param);
    return this.httpClient
      .delete(this._baseUrl + "group/delete", { params: params })
      .pipe(
        map((response: any) => {
          // this.credentialsService.setCredentials(response.data);
          return response;
        }),
        catchError(this.handleError)
      );
   }
  deleteGroupMember(context) {
    return this.httpClient
      .post(this._baseUrl + "group/leaveMember", context)
      .pipe(
        map((response: any) => {
          // this.credentialsService.setCredentials(response.data);
          return response;
        }),
        catchError(this.handleError)
      );
  }
    deletePost(param?) {
    let params = this.getParams(param);
    return this.httpClient
      .delete(this._baseUrl + "group/post/delete", { params: params })
      .pipe(
        map((response: any) => {
          // this.credentialsService.setCredentials(response.data);
          return response;
        }),
        catchError(this.handleError)
      );
    }
   updateGroup(context, id) {
    return this.httpClient
      .put(this._baseUrl + `group/update?id=` + id, context)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }
  deleteImage(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key]);
      }
    }
    return this.httpClient
      .get(this._baseUrl + "image/delete", { params: params })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }
    getDocIconFromType(type) {
    let image = "";
    if (type == "image/png") {
      image = "assets/img/img.png";
    }
    if (type == "application/pdf") {
      image = "assets/img/pdf1.png";
    }
    if (type == "image/webp") {
      image = "assets/img/web.png";
    }
    if (type == "image/jpeg") {
      image = "assets/img/img.png";
    }
    if (type == "application/vnd.ms-excel") {
      image = "assets/img/web.png";
    }
    return image;
  }

getParams(parameters) {
  let params = new HttpParams();
  Object.keys(parameters).map((key) => {
    params = params.set(key, parameters[key]);
  })
  return params;
}
handleError(error: HttpErrorResponse) {
  // console.log(error);
  if (error.error.code == 401) {
    return throwError(error.error.message);
  } else if (error.error.code == 404) {
    return throwError(error.error.message);
  } else if (error.error.code == 400) {
    return throwError(error.error.message);
  }
  return throwError(
    'Something bad happened; please try again later.');
}
}
