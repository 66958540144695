import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TimeManagementRoutingModule } from './time-management-routing.module';
import { AddUpdateTimeComponent } from './add-update-time/add-update-time.component';
import { ListingTimeComponent } from './listing-time/listing-time.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';


@NgModule({
  declarations: [AddUpdateTimeComponent, ListingTimeComponent],
  imports: [
    CommonModule,
    TimeManagementRoutingModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    NgxDatatableModule,
  ]
})
export class TimeManagementModule { }
