import { Component, OnInit } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CredentialsService } from 'src/app/auth/credentials.service';
import { ProductsService } from '../products.service';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';

@Component({
  selector: 'app-listing-products',
  templateUrl: './listing-products.component.html',
  styleUrls: ['./listing-products.component.scss']
})
export class ListingProductsComponent implements OnInit {
  columns = [];
  ColumnMode = ColumnMode;
  _subscriberData: any;
  public productsData: Array<any> = [];
  public response: any;
  public response2: any;

  totalItems=0
  page = 1;
  filters: { page: number; count: number; search: string; role:string} = {
    page: 1,
    count: 10,
    search: '',
    role: 'user'
  };

  constructor(
    private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private credentialService: CredentialsService,
    private router: Router,
    private productService: ProductsService
  ) { }

  ngOnInit() {
   
    if (this._activateRouter.snapshot.params['page']) {

      this.filters.page = JSON.parse(this._activateRouter.snapshot.params['page']);
      this.page = this.filters.page;
      console.log(this.filters.page, typeof (this._activateRouter.snapshot.params['page']))
      Object.assign(this.filters, { page: this.filters.page });
      this.getAllProducts();
    } else {
      this.page = 1
      this.getAllProducts();
    }
  }

  getAllProducts() {
    this.spinner.show();
    let data = this.filters
    
    this._subscriberData = this.productService.getAllProducts(data).subscribe((response) => {
      if (response.data.length == 0 && data.page > 1) {
        data.page = data.page -1
        this._subscriberData = this.productService.getAllProducts(data).subscribe((response) => {
          if (response.data.length == 0 && data.page > 1) {
            this.productsData = [];
            this.totalItems = response.total;       
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [response.message]
           });
          } else {
            this.productsData = response.data.map(data => {
              return {
                id: data._id,
                name: data.name,
                quantity: data.quantity,
                date: data.createdAt,  
                category_id:data.category_id,      
                createdAt: data.createdAt,          
                status: data.status
              }
            });
            this.totalItems = response.total;      
            this.spinner.hide();
          }
        },
        error=>{
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [error]
         });
        }
        );
      }else if(response.data.length == 0){
        this.productsData = [];
        this.totalItems = response.total;       
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: '',
          messages: [response.message]
       });
      } else {
        this.productsData = response.data.map(data => {
          return {
            id: data._id,
            name: data.name,
            quantity: data.quantity,
            date: data.createdAt,  
            category_id:data.category_id,      
            createdAt: data.createdAt,          
            status: data.status
          }
        });
        this.totalItems = response.total;      
        this.spinner.hide();
      }
    },
    error=>{
      this.spinner.hide();
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: '',
        messages: [error]
     });
    }
    );
  }

  setPage(e) {
    console.log(e);
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: this.page });  
    this.getAllProducts();
  }

  searchValue() {
    this.page = 1;
    Object.assign(this.filters, { page: this.page, search: this.filters.search });
    this.getAllProducts();
  }

  clearValue() {
    this.page = 1;
    this.filters.search = '';
    Object.assign(this.filters, { page: this.page, search: this.filters.search });
    this.getAllProducts();
  }



  view(companyID, type) {  
    let route = '/products/view/' + companyID + '/' + this.page;
    this.router.navigate([route]);
  }
  add() {
    let route = '/products/add';    
    this.router.navigate([route]);
  }
  edit(ID) {
    console.log(ID);   
    let route = '/products/edit/' + ID + '/' + this.page;
    this.router.navigate([route]);
  }

    /* Function use to remove Crop with crop id */
    deleteRecord(ID) {
      if (confirm("Do you want to delete this product?")) {
        this.spinner.show();
  
        let obj = {
          id: ID,
          model: 'product'
        }
  
        this.productService.deleteRecord(obj).subscribe((res: any) => {
          if (res.success) {
            this.response = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
           });
           
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.error.message]
           });
             }
          this.getAllProducts();
          this.spinner.hide();
        }, err => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
            messages: [err]
         });
        });
      }
    }

    changeStatus(data, ID) {
      let Status = '';
      let message = 'Do you want to activate this  product?';
      if (data == 'active') {
        Status = "deactive";
        message = 'Do you want to deactivate this  product?';
      } else {
        Status = "active";
      }
  
  
      if (confirm(message)) {
        this.spinner.show();
        this.productService.status(ID, 'product', Status).subscribe((res: any) => {
          if (res.success) {
            this.response2 = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
           });
           
            this.getAllProducts();
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [res.error.message]
           });
            
          }
          this.spinner.hide();
        }, err => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [err]
         });
            });
      }
    }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this._subscriberData){
      this._subscriberData.unsubscribe();
    }
  }

}
