import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdvertisementMgmtRoutingModule } from './advertisement-mgmt-routing.module';
import { AddUpdateAdvertiseComponent } from './add-update-advertise/add-update-advertise.component';
import { ListingAdvertiseComponent } from './listing-advertise/listing-advertise.component';
import { ViewAdvertiseComponent } from './view-advertise/view-advertise.component';
import { ContentMgmtRoutingModule } from '../content-mgmt/content-mgmt-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxCkeditorModule } from 'ngx-ckeditor4';


@NgModule({
  declarations: [AddUpdateAdvertiseComponent, ListingAdvertiseComponent, ViewAdvertiseComponent],
  imports: [
    CommonModule,
    AdvertisementMgmtRoutingModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxCkeditorModule.forRoot({
      url: "https://cdn.bootcss.com/ckeditor/4.11.3/ckeditor.js",
      config: {
        filebrowserUploadMethod: "xhr",
        filebrowserUploadUrl: "http://127.0.0.1:8000/index/index/uploads"
      }
    }),
  ]
})
export class AdvertisementMgmtModule { }
