import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContentMgmtService } from '../content-mgmt.service';
import { CkeditorService } from "ngx-ckeditor4";

@Component({
  selector: 'app-update-content',
  templateUrl: './update-content.component.html',
  styleUrls: ['./update-content.component.scss']
})
export class UpdateContentComponent implements OnInit {
  public Form: FormGroup;
  submitted = false;
  private _formObservable: any;
  public meta_Id: any;
  conditionalForm: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private contentMgmtServices: ContentMgmtService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute

  ) { this.createForm(); }

  createForm() {
    this.Form = this.formBuilder.group({
      title: ['', Validators.required],
      meta_title: ['', Validators.required],
      meta_description: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  get f() { return this.Form.controls; }

  ngOnInit() {
    this.meta_Id = this._activateRouter.snapshot.params['slug'];

    if (this.meta_Id) {
      this.conditionalForm = true;
      this.spinner.show();
      this.contentMgmtServices.get(this.meta_Id).subscribe((res: any) => {
        if (res.success) {

          this.Form.patchValue({
            title: res.data.title,
            meta_title: res.data.meta_title,
            meta_description: res.data.meta_description,
            description: res.data.description
          });
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
          });
        }
        this.spinner.hide();
      },
        error => {
          this.spinner.hide();

          //  this.ngxNotificationMsgService.open({
          //     status: NgxNotificationStatusMsg.FAILURE,
          //    header: '',
          //   messages: [error]
          //  });
        });
    } else {
      this.conditionalForm = false;
    }

  }


  onSubmit() {
    this.submitted = true;

    if (!this.Form.invalid) {
      this.spinner.show();

      if (this.Form) {

        this._formObservable = this.contentMgmtServices.updateContent(this.Form.value, this.meta_Id).subscribe(res => {
          if (res.success) {

            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
            });
            
            this.router.navigate(['/contents']);

          } else {

            //this.ngxNotificationMsgService.open({
            //  status: NgxNotificationStatusMsg.FAILURE,
            //  header: '',
            //  messages: [res.error.message]
            //});
          }
          this.spinner.hide();
        },
          error => {
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [error]
            });
          }
        );

      }


    }

  }

  ngOnDestroy(): void {
    if (this._formObservable) {
      this._formObservable.unsubscribe();
    }
  }
}




