import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RvCompanyListingComponent } from './rv-company-listing/rv-company-listing.component';
import { ViewDetailsComponent } from './view-details/view-details.component';


const routes: Routes = [
  {
    path:'',
    component:RvCompanyListingComponent
  },
  {
    path:'view/:id',
    component:ViewDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RvCompaniesRoutingModule { }
