import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from "@swimlane/ngx-datatable";
import {
  NgxNotificationMsgService,
  NgxNotificationStatusMsg,
} from "ngx-notification-msg";
import { NgxSpinnerService } from "ngx-spinner";
import { CredentialsService } from "src/app/auth/credentials.service";
import { environment } from "src/environments/environment";
import { ReportsService } from "../reports.service";

@Component({
  selector: "app-reports-listing",
  templateUrl: "./reports-listing.component.html",
  styleUrls: ["./reports-listing.component.scss"],
})
export class ReportsListingComponent implements OnInit {
  columns = [];
  ColumnMode = ColumnMode;
  _subscriberData: any;
  public reportsData: Array<any> = [];
  public response: any;
  public response2: any;

  totalItems = 0;
  page = 1;
  filters: { page: number; count: number; search: string } = {
    page: 1,
    count: 10,
    search: "",
    // role: 'user',
    // isDeleted: false
  };
  _frontUrl = environment.front_url;
  constructor(
    private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private credentialService: CredentialsService,
    private router: Router,
    private reportService: ReportsService
  ) {}

  ngOnInit() {
    // this.filters.addedBy = this.credentialService.credentials.id;
    // this.filters.manager_id = this.credentialService.credentials.manager_id;
    if (this._activateRouter.snapshot.params["page"]) {
      this.filters.page = JSON.parse(
        this._activateRouter.snapshot.params["page"]
      );
      this.page = this.filters.page;
      console.log(
        this.filters.page,
        typeof this._activateRouter.snapshot.params["page"]
      );
      Object.assign(this.filters, { page: this.filters.page });
      this.getAllReports();
    } else {
      this.page = 1;
      this.getAllReports();
    }
  }

  getAllReports() {
    this.spinner.show();
    // this.isLoading = true;
    // if (this.role) {
    //   Object.assign(this.filters, { role: this.role });
    // }
    this._subscriberData = this.reportService
      .getAllReports(this.filters)
      .subscribe(
        (response) => {
          if (response.data.length == 0) {
            this.reportsData = [];
            this.totalItems = response.total;
            // this.isLoading = false;
            this.spinner.hide();
            // this.ngxNotificationMsgService.open({
            //   status: NgxNotificationStatusMsg.SUCCESS,
            //   header: "",
            //   messages: [response.message],
            // });
          } else {
            this.reportsData = response.data.map((data) => {
              return {
                id: data._id,
                postId: data.postId,

                post:
                  data.postType == "modPost" || data.postType == "groupPost"
                    ? data.postdata
                      ? data.postdata.name
                      : ""
                    : data.postdata
                    ? data.postdata.post
                    : "",
                postedBy: data.reportedByDetails
                  ? data.reportedByDetails.email
                  : "",
                date: data.createdAt,
                slug:
                  data.postType == "modPost"
                    ? data.postdata
                      ? data.postdata.slug
                      : ""
                    : data.postdata
                    ? data.postdata.slug
                    : "",
                type: data.type,
                createdAt: data.createdAt,
                deletedAt: data.deletedAt,
                status: data.status,
                postType: data.postType,
                // encPostData: btoa(JSON.stringify(data)),
              };
            });
            this.totalItems = response.total;
            // this.isLoading = false;
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [error],
          });
        }
      );
  }

  setPage(e) {
    console.log(e);
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: this.page });
    // let route = '/companies/companies/' + this.page;
    // this.router.navigate([route]);
    this.getAllReports();
  }

  searchValue() {
    this.page = 1;
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getAllReports();
  }

  clearValue() {
    this.page = 1;
    this.filters.search = "";
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getAllReports();
  }

  // changeStatus(data, ID) {
  //   let Status = '';
  //   let message = 'Do you want to activate this leave?';
  //   if (data == 'active') {
  //     Status = "deactive";
  //     message = 'Do you want to deactivate this leave?';
  //   } else {
  //     Status = "active";
  //   }

  //   if (confirm(message)) {
  //     this.spinner.show();
  //     this.leaveService.status(ID, 'users', Status).subscribe((res: any) => {
  //       if (res.success) {
  //         this.response2 = res;
  //         this.toastr.success(res.message);
  //         this.getAllReports();
  //       } else {
  //         this.toastr.error(res.error.message, 'Error');
  //       }
  //       this.spinner.hide();
  //     }, err => {
  //       this.spinner.hide();
  //       this.toastr.error('There are some errors, please try again after some time !', 'Error');
  //     });
  //   }
  // }

  //  /* Function use to remove Crop with crop id */
  //  remove(ID) {
  //   if (confirm("Do you want to cancel this leave?")) {
  //     this.spinner.show();

  //     let obj = {
  //       id: ID,
  //       model: 'users'
  //     }

  //     this.leaveService.deleteRecord(obj).subscribe((res: any) => {
  //       if (res.success) {
  //         this.response = res;
  //         this.toastr.success('Leave Removed Successfully');
  //       } else {
  //         this.toastr.error('Unable to delete at the moment, Please try again later', 'Error');
  //       }
  //       this.getAllReports();
  //       this.spinner.hide();
  //     }, err => {
  //       this.spinner.hide();
  //       this.toastr.error('There are some errors, please try again after some time !', 'Error');
  //     });
  //   }
  // }

  // edit(ID, type) {
  //   // this.companiesService.actionType.next(type)
  //   // this.companiesService.activePage.next(this.page)
  //   let route = '/leaves/edit/' + ID;
  //   this.router.navigate([route]);
  // }

  // viewUser(leaveId) {
  //   let route = '/leaves/list/' + leaveId + '/' + this.page;
  //   this.router.navigate([route]);
  // }

  view(postType, postId) {
    // let route = '/reports/view/' + companyID + '/' + this.page;
    // this.router.navigate([route]);
  }
  add() {
    let route = "/member/add/";
    // this.companiesService.actionType.next('active')
    this.router.navigate([route]);
  }
  edit(ID) {
    console.log(ID);
    // this.companiesService.actionType.next(type)
    // this.companiesService.activePage.next(this.page)
    let route = "/member/edit/" + ID + "/" + this.page;
    this.router.navigate([route]);
  }

  // deleteRecord(ID){
  //   if (confirm("Do you want to delete this report?")) {
  //     this.spinner.show();

  //     this.reportService.deleteRecord(ID).subscribe((res: any) => {
  //       if (res.success) {
  //         this.response = res;
  //         this.ngxNotificationMsgService.open({
  //           status: NgxNotificationStatusMsg.SUCCESS,
  //           header: '',
  //           messages: [res.message]
  //        });

  //       } else {
  //         this.ngxNotificationMsgService.open({
  //           status: NgxNotificationStatusMsg.SUCCESS,
  //           header: '',
  //           messages: [res.error.message]
  //        });
  //          }
  //       this.getAllReports();
  //       this.spinner.hide();
  //     }, err => {
  //       this.spinner.hide();
  //       this.ngxNotificationMsgService.open({
  //         status: NgxNotificationStatusMsg.SUCCESS,
  //         header: '',
  //         messages: [err]
  //      });
  //     });
  //   }
  // }
  deleteRecord(ID) {
    this.reportService.deleteRecord(ID).subscribe(
      (res: any) => {
        if (res.success) {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: "",
            messages: [res.message],
          });
          this.getAllReports();
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: "",
            messages: [res.error.message],
          });
        }
      },
      (err) => {}
    );
  }
  /* Function use to remove Crop with crop id */
  deletePost(ID, type, reportId) {
    if (confirm("Do you want to delete this post?")) {
      this.spinner.show();

      let obj = {
        id: ID,
        // model: 'users'
      };
      if (type == "normalPost") {
        this.reportService.deletePost(obj).subscribe(
          (res: any) => {
            if (res.success) {
              this.response = res;
              this.deleteRecord(reportId);
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.SUCCESS,
                header: "",
                messages: [res.message],
              });
            } else {
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.SUCCESS,
                header: "",
                messages: [res.error.message],
              });
            }
            this.getAllReports();
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [err],
            });
          }
        );
      } else if (type == "modPost") {
        this.reportService.deleteModPost(obj).subscribe(
          (res: any) => {
            if (res.success) {
              this.response = res;
              this.deleteRecord(reportId);
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.SUCCESS,
                header: "",
                messages: [res.message],
              });
            } else {
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.SUCCESS,
                header: "",
                messages: [res.error.message],
              });
            }
            this.getAllReports();
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [err],
            });
          }
        );
      } else {
        this.reportService.deleteGroupPost(obj).subscribe(
          (res: any) => {
            if (res.success) {
              this.response = res;
              this.deleteRecord(reportId);
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.SUCCESS,
                header: "",
                messages: [res.message],
              });
            } else {
              this.ngxNotificationMsgService.open({
                status: NgxNotificationStatusMsg.SUCCESS,
                header: "",
                messages: [res.error.message],
              });
            }
            this.getAllReports();
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [err],
            });
          }
        );
      }
    }
  }

  changeStatus(data, ID) {
    let Status = "";
    let message = "Do you want to activate this  member?";
    if (data == "active") {
      Status = "deactive";
      message = "Do you want to deactivate this  member?";
    } else {
      Status = "active";
    }

    if (confirm(message)) {
      this.spinner.show();
      this.reportService.status(ID, "users", Status).subscribe(
        (res: any) => {
          if (res.success) {
            this.response2 = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.message],
            });

            this.getAllReports();
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: "",
              messages: [res.error.message],
            });
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [err],
          });
        }
      );
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
}
