import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthModule } from "../auth/auth.module";
import { DashboardModule } from "../dashboard/dashboard.module";
import { ProfileModule } from "../profile/profile.module";
import { AuthGuard } from "../shared/auth.guard";
import { LayoutComponent } from "./layout/layout.component";
import { MembersModule } from "../members/members.module";
import { CategoryModule } from "../category/category.module";
import { RolesMagmtModule } from "../roles-magmt/roles-magmt.module";
import { VendorsModule } from "../vendors/vendors.module";
import { ProductsModule } from "../products/products.module";
import { ContentMgmtModule } from "../content-mgmt/content-mgmt.module";
import { PointsMngmtModule } from "../points-mngmt/points-mngmt.module";
import { FaqModule } from "../faq/faq.module";
import { RvCategoriesModule } from "../rv-categories/rv-categories.module";
import { AdvertisementMgmtModule } from "../advertisement-mgmt/advertisement-mgmt.module";
import { PinnedPostMgmtModule } from "../pinned-post-mgmt/pinned-post-mgmt.module";
import { WelcomepageModule } from "../welcomepage/welcomepage.module";
import { TimeManagementModule } from "../time-management/time-management.module";
import { SubscriptionMgmtModule } from "../subscription-mgmt/subscription-mgmt.module";
import { ReportSectionModule } from "../report-section/report-section.module";
import { ModpostsModule } from "../modposts/modposts.module";
import { GroupListingModule } from "../group-listing/group-listing.module";
import { ContestMgmtModule } from "../contest-mgmt/contest-mgmt.module";
import { RenovationQuoteModule } from "../renovation-quote/renovation-quote.module";
import { RvCompaniesModule } from "../rv-companies/rv-companies.module";

// import { DifficultiesModule } from '../difficulties/difficulties.module';

const routes: Routes = [
  {
    path: "",
    redirectTo: "auth",
    pathMatch: "full",
  },
  {
    path: "auth",
    // loadChildren: '../auth/auth.module#AuthModule',
    loadChildren: () => AuthModule,
    // component: LayoutComponent,
    // canActivate: [ActiveRouteGuard]
  },
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        // loadChildren: './dashboard/dashboard.module#DashboardModule',
        loadChildren: () => DashboardModule,
      },

      {
        path: "profile",
        // loadChildren: './banners/banners.module#BannersModule',
        loadChildren: () => ProfileModule,
      },

      {
        path: "member",
        // loadChildren: './banners/banners.module#BannersModule',
        loadChildren: () => MembersModule,
      },
      {
        path: "vendor",
        loadChildren: () => VendorsModule,
      },
      {
        path: "categories",
        loadChildren: () => CategoryModule,
      },
      {
        path: "products",
        loadChildren: () => ProductsModule,
      },
      {
        path: "roles",
        loadChildren: () => RolesMagmtModule,
      },
      {
        path: "contents",
        loadChildren: () => ContentMgmtModule,
      },
      {
        path: "points",
        loadChildren: () => PointsMngmtModule,
      },
      {
        path: "faq",
        loadChildren: () => FaqModule,
      },
      {
        path: "rv-categories",
        loadChildren: () => RvCategoriesModule,
      },
      {
        path: "advertisements",
        loadChildren: () => AdvertisementMgmtModule,
      },
      {
        path: "pinned-post",
        loadChildren: () => PinnedPostMgmtModule,
      },
      {
        path: "contests",
        loadChildren: () => ContestMgmtModule,
      },
      {
        path: "welcome-page",
        loadChildren: () => WelcomepageModule,
      },
      {
        path: "time-range",
        loadChildren: () => TimeManagementModule,
      },
      {
        path: "subscribers",
        loadChildren: () => SubscriptionMgmtModule,
      },
      {
        path: "reports",
        loadChildren: () => ReportSectionModule,
      },
      {
        path: "modposts",
        loadChildren: () => ModpostsModule,
      },
      {
        path: "groups",
        loadChildren: () => GroupListingModule,
      },
      {
        path: 'renovation-quotes',
        loadChildren: () => RenovationQuoteModule
      },
      {
        path: 'rv-companies',
        loadChildren: () => RvCompaniesModule
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ThemeRoutingModule { }
