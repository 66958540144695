import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RvCategoriesRoutingModule } from './rv-categories-routing.module';
import { AddUpdateCategoryComponent } from './add-update-category/add-update-category.component';
import { AddUpdateSubcategoryComponent } from './add-update-subcategory/add-update-subcategory.component';
import { ListingCategoryComponent } from './listing-category/listing-category.component';
import { SubcategoryListingComponent } from './subcategory-listing/subcategory-listing.component';
import { ViewCategoryComponent } from './view-category/view-category.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [AddUpdateCategoryComponent, AddUpdateSubcategoryComponent,ListingCategoryComponent, SubcategoryListingComponent, ViewCategoryComponent],
  imports: [
    CommonModule,
    RvCategoriesRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxSpinnerModule
  ]
})
export class RvCategoriesModule { }
