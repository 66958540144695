import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CategoryRoutingModule } from './category-routing.module';
import { AddUpdateCategoryComponent } from './add-update-category/add-update-category.component';
import { ListingCategoryComponent } from './listing-category/listing-category.component';
import { ViewCategoryComponent } from './view-category/view-category.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SubcategoryListingComponent } from './subcategory-listing/subcategory-listing.component';
import { AddUpdateSubcategoryComponent } from './add-update-subcategory/add-update-subcategory.component';


@NgModule({
  declarations: [SubcategoryListingComponent,AddUpdateCategoryComponent, ListingCategoryComponent, ViewCategoryComponent, SubcategoryListingComponent, AddUpdateSubcategoryComponent],
  imports: [
    CommonModule,
    CategoryRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxSpinnerModule
  ]
})
export class CategoryModule { }
