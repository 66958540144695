import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProfileService } from '../profile.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { BehaviorService } from 'src/app/shared/behavior.service';
import { environment } from 'src/environments/environment';
import { PinnedPostService } from 'src/app/pinned-post-mgmt/pinned-post.service';
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  public Form: FormGroup;
  submitted = false;
  profileObservable:any;
  user:any;
  token:any;
  ID:any;
  userRole:any;
  fileToUpload:any;
  dImg = '/assets/img/placeholder.jpg';
  imageBase:any;
  isEdit = false;
  userImage:any;
  pinnedImage:any;
  public _host = environment.url;

  constructor(
    private formBuilder: FormBuilder,
    private router:Router,
    private profileService: ProfileService,
    private spinner: NgxSpinnerService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _bs: BehaviorService,
    private pinnPostService: PinnedPostService
  ) { }

  ngOnInit() {
    this.createForm();
    this.getUserDetail();
  }

  getUserDetail(){
    this.spinner.show();
    this.profileService.getDetail().subscribe((res:any)=>{
      if(res.success){
        this.user = res.data;
        this.Form.patchValue({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.email,
          mobileNo: res.data.mobileNo,
          image: this.user.image
        });
        this.userImage = this.user.image;
        this.pinnedImage = this.user.pinnedPostImage;
        // this._bs.setUserData(res.data);
      }else{
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [res.message]
       });
      }
      this.spinner.hide();
    },
    error=>{
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: '',
        messages: [error]
     });
      this.spinner.hide();
    }
    )
  }

  uploadImage(files: FileList) {
    
    this.fileToUpload = files.item(0)
 
    this.spinner.show();
    this.profileService.uploadImage(this.fileToUpload,'users').subscribe((res: any) => {
      if (res.success) {
        this.userImage = res.data.imagePath;
        this.Form.patchValue({ image: this.userImage })
      } else {
        window.scrollTo(0, 0);
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [res.error.message]
        });
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: '',
        messages: ['There are some errors, please try again after some time !', 'Error']
      });
      
    });
  }

  uploadPinnedImage(files: FileList) {
    console.log(files)
    this.fileToUpload = files.item(0)
 
    this.spinner.show();
    this.profileService.uploadImage(this.fileToUpload,'pinnedPost').subscribe((res: any) => {
      if (res.success) {
        this.pinnedImage = res.data.imagePath;
        this.Form.patchValue({ pinnedPostImage: this.pinnedImage })
        this.uploadPinnedUserImage(files);
        console.log(this.Form.value)
      } else {
        window.scrollTo(0, 0);
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [res.error.message]
        });
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: '',
        messages: ['There are some errors, please try again after some time !', 'Error']
      });
      
    });
  }

  uploadPinnedUserImage(files: FileList) {
    console.log(files)
    this.fileToUpload = files.item(0)
 
    this.spinner.show();
    this.profileService.uploadImage(this.fileToUpload,'users').subscribe((res: any) => {
      if (res.success) {
        // this.pinnedImage = res.data.imagePath;
        // this.Form.patchValue({ pinnedPostImage: this.pinnedImage })
        // console.log(this.Form.value)
      } else {
        window.scrollTo(0, 0);
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [res.error.message]
        });
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: '',
        messages: ['There are some errors, please try again after some time !', 'Error']
      });
      
    });
  }


  createForm() {
		this.Form = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern(/^((?!\s{2,}).)*$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^((?!\s{2,}).)*$/)]],
	    image:[''],
      mobileNo: ['', [Validators.required, Validators.min(1000000000),Validators.max(999999999999)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%. +-]+@[a-z0-9.-]+\\.[a-z]{2,4}.$")]],
      pinnedPostImage: ['']
    });
  }
  get f() { return this.Form.controls; }

  onSubmit() {

    console.log("submitting the form",!this.Form.invalid,this.Form)
    this.submitted = true;
    if (!this.Form.invalid) {
      this.spinner.show();
      let data = this.Form.value;
      data['id'] = this.user.id;
      this.profileObservable = this.profileService.update(data).subscribe(res => {
        let url;
        if (res.success) {
          this.user.firstName = this.Form.value.firstName
          this.user.lastName = this.Form.value.lastName
          this.user.mobileNo = this.Form.value.mobileNo
          this.user.image = this.Form.value.image
          this.user.pinnedPostImage = this.Form.value.pinnedPostImage
          const storage = localStorage;
          storage.setItem('user', JSON.stringify(this.user));
          this._bs.setUserData(this.user);
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
            messages: [res.message]
         });
         let key={
           pinnedPostImage:this.pinnedImage
         }
         this.pinnPostService.updateAdd(key,'618b9383713c825d5505b1f2').subscribe(res => {
          if (res.success) {
          //   this.ngxNotificationMsgService.open({
          //     status: NgxNotificationStatusMsg.SUCCESS,
          //     header: '',
          //     messages: [res.message]
          //  });
          //  this.router.navigate(['/advertisements']);
          } else {
          
           this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
         });
          }     
          this.spinner.hide();   
        },
          error => {
             this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [error]
           });
          }
        );
          url = '/profile/myprofile';
          this.router.navigate([url]);
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.message]
         });
        }
        this.spinner.hide();
      },
        error => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [error]
         });
        });
    }
  }
}
