import { Component, OnInit, ViewChild } from "@angular/core";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { CredentialsService } from "src/app/auth/credentials.service";
import {
  NgxNotificationMsgService,
  NgxNotificationStatusMsg,
} from "ngx-notification-msg";
import { ContestMgmtService } from "../contest-mgmt.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-view-mods",
  templateUrl: "./view-mods.component.html",
  styleUrls: ["./view-mods.component.scss"],
})
export class ViewModsComponent implements OnInit {
  columns = [];
  ColumnMode = ColumnMode;
  Copylink: any = "";
  _subscriberData: any;
  public contestData: Array<any> = [];
  public response: any;
  public response2: any;
  tableOffset = 0;

  totalItems = 0;
  public _baseUrl = environment.front_url;
  page = 1;
  filters: {
    page: number;
    count: number;
    search: string;
    isDeleted: Boolean;
    contest: string;
  } = {
    page: 1,
    count: 10,
    search: "",
    contest: "",
    isDeleted: false,
  };

  constructor(
    private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private credentialService: CredentialsService,
    private router: Router,
    private contestMgmtService: ContestMgmtService
  ) {}

  ngOnInit() {
    // this.filters.addedBy = this.credentialService.credentials.id;
    // this.filters.manager_id = this.credentialService.credentials.manager_id;
    if (this._activateRouter.snapshot.params["id"]) {
      this.filters.contest = this._activateRouter.snapshot.params["id"];
    }
    if (this._activateRouter.snapshot.params["page"]) {
      // this.filters.page = JSON.parse(
      //   this._activateRouter.snapshot.params["page"]
      // );
      this.page = this._activateRouter.snapshot.params["page"];
      console.log(
        this.filters.page,
        typeof this._activateRouter.snapshot.params["page"]
      );
      Object.assign(this.filters, { page: this.filters.page });
      this.getAllMods();
    } else {
      this.page = 1;
      this.getAllMods();
    }
  }

  getAllMods() {
    this.spinner.show();
    // this.isLoading = true;
    // if (this.role) {
    //   Object.assign(this.filters, { role: this.role });
    // }
    this._subscriberData = this.contestMgmtService
      .getAllMods(this.filters)
      .subscribe(
        (response) => {
          if (response.data.length == 0) {
            this.contestData = [];
            this.totalItems = response.total;
            // this.isLoading = false;
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [response.message],
            });
          } else {
            this.contestData = response.data.map((data) => {
              return {
                id: data._id,
                title: data.name,
                slug: data.slug,
                totalViews: data.totalViews,
                winningTotal:data.winningTotal,
                userName: data.addedBydetails.fullName,
                votingDescription: data.votingDescription,
                submissionDeadline: data.submissionDeadline,
                votingDeadline: data.votingDeadline,
                couponPartnerName: data.couponPartnerName,
                date: data.createdAt,
                createdAt: data.createdAt,
                deletedAt: data.deletedAt,
                status: data.status,
                totalVoting: data.totalVoting,
                winnerAnnounced: data.winnerAnnounced,
                voteTimeLeft: this.checkdiffExistBwDates(data.votingDeadline),
              };
            });
            this.totalItems = response.total;
            // this.isLoading = false;
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [error],
          });
        }
      );
  }
  CopyLink(row) {
    this.Copylink = this._baseUrl + "contests/" + row.slug;
    let value = this.Copylink;
    navigator.clipboard.writeText(value);
    this.ngxNotificationMsgService.open({
      status: NgxNotificationStatusMsg.SUCCESS,
      header: "",
      messages: ["Copied URL for sharing!"],
    });
    // this.toastr.success("Copied URL to clipboard!");
  }
  announceWinner(row) {
    if (confirm("Are you sure you want to announce winners of this contest?")) {
      this.spinner.show();

      let obj = {
        contest: row.id,
      };

      this.contestMgmtService.announceWinners(obj).subscribe(
        (res: any) => {
          if (res.success) {
            this.response = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.message],
            });
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.error.message],
            });
          }
          this.getAllMods();
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: "",
            messages: [err],
          });
        }
      );
    }
  }

  setPage(e) {
    console.log(e);
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: this.page });
    // let route = '/companies/companies/' + this.page;
    // this.router.navigate([route]);
    this.getAllMods();
  }

  searchValue() {
    this.page = 1;
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getAllMods();
  }

  clearValue() {
    this.page = 1;
    this.filters.search = "";
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getAllMods();
  }
  // clearRoleValue() {
  //   this.page = 1;
  //   this.filters.role = "";
  //   Object.assign(this.filters, {
  //     page: this.page,
  //     role: this.filters.role,
  //   });
  //   this.getAllMods();
  // }
  // changeStatus(data, ID) {
  //   let Status = '';
  //   let message = 'Do you want to activate this leave?';
  //   if (data == 'active') {
  //     Status = "deactive";
  //     message = 'Do you want to deactivate this leave?';
  //   } else {
  //     Status = "active";
  //   }

  //   if (confirm(message)) {
  //     this.spinner.show();
  //     this.leaveService.status(ID, 'users', Status).subscribe((res: any) => {
  //       if (res.success) {
  //         this.response2 = res;
  //         this.toastr.success(res.message);
  //         this.getAllMods();
  //       } else {
  //         this.toastr.error(res.error.message, 'Error');
  //       }
  //       this.spinner.hide();
  //     }, err => {
  //       this.spinner.hide();
  //       this.toastr.error('There are some errors, please try again after some time !', 'Error');
  //     });
  //   }
  // }

  //  /* Function use to remove Crop with crop id */
  //  remove(ID) {
  //   if (confirm("Do you want to cancel this leave?")) {
  //     this.spinner.show();

  //     let obj = {
  //       id: ID,
  //       model: 'users'
  //     }

  //     this.leaveService.deleteRecord(obj).subscribe((res: any) => {
  //       if (res.success) {
  //         this.response = res;
  //         this.toastr.success('Leave Removed Successfully');
  //       } else {
  //         this.toastr.error('Unable to delete at the moment, Please try again later', 'Error');
  //       }
  //       this.getAllMods();
  //       this.spinner.hide();
  //     }, err => {
  //       this.spinner.hide();
  //       this.toastr.error('There are some errors, please try again after some time !', 'Error');
  //     });
  //   }
  // }

  // edit(ID, type) {
  //   // this.companiesService.actionType.next(type)
  //   // this.companiesService.activePage.next(this.page)
  //   let route = '/leaves/edit/' + ID;
  //   this.router.navigate([route]);
  // }

  // viewUser(leaveId) {
  //   let route = '/leaves/list/' + leaveId + '/' + this.page;
  //   this.router.navigate([route]);
  // }

  view(companyID) {
    // this.companiesService.actionType.next(type)
    let route = "/contests/view/" + companyID + "/" + this.page;
    this.router.navigate([route]);
  }
  // viewMods(slug) {
  //   // this.companiesService.actionType.next(type)
  //   let route = this._baseUrl + "/mods/" + slug;
  //   this.router.navigateByUrl(route);
  // }
  add() {
    let route = "/contests/add/";
    // this.companiesService.actionType.next('active')
    this.router.navigate([route]);
  }
  edit(ID) {
    console.log(ID);
    // this.companiesService.actionType.next(type)
    // this.companiesService.activePage.next(this.page)
    let route = "/contests/edit/" + ID + "/" + this.page;
    this.router.navigate([route]);
  }
  checkdiffExistBwDates(votedate) {
    var countDownDate = new Date(votedate).getTime();
    // Get today's date and time
    var now = new Date().getTime();
    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    let diffExist = false;
    if (days > 0 || hours > 0 || minutes > 0 || seconds > 0) {
      diffExist = true;
    }
    return diffExist;
  }

  /* Function use to remove Crop with crop id */
  deleteRecord(ID) {
    if (confirm("Do you want to delete this contest?")) {
      this.spinner.show();

      let obj = {
        id: ID,
      };

      this.contestMgmtService.deleteContest(obj).subscribe(
        (res: any) => {
          if (res.success) {
            this.response = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.message],
            });
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.error.message],
            });
          }
          this.getAllMods();
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: "",
            messages: [err],
          });
        }
      );
    }
  }

  changeStatus(data, ID) {
    let Status = "";
    let message = "Do you want to activate this contest?";
    if (data == "active") {
      Status = "deactive";
      message = "Do you want to deactivate this contest?";
    } else {
      Status = "active";
    }

    if (confirm(message)) {
      this.spinner.show();
      this.contestMgmtService.status(ID, "contests", Status).subscribe(
        (res: any) => {
          if (res.success) {
            this.response2 = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.message],
            });

            this.getAllMods();
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: "",
              messages: [res.error.message],
            });
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [err],
          });
        }
      );
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this._subscriberData) {
      this._subscriberData.unsubscribe();
    }
  }
}
