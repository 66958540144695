import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { CategoryService } from '../category.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-category',
  templateUrl: './view-category.component.html',
  styleUrls: ['./view-category.component.scss']
})
export class ViewCategoryComponent implements OnInit {
  categoryId: any;
  categoryData:any;
  public _host = environment.url;
  constructor(
    private categoryService: CategoryService,
    private spinner: NgxSpinnerService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute
  ) { }

  ngOnInit() {
    this.categoryId = this._activateRouter.snapshot.params['id'];
    this.catDetail();
  }

  catDetail(){
    this.spinner.show();
    this.categoryService.get(this.categoryId).subscribe((res: any) => {
      if (res.success) {
      this.categoryData = res.data;
        
      } else {
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [res.error.message]
       });
      }
      this.spinner.hide();
    },
      error => {
        this.spinner.hide();
  
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [error]
       });
      });
  }

}
