import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddUpdateAdvertiseComponent } from './add-update-advertise/add-update-advertise.component';
import { ListingAdvertiseComponent } from './listing-advertise/listing-advertise.component';
import { ViewAdvertiseComponent } from './view-advertise/view-advertise.component';


const routes: Routes = [
  {
	  path: '',
	  children: [
	   {
		  path: '',
		  component: ListingAdvertiseComponent
		},
		{
		  path: 'list',
		  component: ListingAdvertiseComponent
		},
		{
		  path: 'view/:id',
		  component: ViewAdvertiseComponent
		},
		{
		  path: 'edit/:id',
		  component: AddUpdateAdvertiseComponent
		},
    {
      path: 'add',
      component: AddUpdateAdvertiseComponent,
      data: {
        title: 'Add'
      }
    },
	  ]
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdvertisementMgmtRoutingModule { }
