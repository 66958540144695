import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from "@swimlane/ngx-datatable";
import {
  NgxNotificationMsgService,
  NgxNotificationStatusMsg,
} from "ngx-notification-msg";
import { NgxSpinnerService } from "ngx-spinner";
import { CredentialsService } from "src/app/auth/credentials.service";
import { environment } from "src/environments/environment";
import { ModpostsService } from "../modposts.service";

@Component({
  selector: "app-deleted-modposts",
  templateUrl: "./deleted-modposts.component.html",
  styleUrls: ["./deleted-modposts.component.scss"],
})
export class DeletedModpostsComponent implements OnInit {
  columns = [];
  ColumnMode = ColumnMode;
  _subscriberData: any;
  public reportsData: Array<any> = [];
  public response: any;
  public response2: any;

  totalItems = 0;
  page = 1;
  filters: { page: number; count: number; search: string } = {
    page: 1,
    count: 10,
    search: "",
    // role: 'user',
    // isDeleted: false
  };
  _frontUrl = environment.front_url;
  constructor(
    private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private credentialService: CredentialsService,
    private router: Router,
    private modpostsService: ModpostsService
  ) {}

  ngOnInit() {
    // this.filters.addedBy = this.credentialService.credentials.id;
    // this.filters.manager_id = this.credentialService.credentials.manager_id;
    if (this._activateRouter.snapshot.params["page"]) {
      this.filters.page = JSON.parse(
        this._activateRouter.snapshot.params["page"]
      );
      this.page = this.filters.page;
      console.log(
        this.filters.page,
        typeof this._activateRouter.snapshot.params["page"]
      );
      Object.assign(this.filters, { page: this.filters.page });
      this.getAllPosts();
    } else {
      this.page = 1;
      this.getAllPosts();
    }
  }

  getAllPosts() {
    this.spinner.show();
    // this.isLoading = true;
    // if (this.role) {
    //   Object.assign(this.filters, { role: this.role });
    // }
    this._subscriberData = this.modpostsService
      .getAllPosts(this.filters)
      .subscribe(
        (response) => {
          if (response.data.length == 0) {
            this.reportsData = [];
            this.totalItems = response.total;
            // this.isLoading = false;
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [response.message],
            });
          } else {
            this.reportsData = response.data.map((data) => {
              return {
                id: data.id,
                postId: data.id,

                post: data.name,
                postedBy: data.addedBydetails
                  ? data.addedBydetails.fullName
                  : "",
                deletedBy: data.addedBydetails
                  ? data.addedBydetails.fullName
                  : "",
                date: data.createdAt,
                slug: data.slug,
                type: data.type,
                createdAt: data.createdAt,
                deletedAt: data.deletedAt,
                status: data.status,
                postType: data.postType,
                // encPostData: btoa(JSON.stringify(data)),
              };
            });
            this.totalItems = response.total;
            // this.isLoading = false;
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [error],
          });
        }
      );
  }
  RestorePost(postId) {
    let data = {
      id: postId,
    };
    this.modpostsService.RestorePost(data).subscribe(
      (response) => {
        if (response.success) {
          this.getAllPosts();
        }
      },
      (error) => {
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: "",
          messages: [error],
        });
      }
    );
  }
  DeletePost(postId) {
    if (confirm("Are you sure you want to delete this post permanently?")) {
      this.spinner.show();

      let data = {
        id: postId,
      };

      this.modpostsService.DeletePost(data).subscribe(
        (res: any) => {
          if (res.success) {
            this.getAllPosts();
          } else {
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [err],
          });
        }
      );
    } else {
    }
  }

  setPage(e) {
    console.log(e);
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: this.page });
    // let route = '/companies/companies/' + this.page;
    // this.router.navigate([route]);
    this.getAllPosts();
  }

  searchValue() {
    this.page = 1;
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getAllPosts();
  }

  clearValue() {
    this.page = 1;
    this.filters.search = "";
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getAllPosts();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
}
