import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubscriptionMgmtRoutingModule } from './subscription-mgmt-routing.module';
import { AddUpdateSubscriptionNewsLetterComponent } from './add-update-subscription-news-letter/add-update-subscription-news-letter.component';
import { SubscriptionNewsLetterListingComponent } from './subscription-news-letter-listing/subscription-news-letter-listing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [AddUpdateSubscriptionNewsLetterComponent, SubscriptionNewsLetterListingComponent],
  imports: [
    CommonModule,
    SubscriptionMgmtRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxSpinnerModule
  ]
})
export class SubscriptionMgmtModule { }
