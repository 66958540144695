import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { PinnedPostService } from '../pinned-post.service';

@Component({
  selector: 'app-add-update-pinned-post',
  templateUrl: './add-update-pinned-post.component.html',
  styleUrls: ['./add-update-pinned-post.component.scss']
})
export class AddUpdatePinnedPostComponent implements OnInit {

  @ViewChild('myFileInput',{static: false}) myFileInput;
  public Form: FormGroup;
  submitted = false;
  _roleObservable:any;
  public _host = environment.url;
  user:any;
  token:any;
  ID:any;
  userRole:any;
  fileToUpload:any;
  dImg = '/assets/img/placeholder.jpg';
  imageBase:any;
  isEdit = false;
  private _formObservable: any;
  postId: any;
  headerTitle: string;
  submitTitle: string;
  userImage=[];
  clickCount:any;
  
  constructor(
    private formBuilder: FormBuilder,
    private router:Router,
    private spinner: NgxSpinnerService,
    private pinnPostService: PinnedPostService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute
  ) {this.createForm(); }

  ngOnInit() {

    this.postId = '618b9383713c825d5505b1f2';
    // this.pageNo = this._activateRouter.snapshot.params['page'];

    if (this.postId) {
      
      this.headerTitle = 'Update Pinnedpost';
      this.submitTitle = 'Update'
      this.spinner.show();
     this.getDeatil();
    } else {
      // this.Form.controls['email'].enable();
      this.headerTitle = 'Add Pinnedpost';
      this.submitTitle = 'Add'
    }

  }

  getDeatil(){
    this.pinnPostService.get(this.postId).subscribe((res: any) => {
      if (res.success) {
        this.clickCount = res.data.viewCount;
        this.Form.patchValue({
          title: res.data.title?res.data.title:'',
          postMessage: res.data.postMessage,
          image:res.data.image,
          postDisplay:res.data.postDisplay
        });
        this.userImage = res.data.image
      } else {
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [res.error.message]
       });
      }
      this.spinner.hide();
    },
      error => {
        this.spinner.hide();

        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [error]
       });
      });
  }

  uploadImage(fileData: FileList) {
    
          for (let j = 0; j < fileData.length; j++) {
            this.uploadCandidateDocument(fileData[j]);
          }
       
    
  }

  uploadCandidateDocument(files) {
    
    // this.fileToUpload = files.item(0)
 
    this.spinner.show();
    this.pinnPostService.uploadImage(files,'pinnedpost').subscribe((res: any) => {
      if (res.success) {
        // if (res.data.fullPath.length > 0 && res.data.imagePath.length > 0) {
          // for (let i = 0; i < res.data.fullPath.length; i++) {
            this.userImage.push(res.data.imagePath);
          // }
        // }
        this.myFileInput.nativeElement.value = '';
        // this.userImage = res.data.imagePath[0];
        this.Form.patchValue({ image: this.userImage })
      } else {
        window.scrollTo(0, 0);
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [res.error.message]
        });
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: '',
        messages: ['There are some errors, please try again after some time !', 'Error']
      });
      
    });
  }

  removeImage(image) {
    // this.Form.controls.image.setValue('');
    // this.userImage = '';
    let object = {
      imageName: image,
      modelName: 'pinnedpost'
    }
    this.pinnPostService.deleteImage(object).subscribe((res: any) => {
      if (res.success) {
        // this.userImage = '';   
        this.userImage = this.userImage.filter(x => x != image);   
        this.Form.patchValue({ image: this.userImage })
        this.onSubmit();
        this.spinner.hide();
      } else {
        // this.toastr.error(res.error.message, 'Error');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  createForm() {
		this.Form = this.formBuilder.group({
      title: ['', [Validators.required, Validators.pattern(/^((?!\s{2,}).)*$/)]],
      postMessage: ['', Validators.required],
      image:['', Validators.required],
      postDisplay:['']
    });
  }
  get f() { return this.Form.controls; }

  onSubmit() {
    this.submitted = true;
    if(this.Form.value.image==''){
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: '',
        messages: ['Please upload an image!']
     });
     return;
    }
    if (!this.Form.invalid) {
      this.spinner.show();
      if(this.postId){
        let data= this.Form.value;
        // data['image'] = this.userImage;
        
        this._formObservable = this.pinnPostService.updateAdd(data,this.postId).subscribe(res => {
          if (res.success) {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
           });
           this.getDeatil();
          //  this.router.navigate(['/advertisements']);
          } else {
          
           this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
         });
          }     
          this.spinner.hide();   
        },
          error => {
             this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [error]
           });
          }
        );
      }else{
       
        console.log( this.Form.value," this.Form.value")
        let data= this.Form.value;
       
        this._formObservable = this.pinnPostService.postAdd(data).subscribe(res => {
          if (res.success) {
          
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
           });
          //  this.router.navigate(['/advertisements']);
          } else {
          
           this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
         });
          }     
          this.spinner.hide();   
        },
          error => {
             this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [error]
           });
          }
        );
      }
     
    }
  }

  showHidePost(e){
    if(e.target.checked){
      this.Form.patchValue({
        postDisplay:true
      })
    }else{
      this.Form.patchValue({
        postDisplay:false
      })
    }
    this.onSubmit();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this._formObservable){
      this._formObservable.unsubscribe();
    }
  }

}
