import { Component, OnInit } from '@angular/core';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss']
})
export class ProfileDetailComponent implements OnInit {

  public user: any = {};
  userImage=""
  public _host = environment.url;
  
  constructor(
    private spinner: NgxSpinnerService,
    private profileService: ProfileService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
  ) { }

  ngOnInit() {
    this.getUserDetail();
  }

  getUserDetail(){
    this.spinner.show();
    this.profileService.getDetail().subscribe((res:any)=>{
      if(res.success){
        this.user = res.data;
        // this._bs.setUserData(res.data);
      }else{
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [res.message]
       });
      }
      this.spinner.hide();
    },
    error=>{
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: '',
        messages: [error]
     });
      this.spinner.hide();
    }
    )
  }

}
