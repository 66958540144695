import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddUpdateFaqComponent } from './add-update-faq/add-update-faq.component';
import { ListFaqComponent } from './list-faq/list-faq.component';
import { ViewFaqComponent } from './view-faq/view-faq.component';


const routes: Routes = [{
  path: '',
  data: {
    title: 'FAQ'
  },
  children: [
   {
      path: '',
      component: ListFaqComponent
    },{
      path: 'faq/:page',
      component: ListFaqComponent
    },
    {
      path: 'list',
      component: ListFaqComponent
    },
    {
      path: 'list/:id/:page',
      component: ViewFaqComponent
    },
    {
      path: 'add',
      component: AddUpdateFaqComponent
    },
    {
      path: 'edit/:id/:page',
      component: AddUpdateFaqComponent
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FaqRoutingModule { }
