import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private _baseUrl = environment.url;

  constructor(
    private httpClient: HttpClient
  ) { }

  /**
* @method
* @name addManager
* @description
* Authenticates the user.
* Request body:json {
       'email': string,
       'firstName': string,
       'lastName': string,
       'mobileNo': integer
* }
* @param context The Add manager parameters.
* @return Promise.
*/
update(context) {
  return this.httpClient.put(this._baseUrl + `editProfile`, context).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  );
}

getDetail(){
    return this.httpClient.get(this._baseUrl + 'user').pipe(
          map((response: any) => {
            return response;
          }),
          catchError(this.handleError)
        )
}

uploadImage(fileToUpload: File,type) {

  let params = '?modelName='+type
  const formData: FormData = new FormData();
  formData.append('file', fileToUpload, fileToUpload.name);
  return this.httpClient.post(this._baseUrl + 'product/uploadimage?modelName='+type, formData).pipe(
    map((response: any) => {
      console.log(response);
      return response;
    }),
    catchError(this.handleError)
  )
}

removeImage(data,modal){
  return this.httpClient.delete(this._baseUrl + `removeImage?name=`+data+'&model='+modal).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  );
}

getParams(parameters) {
  let params = new HttpParams();
  Object.keys(parameters).map((key) => {
    params = params.set(key, parameters[key]);
  })
  return params;
}

handleError(error: HttpErrorResponse) {
  console.log(error);
  if (error.error.code == 401) {
    return throwError('');
  } else if (error.error.code == 404) {
    return throwError(error.error.message);
  }
  return throwError(
    'Something bad happened; please try again later.');
}
}
