import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DeletedModpostsComponent } from "./deleted-modposts/deleted-modposts.component";

const routes: Routes = [
  {
    path: "",
    data: {
      title: "",
    },
    children: [
      {
        path: "deleted",
        component: DeletedModpostsComponent,
        data: {
          title: "List",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModpostsRoutingModule {}
