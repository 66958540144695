import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { NgxSpinnerService } from 'ngx-spinner';
import { MembersService } from 'src/app/members/members.service';

@Component({
  selector: 'app-view-quote',
  templateUrl: './view-quote.component.html',
  styleUrls: ['./view-quote.component.scss']
})
export class ViewQuoteComponent implements OnInit {
  id: any;
  memberData: any = {};
  rvType:any
  host=environment.admin_url
  constructor(
    private memberService: MembersService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute
  ) { }

  ngOnInit() {
    this.id = this._activateRouter.snapshot.params["id"];
    // console.log(this.id)
    this.getDetail();
  }

  getDetail() {
    this.spinner.show()
    this.memberService.viewQuotes(this.id).subscribe((res)=>{
      this.spinner.hide()
      this.memberData = res.data
      let splitrvv = this.memberData.rvType
      console.log(splitrvv , "splitrvvvvv")
      this.rvType = splitrvv.split("-").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
    
      // console.log(this.memberData , "response")
    })
  }
  capitalizeEveryWord(str) {
    // Split the string into an array of words
    const words = str.split(' ');

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the array of words back into a single string
    const capitalizedString = capitalizedWords.join(' ');

    return capitalizedString;
  }


  reditectUserPage(id:any){
    window.open(this.host+'member/view/'+id,'_blank')

  }
}
