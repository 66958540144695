import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FaqService } from './faq.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSpinnerModule } from 'ngx-spinner';

import { FaqRoutingModule } from './faq-routing.module';
import { ListFaqComponent } from './list-faq/list-faq.component';
import { ViewFaqComponent } from './view-faq/view-faq.component';
import { AddUpdateFaqComponent } from './add-update-faq/add-update-faq.component';

@NgModule({
  declarations: [AddUpdateFaqComponent, ListFaqComponent, ViewFaqComponent],
  imports: [
    CommonModule,
    FaqRoutingModule,
    CommonModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxDatatableModule
  ],
  providers: [FaqService]

})
export class FaqModule { }