import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddUpdateProductsComponent } from './add-update-products/add-update-products.component';
import { ListingProductsComponent } from './listing-products/listing-products.component';
import { ViewProductsComponent } from './view-products/view-products.component';



const routes: Routes = [
  {
    path: '',
    data: {
      title: 'product'
    },
    children: [
     {
        path: '',
        component: ListingProductsComponent,
        data: {
          title: 'List'
        }
      },
      {
        path: 'list',
        component: ListingProductsComponent,
        data: {
          title: 'List'
        }
      },
         {
        path: 'add',
        component: AddUpdateProductsComponent,
        data: {
          title: 'List'
        }
      },
      {
        path: 'view/:id/:page',
        component: ViewProductsComponent,
        data: {
          title: 'view'
        }
      },
      {
        path: 'edit/:id',
        component: AddUpdateProductsComponent
      },{
        path: 'edit/:id/:page',
        component: AddUpdateProductsComponent
      }
      ]
      }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
