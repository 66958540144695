import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddUpdateVendorsComponent } from './add-update-vendors/add-update-vendors.component';
import { ListingVendorsComponent } from './listing-vendors/listing-vendors.component';
import { ViewVendorsComponent } from './view-vendors/view-vendors.component';


// const routes: Routes = [];
const routes: Routes = [
  {
    path: '',
    data: {
      title: 'vendor'
    },
    children: [
     {
        path: '',
        component: ListingVendorsComponent,
        data: {
          title: 'List'
        }
      },
      {
        path: 'list',
        component: ListingVendorsComponent,
        data: {
          title: 'List'
        }
      },
         {
        path: 'add',
        component: AddUpdateVendorsComponent,
        data: {
          title: 'List'
        }
      },
      {
        path: 'view/:id/:page',
        component: ViewVendorsComponent,
        data: {
          title: 'view'
        }
      },
      {
        path: 'edit/:id',
        component: AddUpdateVendorsComponent
      },{
        path: 'edit/:id/:page',
        component: AddUpdateVendorsComponent
      }
      ]
      }

];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VendorsRoutingModule { }
