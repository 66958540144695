
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { CategoryService } from '../category.service';

@Component({
  selector: 'app-add-update-category',
  templateUrl: './add-update-category.component.html',
  styleUrls: ['./add-update-category.component.scss']
})
export class AddUpdateCategoryComponent implements OnInit {

  public Form: FormGroup;
  submitted = false;
  _roleObservable: any;
  public _host = environment.url;
  user: any;
  token: any;
  ID: any;
  userRole: any;
  fileToUpload: any;
  dImg = '/assets/img/placeholder.jpg';
  imageBase: any;
  isEdit = false;
  private _formObservable: any;
  categoryId: any;
  headerTitle: string;
  submitTitle: string;
  parentCategories: any = [];
  userImage: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private categoryService: CategoryService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute
  ) { this.createForm(); }

  ngOnInit() {
    this.getParentCategories();
    this.categoryId = this._activateRouter.snapshot.params['id'];
    // this.pageNo = this._activateRouter.snapshot.params['page'];

    if (this.categoryId) {

      this.headerTitle = 'Update Category';
      this.submitTitle = 'Update'
      this.spinner.show();
      this.categoryService.get(this.categoryId).subscribe((res: any) => {
        if (res.success) {

          this.Form.patchValue({
            name: res.data.name ? res.data.name : '',
            parentCategory: res.data.parentCategory ? res.data.parentCategory.id : '',
            cat_type: res.data.cat_type,
            description: res.data.description,

          });
          this.userImage = res.data.image
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
          });
        }
        this.spinner.hide();
      },
        error => {
          this.spinner.hide();

          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [error]
          });
        });
    } else {
      // this.Form.controls['email'].enable();
      this.headerTitle = 'Add Category';
      this.submitTitle = 'Add'
    }

  }

  getParentCategories() {
    let data = {
      'page': 1,
      'count': 1000
    }
    this.categoryService.getAllCategories(data).subscribe((response) => {
      if (response.success) {
        this.parentCategories = response.data
      }
      this.spinner.hide();

    },
      error => {
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [error]
        });
      }
    );
  }

  uploadImage(files: FileList) {

    this.fileToUpload = files.item(0)

    this.spinner.show();
    this.categoryService.uploadImage(this.fileToUpload, 'category').subscribe((res: any) => {
      if (res.success) {
        this.userImage = res.data.imagePath;
        this.Form.patchValue({ image: this.userImage })
      } else {
        window.scrollTo(0, 0);
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [res.error.message]
        });
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: '',
        messages: ['There are some errors, please try again after some time !', 'Error']
      });

    });
  }

  removeImage(image) {
    this.Form.controls.image.setValue('');
    this.userImage = '';
    let object = {
      Imagename: image,
      type: 'category'
    }
    this.categoryService.deleteImage(object).subscribe((res: any) => {
      if (res.success) {
        this.userImage = '';
        this.spinner.hide();
      } else {
        // this.toastr.error(res.error.message, 'Error');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }


  createForm() {
    this.Form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^((?!\s{2,}).)*$/)]],
      cat_type: ['', Validators.required],
      parentCategory: [''],
      image: [''],
      description: ['']
    });
  }
  get f() { return this.Form.controls; }

  onSubmit() {
    this.submitted = true;
    if (!this.Form.invalid) {
      this.spinner.show();
      if (this.categoryId) {
        // if(!this.Form.value.parentCategory){
        //   delete this.Form.value.parentCategory;
        // }
        let data = this.Form.value;
        data['id'] = this.categoryId;

        if (data['parentCategory']) {
          data['catType'] = "sub";
        }
        if (!data['parentCategory']) {
          delete data['parentCategory'];
        }
        if (data['cat_type'] == 'modPost') {
          delete data['parentCategory']
        }
        this._formObservable = this.categoryService.updateCategory(data).subscribe(res => {
          if (res.success) {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
            });
            this.router.navigate(['/categories']);
          } else {

            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [res.error.message]
            });
          }
          this.spinner.hide();
        },
          error => {
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [error]
            });
          }
        );
      } else {
        // if(!this.Form.value.parentCategory){
        //   delete this.Form.value.parentCategory;
        // }
        console.log(this.Form.value, " this.Form.value")
        let data = this.Form.value;
        if (data['parentCategory']) {
          data['catType'] = "sub";
        }
        if (!data['parentCategory']) {
          delete data['parentCategory'];
        }
        if (data['cat_type'] == 'modpost') {
          delete data['parentCategory']
        }
        this._formObservable = this.categoryService.addCategory(data).subscribe(res => {
          if (res.success) {

            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
            });
            this.router.navigate(['/categories']);
          } else {

            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [res.error.message]
            });
          }
          this.spinner.hide();
        },
          error => {
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [error]
            });
          }
        );
      }

    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this._formObservable) {
      this._formObservable.unsubscribe();
    }
  }


}
