import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from "@swimlane/ngx-datatable";
import {
  NgxNotificationMsgService,
  NgxNotificationStatusMsg,
} from "ngx-notification-msg";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { AdvertisementService } from "../advertisement.service";

@Component({
  selector: "app-listing-advertise",
  templateUrl: "./listing-advertise.component.html",
  styleUrls: ["./listing-advertise.component.scss"],
})
export class ListingAdvertiseComponent implements OnInit {
  rows: any[];
  columns = [];
  ColumnMode = ColumnMode;
  _subscriberData: any;
  public advertiseData: Array<any> = [];
  public response: any;
  public response2: any;
  isLoading: boolean = false;
  public _host = environment.url;
  totalItems = 0;
  page = 1;
  filters: { page: number; count: number; search: string } = {
    page: 1,
    count: 5,
    search: "",
  };
  afterDisplayAds: any;
  constructor(
    private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private router: Router,
    private advertiseService: AdvertisementService
  ) {}

  ngOnInit(): void {
    if (this._activateRouter.snapshot.params["page"]) {
      this.filters.page = JSON.parse(
        this._activateRouter.snapshot.params["page"]
      );
      this.page = this.filters.page;
      console.log(
        this.filters.page,
        typeof this._activateRouter.snapshot.params["page"]
      );
      Object.assign(this.filters, { page: this.filters.page });
      this.getAdvertisements();
    } else {
      this.page = 1;
      this.getAdvertisements();
    }
  }

  getAdvertisements() {
    this.spinner.show();
    this._subscriberData = this.advertiseService
      .getAllAdvertisements(this.filters)
      .subscribe(
        (response) => {
          if (response.data.length == 0) {
            this.advertiseData = [];
            this.totalItems = response.total;
            this.isLoading = true;
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [response.message],
            });
          } else {
            this.afterDisplayAds = response.afterDisplayAds;
            this.advertiseData = response.data.map((cat) => {
              return {
                id: cat.id,
                title: cat.title,
                date: cat.createdAt,
                status: cat.status,
                image: cat.image ? cat.image : "",
                mobileImage: cat.mobileImage ? cat.mobileImage : "",
                position: cat.position,
                clickCount: cat.clickCount,
                viewCount: cat.viewCount,
              };
            });

            this.totalItems = response.total;
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [error],
          });
        }
      );
  }

  setPage(e) {
    console.log(e);
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: this.page });
    this.getAdvertisements();
  }

  searchValue() {
    this.page = 1;
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getAdvertisements();
  }

  clearValue() {
    this.page = 1;
    this.filters.search = "";
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getAdvertisements();
  }

  view(ID) {
    let route = "/advertisements/view/" + ID;
    this.router.navigate([route]);
  }

  edit(ID) {
    console.log(ID);
    let route = "/advertisements/edit/" + ID;
    this.router.navigate([route]);
  }

  deleteRecord(ID) {
    if (confirm("Do you want to delete this advertisement?")) {
      this.spinner.show();
      this.advertiseService.deleteRecord(ID).subscribe(
        (res: any) => {
          if (res.success) {
            this.response = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.message],
            });
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.error.message],
            });
          }
          this.getAdvertisements();
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: "",
            messages: [err],
          });
        }
      );
    }
  }

  updateAdsAfter(afterpost) {
    let formData = {
      displayPosition: afterpost,
    };
    this.advertiseService.updateShowAdd(formData).subscribe(
      (res) => {
        if (res.success) {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: "",
            messages: [res.message],
          });
          this.getAdvertisements();
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [res.error.message],
          });
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: "",
          messages: [error],
        });
      }
    );
  }

  // changeStatus(data, ID) {
  //   let Status = '';
  //   let message = 'Do you want to activate this  content?';
  //   if (data == 'active') {
  //     Status = "deactive";
  //     message = 'Do you want to deactivate this  content?';
  //   } else {
  //     Status = "active";
  //   }
  //
  //
  //   if (confirm(message)) {
  //     this.spinner.show();
  //     this.advertiseService.status(ID, 'users', Status).subscribe((res: any) => {
  //       if (res.success) {
  //         this.response2 = res;
  //         this.ngxNotificationMsgService.open({
  //           status: NgxNotificationStatusMsg.SUCCESS,
  //           header: '',
  //           messages: [res.message]
  //
  //         });
  //
  //         this.getAdvertisements();
  //       } else {
  //         this.ngxNotificationMsgService.open({
  //           status: NgxNotificationStatusMsg.FAILURE,
  //           header: '',
  //           messages: [res.error.message]
  //         });
  //
  //       }
  //       this.spinner.hide();
  //     }, err => {
  //       this.spinner.hide();
  //       this.ngxNotificationMsgService.open({
  //         status: NgxNotificationStatusMsg.FAILURE,
  //         header: '',
  //         messages: [err]
  //       });
  //     });
  //   }
  // }

  ngOnDestroy(): void {
    if (this._subscriberData) {
      this._subscriberData.unsubscribe();
    }
  }
}
