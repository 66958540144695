import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportSectionRoutingModule } from './report-section-routing.module';
import { ReportsListingComponent } from './reports-listing/reports-listing.component';
import { ViewReportComponent } from './view-report/view-report.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [ReportsListingComponent, ViewReportComponent],
  imports: [
    CommonModule,
    ReportSectionRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxSpinnerModule
  ]
})
export class ReportSectionModule { }
