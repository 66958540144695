import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListingContentComponent } from './listing-content/listing-content.component';
import { UpdateContentComponent } from './update-content/update-content.component';
import { ViewContentComponent } from './view-content/view-content.component';

const routes: Routes = [
	{
	  path: '',
	  children: [
	   {
		  path: '',
		  component: ListingContentComponent
		},
		{
		  path: 'list',
		  component: ListingContentComponent
		},
		{
		  path: 'list/:slug',
		  component: ViewContentComponent
		},
		{
		  path: 'edit/:slug',
		  component: UpdateContentComponent
		}
	  ]
	}
  ];
  
@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ContentMgmtRoutingModule { }
