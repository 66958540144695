import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import {
  HttpClient,
  HttpClientModule,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { CredentialsService } from "./credentials.service";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private _baseUrl = environment.url;
  permissions: any = {};
  constructor(
    private httpClient: HttpClient,
    private credentialsService: CredentialsService
  ) {}

  /**
* @method
* @name login
* @description
* Authenticates the user.
* Request body:json {
       'email': string,
       'password': string
* }
* @param context The login parameters.
* @return Promise.
*/
  login(context) {
    return this.httpClient.post(this._baseUrl + `adminSignin`, context).pipe(
      map((response: any) => {
        // this.credentialsService.setCredentials(response.data);
        return response;
      }),
      catchError(this.handleError)
    );
  }

  submitOtp(context) {
    return this.httpClient.post(this._baseUrl + `verifiy/otp`, context).pipe(
      map((response: any) => {
        this.getPermissions(response.data);
        // response.data.permission=this.permissions
        // this.credentialsService.setCredentials(response.data);
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getPermissions(data) {
    let permissions = data.permission;
    for (var i = 0; i < permissions.length; i++) {
      this.permissions[permissions[i]["name"]] = permissions[i]["isChecked"];
    }
    data.permission = this.permissions;
    this.credentialsService.setCredentials(data);
  }

  // logout() {
  //   this.credentialsService.setCredentials();
  //   return of(true);
  // }

  changePass(params) {
    // let param = this.getParams(params);
    return this.httpClient.post(this._baseUrl + "changePassword", params).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getParams(parameters) {
    let params = new HttpParams();
    Object.keys(parameters).map((key) => {
      params = params.set(key, parameters[key]);
    });
    return params;
  }

  handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.error.code == 401) {
      return throwError("");
    } else if (error.error.code == 404) {
      return throwError(error.error.message);
    } else if (error.error.code == 400) {
      return throwError(error.error.message);
    }
    return throwError("Something bad happened; please try again later.");
  }
}
