import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddUpdatePinnedPostComponent } from './add-update-pinned-post/add-update-pinned-post.component';


const routes: Routes = [
  {
	  path: '',
	  children: [
	   {
		  path: '',
		  component: AddUpdatePinnedPostComponent
		}
	  ]
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PinnedPostMgmtRoutingModule { }
