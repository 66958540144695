import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "src/app/auth/credentials.service";
// import { MembersService } from "../gr.service";
import { GroupListingService} from "../group-listing.service"
import {
  NgxNotificationMsgService,
  NgxNotificationStatusMsg,
} from "ngx-notification-msg";

@Component({
  selector: 'app-group-member',
  templateUrl: './group-member.component.html',
  styleUrls: ['./group-member.component.scss']
})
export class GroupMemberComponent implements OnInit {
  Allgroup = [];
  Allgroup1 = [];
  @Input() groupId: any
  @Input() members: any
   @Input() totalMember :any
  columns = [];
  ColumnMode = ColumnMode;
  page: number;
  totalItems = 0;
  filters: {
    page: number;
    count: number;
    search: string;
    role: string;
    isDeleted: Boolean;
  } = {
    page: 1,
    count: 10,
    search: "",
    role: "",
    isDeleted: false,
  };

  constructor(  private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private credentialService: CredentialsService,
    private router: Router,
    private groupservice: GroupListingService ) { }

  ngOnInit() {
  // this.getGroupMembers() 
 

  }

  
  viewGroup(slug) {
    console.log("testdata",slug)
    // this.companiesService.actionType.next(type)
    let route = "/groups/"+slug ;
    this.router.navigate([route]);
  }
  getGroupMembers() {
    this.spinner.show();
    let data = this.filters
    data['groupid']=this.groupId
    this.groupservice.getGroupMembers(this.filters).subscribe(
      (res: any) => {
        
        this.members=res.data.map((data) => {
          console.log(data.isPrivate,"data.isPrivate")
          return {
            groupid: this.groupId,
            slug: data.userDetails.slug,
              //  groupType: "Private",
               role:data.userRole=='user' ? 'Member' :'Admin',
            name: data.userDetails.fullName,
             id: data.userId,
          };
        
        });
        this.totalMember = res.total;
      },
      (error) => {
        this.spinner.hide();

        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: "",
          messages: [error],
        });
      }
    );
  }

   deleteGroupMember(id) {
     
     if (confirm("Do you want to delete this Member?")) {
         let data = {
          groupId: this.groupId,
          userID: id,
        };
        this.groupservice.deleteGroupMember(data).subscribe(
          (res: any) => {
            if (res.success) {
              this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
                messages: [res.message]
            
              });
              this.getGroupMembers()
            } else {
              this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
            messages: [res.error.message]
         });
            }
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: '',
          messages: [err]
       });
          }
        );
    }
  }
  searchValue() {
    this.page = 1;
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getGroupMembers();
  }
  setPage(e) {
    console.log(e);
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: this.page });
    // let route = '/companies/companies/' + this.page;
    // this.router.navigate([route]);
    this.getGroupMembers();
  }

  clearValue() {
    this.page = 1;
    this.filters.search = "";
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getGroupMembers();
  }
}
