import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material';

@Component({
  selector: 'app-select-check-all',
  templateUrl: "./select-check-all.component.html",
  styleUrls: ['./select-check-all.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectCheckAllComponent {
  @Input() model: FormControl;
  @Input() values = [];
  @Input() text = 'Select All'; 
  @Output() ChangeEvent = new EventEmitter<any>();
  isChecked(): boolean {
    return this.model.value && this.values.length
      && this.model.value.length === this.values.length;
  }

  isIndeterminate(): boolean {
    return this.model.value && this.values.length && this.model.value.length
      && this.model.value.length < this.values.length;
  }

  toggleSelection(change: MatCheckboxChange): void {
    console.log(this.values,"values")
    if (change.checked) {
      let newvalues=[]
      newvalues=this.values.map((item:any)=>item.code)
      console.log(newvalues,"newvalues")
      this.model.setValue(newvalues);
      this.dataChanged(newvalues)
    } else {
      this.model.setValue([]);
      this.dataChanged([])
    }
  }
  dataChanged(item: any) {
    this.ChangeEvent.emit({ item, event: 'save' }) 
}
}