import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  NgxNotificationMsgService,
  NgxNotificationStatusMsg,
} from "ngx-notification-msg";
import { NgxSpinnerService } from "ngx-spinner";
import { RolesService } from "../roles.service";

@Component({
  selector: "app-edit-roles",
  templateUrl: "./edit-roles.component.html",
  styleUrls: ["./edit-roles.component.scss"],
})
export class EditRolesComponent implements OnInit {
  public roleId: any;
  page: 1;
  pageNo: any;

  public response: any;
  conditionalForm: boolean = false;

  public roleForm: FormGroup;
  submitted = false;
  permissionRequired = false;
  _roleObservable: any;

  rolePermissions = [
    {
      label: "Member's",
      name: "member_mgt",
      isChecked: true,
    },
    {
      label: "Categories",
      name: "categories_mgt",
      isChecked: true,
    },
    {
      label: "Roles",
      name: "roles_mgt",
      isChecked: true,
    },
    {
      label: "Content Management",
      name: "content_mgt",
      isChecked: false,
    },
    {
      label: "Point Management",
      name: "point_mgt",
      isChecked: false,
    },
    {
      label: "Ads Managemen't",
      name: "ads_mgt",
      isChecked: false,
    },
    {
      label: "RV Categories Management",
      name: "rv_categories_mgt",
      isChecked: true,
    },
    {
      label: "FAQ",
      name: "faq_mgt",
      isChecked: false,
    },
    {
      label: "Add Pinned Post",
      name: "pinned_mgt",
      isChecked: false,
    },
    {
      label: "Add Welcome Content",
      name: "welcome_content_mgt",
      isChecked: false,
    },
    {
      label: "Time Management",
      name: "time_mgt",
      isChecked: false,
    },
    {
      label: "Subscribers",
      name: "subscribers_mgt",
      isChecked: false,
    },
    {
      label: "Report",
      name: "report_mgt",
      isChecked: false,
    },
  ];

  constructor(
    private _activateRouter: ActivatedRoute,
    private roleService: RolesService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private router: Router
  ) {
    this.roleId = this._activateRouter.snapshot.params["id"];
    this.pageNo = this._activateRouter.snapshot.params["page"];
    this.createForm();
  }

  ngOnInit() {
    this.roleId = this._activateRouter.snapshot.params["id"];
    this.page = this._activateRouter.snapshot.params["page"];
    console.log(this.roleId, this.page);
    if (this.roleId) {
      this.conditionalForm = true;

      this.roleService.get(this.roleId).subscribe(
        (res: any) => {
          if (res.success) {
            this.roleForm.patchValue({
              label: res.data.label,
            });
            if (res.data.permission.length > 0) {
              this.rolePermissions = res.data.permission;
            }
            console.log(this.rolePermissions, "this.rolePermissions");
          } else {
            // this.toastr.error(res.error.message, 'Error');
          }
          // this.s/
        },
        (error) => {
          // this.spinner.hide();
          // this.toastr.error(error, 'Error');
        }
      );
    } else {
      this.conditionalForm = false;
    }
  }

  createForm() {
    this.roleForm = this.formBuilder.group({
      label: ["", Validators.required],
      permission: [],
    });
  }

  onSubmit() {
    this.submitted = true;
    this.permissionRequired = true;
    console.log(this.roleForm.controls.permission.value);
    this.roleForm.controls.permission.setValue(this.rolePermissions);
    this.rolePermissions.forEach((element) => {
      if (element.isChecked == true) {
        this.permissionRequired = false;
        return;
      }
    });

    if (this.permissionRequired) {
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: "",
        messages: ["Please select atleast one permission."],
      });
      return;
    }

    if (!this.roleForm.invalid) {
      this.spinner.show();

      this.roleService.updateRole(this.roleForm.value, this.roleId).subscribe(
        (res) => {
          let url;
          if (res.success) {
            url = "/roles";
            this.router.navigate([url]);
          } else {
            // this.toastr.error(res.message, 'Error');
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          // this.toastr.error(error, 'Error');
        }
      );
    }
  }

  get f() {
    return this.roleForm.controls;
  }
}
