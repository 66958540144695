import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ContentMgmtService } from '../content-mgmt.service';

@Component({
  selector: 'app-view-content',
  templateUrl: './view-content.component.html',
  styleUrls: ['./view-content.component.scss']
})

export class ViewContentComponent implements OnInit {
  public _host = environment.url;
  contentId: any;
  public contentData: any = {};
  conditionalForm: boolean = false;



  constructor(

    private contentmgmtService: ContentMgmtService,
    private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private contmgmtService: ContentMgmtService
  ) { }


  ngOnInit() {
    this.spinner.show();

    this.contentId = this._activateRouter.snapshot.params['slug'];
    if (this.contentId) {
      this.conditionalForm = true;
      this.contentmgmtService.get(this.contentId).subscribe((res: any) => {
        if (res.success) {
          this.contentData = res.data;
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
          });
        }
        this.spinner.hide();
      },
        error => {
          this.spinner.hide();
          // this.toastr.error(error, 'Error');
        });
    } else {
      this.conditionalForm = false;
    }
  }




}
