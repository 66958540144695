import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  NgxNotificationMsgService,
  NgxNotificationStatusMsg,
} from "ngx-notification-msg";
import { NgxSpinnerService } from "ngx-spinner";
import { MembersService } from "../members.service";

@Component({
  selector: "app-view-members",
  templateUrl: "./view-members.component.html",
  styleUrls: ["./view-members.component.scss"],
})
export class ViewMembersComponent implements OnInit {
  memberId: any;
  memberData: any = {};

  constructor(
    private memberService: MembersService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute
  ) {}

  ngOnInit() {
    this.memberId = this._activateRouter.snapshot.params["id"];
    this.getDetail();
  }
  edit() {
    let route = "/member/edit/" +this.memberId
    this.router.navigate([route]);
  }
  getDetail() {
    this.spinner.show();
    this.memberService.get(this.memberId).subscribe(
      (res: any) => {
        if (res.success) {
          // this.memberData = res.data[0];
          let data = res.data.map((cat) => {
            return {
              id: cat.id,
              firstName: cat.firstName,
              lastName: cat.lastName,
              email: cat.email,
              role:
              cat.role == "user"
                ? "User"
                : cat.role == "guest"
                ? "Guest"
                : cat.role == "sub_admin_1"
                ? "Sub Admin 1"
                : cat.role == "sub_admin"
                ? "Sub Admin"
                : cat.role == "sub_admin_2"
                ? "Sub Admin 2"
                : cat.role == "sub_admin_3"
                ? "Sub Admin 3"
                : "Admin",
              ownRV:cat.ownRV,
              companyName:cat.companyName,
              unclaimedName:cat.unclaimedName,
              businessLocationCity:cat.businessLocationCity,
              businessLocationState:cat.businessLocationState,
              stateServed:cat.stateServed           
            };
          });
          this.memberData = data[0];
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [res.error.message],
          });
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();

        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: "",
          messages: [error],
        });
      }
    );
  }

  viewPosts(userId) {
    // this.companiesService.actionType.next(type)
    let route = "/member/posts/" + userId;
    this.router.navigate([route]);
  }
}
