import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CredentialsService } from '../auth/credentials.service';

@Injectable({
  providedIn: 'root'
})
export class WelcomeService {

  private _baseUrl = environment.url;

  constructor(
    private credentialsService: CredentialsService,

    private httpClient: HttpClient

  ) { }

  get(id) {
    return this.httpClient.get(this._baseUrl + 'welcomepost?id='+id).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }


  uploadImage(fileToUpload: File,type) {

    // let params = '?modelName='+type
    const formData: FormData = new FormData();
    formData.append('data', fileToUpload, fileToUpload.name);
    // formData.append('modelName',type);
    return this.httpClient.post(this._baseUrl + 'uploadimages?modelName=welcome', formData).pipe(
      map((response: any) => {
        console.log(response);
        return response;
      }),
      catchError(this.handleError)
    )
  }
  deleteImage(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key])
      }
    }
    return this.httpClient.get(this._baseUrl + 'image/delete', { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    )
  }

  updateAdd(context,id) {
   return this.httpClient.put(this._baseUrl + `welcomepost/update?id=`+id, context).pipe(
     map((response: any) => {
       return response;
     }),
     catchError(this.handleError)
   );
 }

 postAdd(context){
  return this.httpClient.post(this._baseUrl + `add/pinnedpost`, context).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(this.handleError)
  );
 }

 

  getParams(parameters) {
    let params = new HttpParams();
    Object.keys(parameters).map((key) => {
      params = params.set(key, parameters[key]);
    })
    return params;
  }

  handleError(error: HttpErrorResponse) {
    // console.log(error);
    if (error.error.code == 401) {
      return throwError(error.error.message);
    } else if (error.error.code == 404) {
      return throwError(error.error.message);
    } else if (error.error.code == 400) {
      return throwError(error.error.message);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
}
