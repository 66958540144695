import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { NgxSpinnerService } from 'ngx-spinner';
import { CredentialsService } from 'src/app/auth/credentials.service';
import { MembersService } from 'src/app/members/members.service';

@Component({
  selector: 'app-renovation-listing',
  templateUrl: './renovation-listing.component.html',
  styleUrls: ['./renovation-listing.component.scss']
})
export class RenovationListingComponent implements OnInit {

  columns = [];
  ColumnMode = ColumnMode;
  _subscriberData: any;
  public membersData: Array<any> = [];
  public response: any;
  public response2: any;
  userIdArr: any;
  totalItems = 0;
  page = 1;
  filters: {
    page: number;
    count: number;
    search: string;
    role: string;
    isDeleted: Boolean;
    type: string,
  } = {
      page: 1,
      count: 10,
      search: "",
      role: "",
      isDeleted: false,
      type: 'renovators'
    };

  constructor(
    private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private credentialService: CredentialsService,
    private router: Router,
    private memberService: MembersService
  ) { }

  ngOnInit() {
    // this.filters.addedBy = this.credentialService.credentials.id;
    // this.filters.manager_id = this.credentialService.credentials.manager_id;
    if (this._activateRouter.snapshot.params["page"]) {
      this.filters.page = JSON.parse(
        this._activateRouter.snapshot.params["page"]
      );
      this.page = this.filters.page;
      // console.log(
      //   this.filters.page,
      //   typeof this._activateRouter.snapshot.params["page"]
      // );
      Object.assign(this.filters, { page: this.filters.page });
      this.getAllMembers();
    } else {
      this.page = 1;
      this.getAllMembers();
    }
  }



  setPage(e) {
    console.log(e);
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: this.page });
    // let route = '/companies/companies/' + this.page;
    // this.router.navigate([route]);
    this.getAllMembers();
  }

  searchValue() {
    this.page = 1;
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getAllMembers();
  }

  clearValue() {
    this.page = 1;
    this.filters.search = "";
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getAllMembers();
  }
  clearRoleValue() {
    this.page = 1;
    this.filters.role = "";
    Object.assign(this.filters, {
      page: this.page,
      role: this.filters.role,
    });
    this.getAllMembers();
  }

  getAllMembers() {
    this.spinner.show();
    this._subscriberData = this.memberService
      .getQuotes(this.filters)
      .subscribe(
        (response) => {
          if (response.data.length == 0) {
            this.membersData = [];

            this.totalItems = response.total;

            this.spinner.hide();
            // this.ngxNotificationMsgService.open({
            //   status: NgxNotificationStatusMsg.SUCCESS,
            //   header: "",
            //   messages: [response.message],
            // });
          } else {


            this.membersData = response.data.map((data) => {
              this.userIdArr = data.userId
              return {
                id: data._id,
                name: data.firstName + ' ' + data.lastName,
                email: data.email,
                phoneNumber: data.phoneNumber,
                // lastlogin: data.lastlogin,
                requestquotefrom: data.userId,
                date: data.createdAt,
                // role:
                //   data.role == "user"
                //     ? "User"
                //     : data.role == "guest"
                //     ? "Guest"
                //     : data.role == "sub_admin_1"
                //     ? "Sub Admin 1"
                //     : data.role == "sub_admin"
                //     ? "Sub Admin"
                //     : data.role == "sub_admin_2"
                //     ? "Sub Admin 2"
                //     : data.role == "sub_admin_3"
                //     ? "Sub Admin 3"
                //     : "Admin",
                createdAt: data.createdAt,
                deletedAt: data.deletedAt,
                // status: data.status,
              };
            });
            this.totalItems = response.total;
            // this.isLoading = false;
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [error],
          });
        }
      );
  }
  view(id) {

    let route = "/renovation-quotes/quote-details/" + id
    this.router.navigate([route]);
  }






  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this._subscriberData) {
      this._subscriberData.unsubscribe();
    }
  }
}
