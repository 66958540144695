import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddUpdateTimeComponent } from './add-update-time/add-update-time.component';
import { ListingTimeComponent } from './listing-time/listing-time.component';


const routes: Routes = [
  {
	  path: '',
	  children: [
	   {
		  path: '',
		  component: ListingTimeComponent
		},
		{
		  path: 'list',
		  component: ListingTimeComponent
		},
		// {
		//   path: 'view/:id',
		//   component: ViewAdvertiseComponent
		// },
		{
		  path: 'edit/:id',
		  component: AddUpdateTimeComponent
		},
    {
      path: 'add',
      component: AddUpdateTimeComponent,
      data: {
        title: 'Add'
      }
    },
	  ]
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TimeManagementRoutingModule { }
