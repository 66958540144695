import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { NgxSpinnerService } from 'ngx-spinner';
import { CredentialsService } from 'src/app/auth/credentials.service';
import { CategoryService } from '../category.service';

@Component({
  selector: 'app-subcategory-listing',
  templateUrl: './subcategory-listing.component.html',
  styleUrls: ['./subcategory-listing.component.scss']
})
export class SubcategoryListingComponent implements OnInit {

  columns = [];
  ColumnMode = ColumnMode;
  _subscriberData: any;
  public categoryData: Array<any> = [];
  public subCategoryData: Array<any> = [];
  public response: any;
  public response2: any;

  totalItems=0
  totalSubCats= 0
  page = 1;
  filters: { page: number; count: number; search: string; type:string;catType:string } = {
    page: 1,
    count: 10,
    search: '',
    type:'sub_categories',
    catType:''
  };

  constructor(
    private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private credentialService: CredentialsService,
    private router: Router,
    private categoryService: CategoryService
  ) { }

  ngOnInit() {
    // this.filters.addedBy = this.credentialService.credentials.id;
    // this.filters.manager_id = this.credentialService.credentials.manager_id;
    if (this._activateRouter.snapshot.params['page']) {

      this.filters.page = JSON.parse(this._activateRouter.snapshot.params['page']);
      this.page = this.filters.page;
      console.log(this.filters.page, typeof (this._activateRouter.snapshot.params['page']))
      Object.assign(this.filters, { page: this.filters.page });
      this.getAllSubCategories();
    } else {
      this.page = 1
      this.getAllSubCategories();
    }
  }

  getAllCategories() {
    this.spinner.show();
    // this.isLoading = true;
    // if (this.role) {
    //   Object.assign(this.filters, { role: this.role });
    // }
    this._subscriberData = this.categoryService.getAllCategories(this.filters).subscribe((response) => {
      if (response.data.length == 0) {
        this.categoryData = [];
        this.totalItems = response.total;
        // this.isLoading = false;
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: '',
          messages: [response.message]
       });
      } else {
        this.categoryData = response.data.map(data => {
          return {
            id: data.id,
            name: data.name,
            cat_type: data.cat_type,
            date: data.createdAt,
            createdAt: data.createdAt,
            deletedAt: data.deletedAt,
            status: data.status
          }
        });
        this.totalItems = response.total;
        // this.isLoading = false;
        this.spinner.hide();
      }
    },
    error=>{
      this.spinner.hide();
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: '',
        messages: [error]
     });
    }
    );
  }

  getAllSubCategories() {
    this.spinner.show();
   
    this._subscriberData = this.categoryService.getAllsubCategories(this.filters).subscribe((response) => {
    
      if (response.data.length == 0) {
        this.subCategoryData = [];
        this.totalSubCats = response.total;        
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: '',
          messages: [response.message]
       });
      } else {      
        this.subCategoryData = response.data.map(data => {
          return {
            id: data.id,
            name: data.name,
            cat_type: data.cat_type,
            date: data.createdAt,
            createdAt: data.createdAt,
            deletedAt: data.deletedAt,
            parentCategory: data.parentCategory.name,
            status: data.status
          }
        });
        this.totalSubCats = response.total;
     
        this.spinner.hide();
      }
    },
    error=>{
      this.spinner.hide();
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: '',
        messages: [error]
     });
    }
    );
  }

  setPage(e) {
    console.log(e);
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: this.page });
 
    this.getAllSubCategories();
  }

  searchValue() {
    this.page = 1;
    Object.assign(this.filters, { page: this.page, search: this.filters.search });
    this.getAllSubCategories();
  }

  clearValue() {
    this.page = 1;
    this.filters.search = '';
    Object.assign(this.filters, { page: this.page, search: this.filters.search });
    this.getAllSubCategories();
  }

  changeCategory(value){
    this.filters.catType = value;
    this.getAllSubCategories();
  }



  view(companyID) { 
    let route = '/categories/view/' + companyID + '/' + this.page;
    this.router.navigate([route]);
  }
  add() {
    let route = '/categories/add/'; 
    this.router.navigate([route]);
  }
  edit(ID) {
    console.log(ID); 
    let route = '/categories/edit/' + ID + '/' + this.page;
    this.router.navigate([route]);
  }

    /* Function use to remove Crop with crop id */
    deleteRecord(ID) {
      if (confirm("Do you want to delete this sub category?")) {
        this.spinner.show();
  
        let obj = {
          id: ID,
          model: 'category'
        }
  
        this.categoryService.deleteRecord(obj).subscribe((res: any) => {
          if (res.success) {
            this.response = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
           });
           
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.error.message]
           });
             }
          this.getAllSubCategories();
          this.spinner.hide();
        }, err => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
            messages: [err]
         });
        });
      }
    }

    changeStatus(data, ID) {
      let Status = '';
      let message = 'Do you want to activate this sub category?';
      if (data == 'active') {
        Status = "deactive";
        message = 'Do you want to deactivate this  sub category?';
      } else {
        Status = "active";
      }
  
  
      if (confirm(message)) {
        this.spinner.show();
        this.categoryService.status(ID, 'category', Status).subscribe((res: any) => {
          if (res.success) {
            this.response2 = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
           });
           
            this.getAllSubCategories();
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [res.error.message]
           });
            
          }
          this.spinner.hide();
        }, err => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [err]
         });
            });
      }
    }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this._subscriberData){
      this._subscriberData.unsubscribe();
    }
  }

}
