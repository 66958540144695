import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ContestMgmtService {
  private _baseUrl = environment.url;

  constructor(private httpClient: HttpClient) {}

  /**
   * @method
   * @name addContest
   * @description
   * Authenticates the
   * @param context The Add Contest parameters.
   * @return Promise.
   */
  addContest(context) {
    return this.httpClient.post(this._baseUrl + `add/contest`, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  /**
   * @method
   * @name add Common
   * @description
   * Authenticates the
   * @param context The Add Contest parameters.
   * @return Promise.
   */
  add(path, context) {
    return this.httpClient.post(this._baseUrl + path, context).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getAllContests(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key]);
      }
    }
    return this.httpClient
      .get(this._baseUrl + "contests", { params: params })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }
  getAllMods(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key]);
      }
    }
    return this.httpClient
      .get(this._baseUrl + "admin/contest/mods", { params: params })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }
  announceWinners(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key]);
      }
    }
    return this.httpClient
      .get(this._baseUrl + "count/voting", { params: params })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  unannounceWinners(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key]);
      }
    }
    return this.httpClient
      .get(this._baseUrl + "contest/unannouced", { params: params })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  get(id) {
    return this.httpClient.get(this._baseUrl + "contest?id=" + id).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  getWithParams(path, param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key]);
      }
    }
    return this.httpClient.get(this._baseUrl + path, { params: params }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  updateContest(data) {
    return this.httpClient.put(this._baseUrl + `contest/update`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  uploadImage(fileToUpload: File, type) {
    // let params = '?modelName='+type
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    // formData.append('modelName',type);
    return this.httpClient
      .post(this._baseUrl + "product/uploadimage?modelName=contests", formData)
      .pipe(
        map((response: any) => {
          console.log(response);
          return response;
        }),
        catchError(this.handleError)
      );
  }
  deleteImage(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key]);
      }
    }
    return this.httpClient
      .get(this._baseUrl + "image/delete", { params: params })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  deleteRecord(param?) {
    // let params = new HttpParams();
    // if (param) {
    //   for (let key of Object.keys(param)) {
    //     params = params.set(key, param[key])
    //   }
    // }
    return this.httpClient.put(this._baseUrl + "delete", param).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  deleteContest(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key]);
      }
    }
    return this.httpClient
      .delete(this._baseUrl + "contest/delete", { params: params })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  deletePost(param?) {
    let params = new HttpParams();
    if (param) {
      for (let key of Object.keys(param)) {
        params = params.set(key, param[key]);
      }
    }
    return this.httpClient
      .get(this._baseUrl + "post/delete", { params: params })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }
  status(id, model, status) {
    let url =
      this._baseUrl +
      "update/status?id=" +
      id +
      "&model=" +
      model +
      "&status=" +
      status;

    return this.httpClient.put(url, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getParams(parameters) {
    let params = new HttpParams();
    Object.keys(parameters).map((key) => {
      params = params.set(key, parameters[key]);
    });
    return params;
  }

  handleError(error: HttpErrorResponse) {
    // console.log(error);
    if (error.error.code == 401) {
      return throwError(error.error.message);
    } else if (error.error.code == 404) {
      return throwError(error.error.message);
    } else if (error.error.code == 400) {
      return throwError(error.error.message);
    }
    return throwError("Something bad happened; please try again later.");
  }
}
