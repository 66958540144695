import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RenovationQuoteRoutingModule } from './renovation-quote-routing.module';
import { RenovationListingComponent } from './renovation-listing/renovation-listing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CustomMaterialModule } from '../custom-material/custom-material.module';
import { SharedModule } from '../shared/shared.module';
import { ViewQuoteComponent } from './view-quote/view-quote.component';


@NgModule({
  declarations: [RenovationListingComponent, ViewQuoteComponent],
  imports: [
    CommonModule,
    RenovationQuoteRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxSpinnerModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    CustomMaterialModule,
    SharedModule
  ]
})
export class RenovationQuoteModule { }
