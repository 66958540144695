import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import {   Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private _baseUrl = environment.url;
  constructor(
    private http: HttpClient
  ) { }

  getDashboardData() {
   
       return this.http.get(this._baseUrl+'store/statistics');
    }

}
