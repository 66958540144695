import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { VendorsService } from '../vendors.service';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';

@Component({
  selector: 'app-add-update-vendors',
  templateUrl: './add-update-vendors.component.html',
  styleUrls: ['./add-update-vendors.component.scss']
})
export class AddUpdateVendorsComponent implements OnInit {

  public Form: FormGroup;
  submitted = false;
  _roleObservable:any;
  user:any;
  token:any;
  ID:any;
  userRole:any;
  fileToUpload:any;
  dImg = '/assets/img/placeholder.jpg';
  imageBase:any;
  isEdit = false;
  private _formObservable: any;
  vendorId: any;
  headerTitle: string;
  submitTitle: string;
  
  constructor(
    private formBuilder: FormBuilder,
    private router:Router,
    private spinner: NgxSpinnerService,
    private vendorService: VendorsService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute
  ) {this.createForm(); }

  ngOnInit() {
    
    this.vendorId = this._activateRouter.snapshot.params['id'];
    // this.pageNo = this._activateRouter.snapshot.params['page'];

    if (this.vendorId) {
      this.Form.controls['email'].disable();
      this.headerTitle = 'Update Vendor';
      this.submitTitle = 'Update'
      this.spinner.show();
      this.vendorService.get(this.vendorId).subscribe((res: any) => {
        if (res.success) {
        
          this.Form.patchValue({
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            role: res.data.role,
            email: res.data.email,
            mobileNo: res.data.mobileNo,
         
          });
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
         });
        }
        this.spinner.hide();
      },
        error => {
          this.spinner.hide();

          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [error]
         });
        });
    } else {
      this.Form.controls['email'].enable();
      this.headerTitle = 'Add Vendor';
      this.submitTitle = 'Add'
    }

  }


  createForm() {
		this.Form = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern(/^((?!\s{2,}).)*$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^((?!\s{2,}).)*$/)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%. +-]+@[a-z0-9.-]+\\.[a-z]{2,4}.$")]],
      role:['vendor'],
      mobileNo: ['', [Validators.min(1000000000), Validators.max(999999999999), Validators.pattern]],

    });
  }
  get f() { return this.Form.controls; }

  onSubmit() {
    this.submitted = true;
    if (!this.Form.invalid) {
      this.spinner.show();
      if(this.vendorId){
        let data= this.Form.value;
        data['id'] = this.vendorId;
        this._formObservable = this.vendorService.updateMvendor(data).subscribe(res => {
          if (res.success) {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
           });
           this.router.navigate(['/vendor']);
          } else {
          
           this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
         });
          }     
          this.spinner.hide();   
        },
          error => {
             this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [error]
           });
          }
        );
      }else{
        this._formObservable = this.vendorService.addMvendor(this.Form.value).subscribe(res => {
          if (res.success) {
          
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
           });
           this.router.navigate(['/vendor']);
          } else {
          
           this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
         });
          }     
          this.spinner.hide();   
        },
          error => {
             this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [error]
           });
          }
        );
      }
     
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this._formObservable){
      this._formObservable.unsubscribe();
    }
  }

}
