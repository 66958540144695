import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContentMgmtService } from '../content-mgmt.service';

@Component({
  selector: 'app-listing-content',
  templateUrl: './listing-content.component.html',
  styleUrls: ['./listing-content.component.scss']
})
export class ListingContentComponent implements OnInit {
  rows: any[];
  columns = [];
  ColumnMode = ColumnMode;
  _subscriberData: any;
  public contentData: Array<any> = [];
  public response: any;
  public response2: any;
  isLoading: boolean = false;

  totalItems = 0
  page = 1;
  filters: { page: number; count: number; search: string; } = {
    page: 1,
    count: 5,
    search: '',
  };
  constructor(
    private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private router: Router,
    private contentmgmtService: ContentMgmtService

  ) { }

  ngOnInit(): void {

    if (this._activateRouter.snapshot.params['page']) {

      this.filters.page = JSON.parse(this._activateRouter.snapshot.params['page']);
      this.page = this.filters.page;
      console.log(this.filters.page, typeof (this._activateRouter.snapshot.params['page']))
      Object.assign(this.filters, { page: this.filters.page });
      this.getContent();
    } else {
      this.page = 1
      this.getContent();
    }
  }

  getContent() {
    this.spinner.show();
    this._subscriberData = this.contentmgmtService.getAllContent(this.filters).subscribe((response) => {
      if (response.data.length == 0) {
        this.contentData = [];
        this.totalItems = response.total;      
        this.isLoading = true;
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: '',
          messages: [response.message]
        });
      } else {
        this.contentData = response.data.map(cat => {
          return {
            id: cat.id,
            title: cat.title,
            date: cat.createdAt,
            status: cat.status,
            slug: cat.slug
          }
        });
    
        this.totalItems = response.total;      
        this.spinner.hide();
      }
    },
      error => {
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [error]
        });
      }
    );

  }

  setPage(e) {
    console.log(e);
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: this.page });
    this.getContent();
  }

  searchValue() {
    this.page = 1;
    Object.assign(this.filters, { page: this.page, search: this.filters.search });
    this.getContent();
  }

  clearValue() {
    this.page = 1;
    this.filters.search = '';
    Object.assign(this.filters, { page: this.page, search: this.filters.search });
    this.getContent();
  }

  view(ID) {
    let route = '/contents/list/' + ID;
    this.router.navigate([route]);
  }

  edit(ID) {
    console.log(ID);
    let route = '/contents/edit/' + ID;
    this.router.navigate([route]);
  }

 // changeStatus(data, ID) {
 //   let Status = '';
 //   let message = 'Do you want to activate this  content?';
 //   if (data == 'active') {
 //     Status = "deactive";
 //     message = 'Do you want to deactivate this  content?';
 //   } else {
 //     Status = "active";
 //   }
//
//
 //   if (confirm(message)) {
 //     this.spinner.show();
 //     this.contentmgmtService.status(ID, 'users', Status).subscribe((res: any) => {
 //       if (res.success) {
 //         this.response2 = res;
 //         this.ngxNotificationMsgService.open({
 //           status: NgxNotificationStatusMsg.SUCCESS,
 //           header: '',
 //           messages: [res.message]
//
 //         });
//
 //         this.getContent();
 //       } else {
 //         this.ngxNotificationMsgService.open({
 //           status: NgxNotificationStatusMsg.FAILURE,
 //           header: '',
 //           messages: [res.error.message]
 //         });
//
 //       }
 //       this.spinner.hide();
 //     }, err => {
 //       this.spinner.hide();
 //       this.ngxNotificationMsgService.open({
 //         status: NgxNotificationStatusMsg.FAILURE,
 //         header: '',
 //         messages: [err]
 //       });
 //     });
 //   }
 // }
 


  ngOnDestroy(): void {
    if (this._subscriberData) {
      this._subscriberData.unsubscribe();
    }
  }

}




