import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MembersRoutingModule } from './members-routing.module';
import { AddUpdateMembersComponent } from './add-update-members/add-update-members.component';
import { ListingComponent } from './listing/listing.component';
import { ViewMembersComponent } from './view-members/view-members.component';


import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AllPostsComponent } from './all-posts/all-posts.component';
import { MatFormFieldModule, MatInputModule, MatSelectModule } from '@angular/material';
import { CustomMaterialModule } from '../custom-material/custom-material.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [AddUpdateMembersComponent, ListingComponent, ViewMembersComponent, AllPostsComponent],
  imports: [
    CommonModule,
    MembersRoutingModule,
     FormsModule, 
     ReactiveFormsModule,
     NgxDatatableModule,
     NgxSpinnerModule,
     MatSelectModule,
     MatFormFieldModule,
     MatInputModule,
     CustomMaterialModule,
     SharedModule
  ]
})
export class MembersModule { }
