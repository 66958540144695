import { Pipe, PipeTransform } from '@angular/core';
import { Country, State, City } from 'country-state-city';
@Pipe({
  name: 'stateName'
})
export class StateNamePipe implements PipeTransform {
  states: any = []
  transform(state: any) {
    this.states = State.getStatesOfCountry('US')
    let obj: any = {}
    obj = this.states.find((value: any) => {
      // Validates that has a keywords filter array
      if (value && value.name) {
        // If there's any matching, then return the value

        if (value.isoCode == state) {
          return value.name
        }
      } else {
        return state;
      }
    });

    return obj && obj.name ? obj.name : state
  }

}
