import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxNotificationStatusMsg } from "ngx-notification-msg";
import { NgxSpinnerService } from "ngx-spinner";
import { RolesService } from "../roles.service";

@Component({
  selector: "app-view-roles",
  templateUrl: "./view-roles.component.html",
  styleUrls: ["./view-roles.component.scss"],
})
export class ViewRolesComponent implements OnInit {
  public roleId: any;
  role: any;
  page: 1;
  pageNo: any;

  public response: any;

  submitted = false;
  permissionRequired = false;
  _roleObservable: any;

  rolePermissions = [
    {
      label: "Members",
      name: "member_mngnt",
      isChecked: false,
    },
    {
      label: "Categories",
      name: "cat_mnmt",
      isChecked: false,
    },
    {
      label: "Blog Management",
      name: "blog_mgmt",
      isChecked: false,
    },
    {
      label: "Role Management",
      name: "role_mgmt",
      isChecked: false,
    },
    {
      label: "Plan Management",
      name: "plan_mgnt",
      isChecked: false,
    },
  ];
  ngxNotificationMsgService: any;

  constructor(
    private _activateRouter: ActivatedRoute,
    private roleService: RolesService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.roleId = this._activateRouter.snapshot.params["id"];
    this.pageNo = this._activateRouter.snapshot.params["page"];
  }

  ngOnInit() {
    this.roleId = this._activateRouter.snapshot.params["id"];
    this.page = this._activateRouter.snapshot.params["page"];
    if (this.roleId) {
      this.spinner.show();
      this.roleService.get(this.roleId).subscribe(
        (res: any) => {
          if (res.success) {
            this.role = res.data.label;
            if (res.data.permission.length > 0) {
              this.rolePermissions = res.data.permission;
            }
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: "",
              messages: ["Some error occur. Please try again later."],
            });
          }
        },
        (error) => {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [error],
          });
        }
      );
    }
  }
}
