import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxNotificationMsgModule } from "ngx-notification-msg";
import { ErrorInterceptor } from "./shared/error.interceptor";
import { AuthInterceptor } from "./shared/auth-interceptor";
import { BehaviorService } from "./shared/behavior.service";
import { ThemeService } from "ng2-charts";
import {TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxNotificationMsgModule,
  ],
  providers: [
    ThemeService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    BehaviorService,
      { provide: TINYMCE_SCRIPT_SRC, useValue: "tinymce/tinymce.min.js" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
