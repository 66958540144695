// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-add-update-faq',
//   templateUrl: './add-update-faq.component.html',
//   styleUrls: ['./add-update-faq.component.scss']
// })
// export class AddUpdateFaqComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { FaqService } from '../faq.service';

@Component({
  selector: 'app-add-update-faq',
  templateUrl: './add-update-faq.component.html',
  styleUrls: ['./add-update-faq.component.scss']
})
export class AddUpdateFaqComponent implements OnInit {

  public Form: FormGroup;
  submitted = false;
  _faqObservable:any;
  user:any;
  token:any;
  userRole:any;
  faqID=""
  fileToUpload:any;
  dImg = '/assets/img/placeholder.jpg';
  imageBase:any;
  isEdit = false;
  private _formObservable: any;
  pointId: any;
  pageNo=1
  headerTitle: string;
  submitTitle: string;
  
  constructor(
    private formBuilder: FormBuilder,
    private router:Router,
    private spinner: NgxSpinnerService,
    private faqService: FaqService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute
  ) {this.createForm(); }

  ngOnInit() {
    
    this.pageNo = this._activateRouter.snapshot.params['page'];
    this.faqID = this._activateRouter.snapshot.params['id'];

    if (this.faqID) {
      this.headerTitle = 'Update Faq';
      this.submitTitle = 'Update'
      this.spinner.show();
      this.faqService.get(this.faqID).subscribe((res: any) => {
        if (res.success) {
          this.Form.patchValue({
            question: res.data.question.replace(/<.*?>/g, ''),
            answer: res.data.answer.replace(/<.*?>/g, '')
          });
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
         });
        }
        this.spinner.hide();
      },
        error => {
          this.spinner.hide();
          // this.toastr.error(error, 'Error');
        });
    }
    else {
      this.headerTitle = 'Add Faq';
      this.submitTitle = 'Add'
    }
  }


  createForm() {
		this.Form = this.formBuilder.group({
      question: ['', [Validators.required, Validators.pattern(/^((?!\s{2,}).)*$/)]],
      answer: ['', [Validators.required, Validators.pattern(/^((?!\s{2,}).)*$/)]]
    });
  }
  get f() { return this.Form.controls; }

  // onSubmit() {
  //   this.submitted = true;
  //   if (!this.Form.invalid) {
  //     this.spinner.show();
  //     if(this.pointId){
  //       let data= this.Form.value;
  //       data['id'] = this.pointId;
  //       this._formObservable = this.faqService.updatePoint(data).subscribe(res => {
  //         if (res.success) {
  //           this.ngxNotificationMsgService.open({
  //             status: NgxNotificationStatusMsg.SUCCESS,
  //             header: '',
  //             messages: [res.message]
  //          });
  //          this.router.navigate(['/points']);
  //         } else {
          
  //          this.ngxNotificationMsgService.open({
  //           status: NgxNotificationStatusMsg.FAILURE,
  //           header: '',
  //           messages: [res.error.message]
  //        });
  //         }     
  //         this.spinner.hide();   
  //       },
  //         error => {
  //            this.spinner.hide();
  //           this.ngxNotificationMsgService.open({
  //             status: NgxNotificationStatusMsg.FAILURE,
  //             header: '',
  //             messages: [error]
  //          });
  //         }
  //       );
  //     }else{
  //       this._formObservable = this.pointsMngmtService.addPoint(this.Form.value).subscribe(res => {
  //         if (res.success) {
          
  //           this.ngxNotificationMsgService.open({
  //             status: NgxNotificationStatusMsg.SUCCESS,
  //             header: '',
  //             messages: [res.message]
  //          });
  //          this.router.navigate(['/points']);
  //         } else {
          
  //          this.ngxNotificationMsgService.open({
  //           status: NgxNotificationStatusMsg.FAILURE,
  //           header: '',
  //           messages: [res.error.message]
  //        });
  //         }     
  //         this.spinner.hide();   
  //       },
  //         error => {
  //            this.spinner.hide();
  //           this.ngxNotificationMsgService.open({
  //             status: NgxNotificationStatusMsg.FAILURE,
  //             header: '',
  //             messages: [error]
  //          });
  //         }
  //       );
  //     }
     
  //   }
  // }
  onSubmit() {
    this.submitted = true;
    if (!this.Form.invalid) {
      this.spinner.show();

      if (this.faqID) {
        this._faqObservable = this.faqService.update(this.Form.value, this.faqID).subscribe(res => {
          let url;
          if (res.success) {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
           });
            url = '/faq/faq/'+this.pageNo;
            this.router.navigate([url]);
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [res.error.message]
           });
          }
          this.spinner.hide();
        },
          error => {
            this.spinner.hide();
            // this.toastr.error(error, 'Error');
          });

      } else {
        this._faqObservable = this.faqService.add(this.Form.value).subscribe(res => {
          let url;
          if (res.success) {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
           });
            url = '/faq';
            this.router.navigate([url]);
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [res.error.message]
           });
          }
          this.spinner.hide();
        },
          error => {
            this.spinner.hide();
            // this.toastr.error(error, 'Error');
          });
      }
    }
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this._formObservable){
      this._formObservable.unsubscribe();
    }
  }

}
