import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MembersService } from '../members.service';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';

@Component({
  selector: 'app-add-update-members',
  templateUrl: './add-update-members.component.html',
  styleUrls: ['./add-update-members.component.scss']
})
export class AddUpdateMembersComponent implements OnInit {
  emailexist:any
  public Form: FormGroup;
  submitted = false;
  _roleObservable:any;
  user:any;
  token:any;
  ID:any;
  userRole:any;
  fileToUpload:any;
  dImg = '/assets/img/placeholder.jpg';
  imageBase:any;
  isEdit = false;
  private _formObservable: any;
  memberId: any;
  headerTitle: string;
  submitTitle: string;
  ownRV:any="Owner"
  cities: any=[];
  allstates: any=[];
  userdata: any;
  constructor(
    private formBuilder: FormBuilder,
    private router:Router,
    private spinner: NgxSpinnerService,
    private memberService: MembersService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute
  ) {this.getStates();this.createForm(); }

  ngOnInit() {
    
    this.memberId = this._activateRouter.snapshot.params['id'];
    // this.pageNo = this._activateRouter.snapshot.params['page'];

    if (this.memberId) {
      // this.Form.controls['email'].disable();
      this.headerTitle = 'Update Member';
      this.submitTitle = 'Update'
      this.spinner.show();
      this.memberService.get(this.memberId).subscribe((res: any) => {
        if (res.success) {
          this.userdata=res.data[0]
          this.Form.patchValue({
            firstName: res.data[0].firstName,
            lastName: res.data[0].lastName,
            role: res.data[0].role,
            email: res.data[0].email,
            mobileNo: res.data[0].mobileNo,
            stateServed:res.data[0].stateServed,
            companyName:res.data[0].companyName,
            unclaimedName:res.data[0].unclaimedName,
            businessLocationCity: res.data[0].businessLocationCity,
            businessLocationState:res.data[0].businessLocationState,
          });
          this.getCity()
          this.ownRV= res.data[0].ownRV
          this.onChangeOwnRV()
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
         });
        }
        this.spinner.hide();
      },
        error => {
          this.spinner.hide();

          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [error]
         });
        });
    } else {
      this.Form.controls['email'].enable();
      this.headerTitle = 'Add Member';
      this.submitTitle = 'Add'
    }

  }
  ChangeEvent(p:any){
    console.log("p",p)
    // if(p.event=='save'){
    //  this.onFormChanges()
    // }
  }
  getCity() {
    // let value: any = this.Form.value;
    let value: any = this.Form.value;
    let data = {
      // countryCode: value.country == "USA" ? "US" : value.country,
      countryCode: "US",
      stateCode: value.businessLocationState,
    };
    this.memberService.getWithParams("city", data).subscribe(
      (res) => {
        if (res.success) {
          this.cities = res.data;
        }
      },
      (error) => {
      }
    );
  }
  // onChangeOwnRV() {
  //   if (this.ownRV=="Renovator") {
  //     this.Form.get("stateServed").setValidators(Validators.required);
  //     this.Form.get("companyName").setValidators(Validators.required);
  //     this.Form.get("businessLocationCity").setValidators(Validators.required);
  //     this.Form.get("businessLocationState").setValidators(Validators.required);
  //   }
  //   else if (this.ownRV=="RV Repair Companies"){
  //     this.Form.get("stateServed").setValidators(Validators.required);
  //     this.Form.get("companyName").setValidators(Validators.required);
  //     this.Form.get("businessLocationCity").setValidators(Validators.required);
  //     this.Form.get("businessLocationState").setValidators(Validators.required);
  //   }
  //   else{
  //     this.Form.get("stateServed").clearValidators();
  //     this.Form.get("companyName").clearValidators();
  //     this.Form.get("businessLocationCity").clearValidators();
  //     this.Form.get("businessLocationState").clearValidators();
  //   }
  //   this.Form.get("stateServed").updateValueAndValidity();
  //   this.Form.get("companyName").updateValueAndValidity();
  //   this.Form.get("businessLocationCity").updateValueAndValidity();
  //   this.Form.get("businessLocationState").updateValueAndValidity();
  // }

  onChangeOwnRV() {
    const requiredValidators = [Validators.required];
  
    if (this.ownRV === "Renovator" || this.ownRV === "RV Repair Companies") {
      [
        "stateServed",
        "companyName",
        "businessLocationCity",
        "businessLocationState",
      ].forEach((controlName) => {
        this.Form.get(controlName).setValidators(requiredValidators);
        this.Form.get(controlName).updateValueAndValidity();
      });
    } else {
      [
        "stateServed",
        "companyName",
        "businessLocationCity",
        "businessLocationState",
      ].forEach((controlName) => {
        this.Form.get(controlName).clearValidators();
        this.Form.get(controlName).updateValueAndValidity();
      });
    }
  }
  
  getStates(){
    let data={
      countryCode:'US'
    }
    this.memberService.getWithParams('states',data).subscribe((res:any)=>{
      if(res.success){
        this.allstates=res.data
      }else{

      }
    },
      (err) => { })
  }

  createForm() {
		this.Form = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern(/^((?!\s{2,}).)*$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^((?!\s{2,}).)*$/)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%. +-]+@[a-z0-9.-]+\\.[a-z]{2,4}.$")]],
      role:['user'],
      mobileNo: ['', [Validators.min(1000000000),Validators.max(999999999999)]],
      stateServed:[[]],
      unclaimedName:[false],
      companyName:[""],
      businessLocationCity: ["" , Validators.required],
      businessLocationState: ["" , Validators.required],
    });
  }
  get f() { return this.Form.controls; }
  hasRequiredFieldvalue(key) {
    let validator1 = this.Form.controls[key].validator;
    return validator1;
  }
  onSubmit() {
    this.submitted = true;
    console.log(this.Form.controls,"this.Form.controls")
    if (!this.Form.invalid) {
      this.spinner.show();
      let data= this.Form.value;
      data['ownRV']=this.ownRV
   
      if(this.memberId){
        data['id'] = this.memberId;
        // this.checkemail()
        if((this.emailexist==false && (this.Form.value.email!=this.userdata.email)) || this.Form.value.email==this.userdata.email){
          this.updateMember(data)
        }else{
          this.spinner.hide()
          return
        }
       
      }else{
        this._formObservable = this.memberService.addMmember(this.Form.value).subscribe(res => {
          if (res.success) {
          
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
           });
           this.router.navigate(['/member']);
          } else {
          
           this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
         });
          }     
          this.spinner.hide();   
        },
          error => {
             this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [error]
           });
          }
        );
      }
     
    }
  }
  updateMember(data:any)
 
  {
    data['isEmailChange']=this.Form.value.email!=this.userdata.email ?true:false
      this._formObservable = this.memberService.updateMmember(data).subscribe(res => {
        if (res.success) {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
            messages: [res.message]
         });
         this.submitted=false
         this.router.navigate(['/member']);
        } else {
        
         this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [res.error.message]
       });
        }     
        this.spinner.hide();   
      },
        error => {
           this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [error]
         });
        }
      );
    }
  onchangeemail(){
    this.emailexist=false
  }
  checkemail(){
    console.log("in check email")
    let emailexist=false
    let data={
      email:this.Form.value.email
    }
    this.memberService.getWithParams('check/email',data).subscribe((res:any)=>{
      if(res.success){
        emailexist=res.isemailalreadyexist==true?true:false
      }else{
        emailexist=res.isemailalreadyexist==true?true:false
      }
      this.emailexist=emailexist
      console.log(this.emailexist,"this.emailexist")
        // if(this.emailexist==false){
        //   let newdata= this.Form.value;
        //   newdata['ownRV']=this.ownRV
       
        //   if(this.memberId){
        //     newdata['id'] = this.memberId;
        //   }
         
        // }else{
        //   this.spinner.hide()
        // }
    },error=>{
    })
   
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this._formObservable){
      this._formObservable.unsubscribe();
    }
  }

}
