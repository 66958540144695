import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { CredentialsService } from "src/app/auth/credentials.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  user: any;
  constructor(
    private httpClient: HttpClient,
    public credentials: CredentialsService
  ) {}

  ngOnInit() {
  }
}
