// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-badge-add-update',
//   templateUrl: './badge-add-update.component.html',
//   styleUrls: ['./badge-add-update.component.scss']
// })
// export class BadgeAddUpdateComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import {
	NgxNotificationMsgService,
	NgxNotificationStatusMsg,
} from "ngx-notification-msg";
import { PointsMngmtService } from "../points-mngmt.service";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-badge-add-update",
	templateUrl: "./badge-add-update.component.html",
	styleUrls: ["./badge-add-update.component.scss"],
})
export class BadgeAddUpdateComponent implements OnInit {
	public Form: FormGroup;
	submitted = false;
	_roleObservable: any;
	user: any;
	token: any;
	badgesData: Array<any> = [];
	ID: any;
	userRole: any;
	fileToUpload: any;
	badgeImage: any;
	badgeExist: Boolean = false;
	dImg = "/assets/img/placeholder.jpg";
	imageBase: any;
	isEdit = false;
	private _formObservable: any;
	badgeId: any;
	headerTitle: string;
	submitTitle: string;
	_host = environment.url;
	filters: {
		page: number;
		count: number;
		search: string;
		role: string;
		isDeleted: Boolean;
	} = {
		page: 1,
		count: 10000,
		search: "",
		role: "user",
		isDeleted: false,
	};

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private spinner: NgxSpinnerService,
		private pointsMngmtService: PointsMngmtService,
		private readonly ngxNotificationMsgService: NgxNotificationMsgService,
		private _activateRouter: ActivatedRoute
	) {
		this.createForm();
	}

	ngOnInit() {
		this.badgeId = this._activateRouter.snapshot.params["id"];
		if(this.badgeId){
			this.Form.get('badge_for').disable()
		}
		// this.pageNo = this._activateRouter.snapshot.params['page'];

		if (this.badgeId) {
			this.headerTitle = "Update Badge";
			this.submitTitle = "Update";
			this.spinner.show();
			this.pointsMngmtService.getBadge(this.badgeId).subscribe(
				(res: any) => {
					if (res.success) {
						this.Form.patchValue({
							name: res.data.name,
							points: res.data.points,
							image: res.data.image,
							badge_for: res.data.badge_for,
						});
						this.badgeImage = res.data.image;
					} else {
						this.ngxNotificationMsgService.open({
							status: NgxNotificationStatusMsg.FAILURE,
							header: "",
							messages: [res.error.message],
						});
					}
					this.spinner.hide();
				},
				(error) => {
					this.spinner.hide();

					this.ngxNotificationMsgService.open({
						status: NgxNotificationStatusMsg.FAILURE,
						header: "",
						messages: [error],
					});
				}
			);
		} else {
			this.headerTitle = "Add Badge";
			this.submitTitle = "Add";
		}
		this.getAllBadges();
	}

	createForm() {
		this.Form = this.formBuilder.group({
			name: ["", [Validators.required, Validators.pattern(/^((?!\s{2,}).)*$/)]],
			image: ["", [Validators.required]],
			points: ["", [Validators.required]],
			badge_for: ["", [Validators.required]],
		});
	}
	get f() {
		return this.Form.controls;
	}

	onSubmit() {
		this.submitted = true;
		if (!this.Form.invalid) {
			// this.badgeExist = this.BadgeExists(
			// 	this.badgesData,
			// 	this.Form.value.badge_for
			// 	// this.Form.value.points
			// );
			console.log(this.badgeExist, "this.validBadge");
			// if (!this.badgeExist) {
				this.spinner.show();
				if (this.badgeId) {
					let data = this.Form.value;
					data["id"] = this.badgeId;
					this._formObservable = this.pointsMngmtService
						.updateBadge(data)
						.subscribe(
							(res) => {
								if (res.success) {
									this.ngxNotificationMsgService.open({
										status: NgxNotificationStatusMsg.SUCCESS,
										header: "",
										messages: [res.message],
									});
									this.router.navigate(["/points"]);
								} else {
									this.ngxNotificationMsgService.open({
										status: NgxNotificationStatusMsg.FAILURE,
										header: "",
										messages: [res.error.message],
									});
								}
								this.spinner.hide();
							},
							(error) => {
								this.spinner.hide();
								this.ngxNotificationMsgService.open({
									status: NgxNotificationStatusMsg.FAILURE,
									header: "",
									messages: [error],
								});
							}
						);
				} else {
					this._formObservable = this.pointsMngmtService
						.addBadge(this.Form.value)
						.subscribe(
							(res) => {
								if (res.success) {
									this.ngxNotificationMsgService.open({
										status: NgxNotificationStatusMsg.SUCCESS,
										header: "",
										messages: [res.message],
									});
									this.router.navigate(["/points"]);
								} else {
									this.ngxNotificationMsgService.open({
										status: NgxNotificationStatusMsg.FAILURE,
										header: "",
										messages: [res.error.message],
									});
								}
								this.spinner.hide();
							},
							(error) => {
								this.spinner.hide();
								this.ngxNotificationMsgService.open({
									status: NgxNotificationStatusMsg.FAILURE,
									header: "",
									messages: [error],
								});
							}
						);
				}
			// }
			// if (this.badgeExist) {
			// 	this.ngxNotificationMsgService.open({
			// 		status: NgxNotificationStatusMsg.FAILURE,
			// 		header: "",
			// 		messages: ["Points are already assigned to another badge."],
			// 	});
			// 	return;
			// }
		}
	}
	BadgeExists(arr, value) {
		return arr.some(function (el) {
			return el.test_badge_for === value;
			// return el.points === value;
		});
	}

	getAllBadges() {
		this.spinner.show();
		// this.isLoading = true;
		// if (this.role) {
		//   Object.assign(this.filters, { role: this.role });
		// }
		this.pointsMngmtService.getAllBadges(this.filters).subscribe(
			(response) => {
				if (response.data.length == 0) {
					this.badgesData = [];
					// this.isLoading = false;
					this.spinner.hide();
					this.ngxNotificationMsgService.open({
						status: NgxNotificationStatusMsg.SUCCESS,
						header: "",
						messages: [response.message],
					});
				} else {
					this.badgesData = response.data.map((data) => {
						let badge_for = "";
						if (data.badge_for == "group_points") {
							badge_for = "Group Points";
						}
						if (data.badge_for == "mod_post_points") {
							badge_for = "Mod Post Points";
						}
						if (data.badge_for == "social_post_points") {
							badge_for = "Social Post Points";
						}
						if (data.badge_for == "profile_points") {
							badge_for = "Profile Points";
						}
						return {
							id: data._id,
							name: data.name,
							image: data.image,
							action: data.action,
							badge_for: badge_for,
							test_badge_for: data.badge_for,
							points: data.points,
							createdAt: data.createdAt,
							deletedAt: data.deletedAt,
							status: data.status,
						};
					});
					// this.isLoading = false;
					this.spinner.hide();
				}
			},
			(error) => {
				this.spinner.hide();
				this.ngxNotificationMsgService.open({
					status: NgxNotificationStatusMsg.FAILURE,
					header: "",
					messages: [error],
				});
			}
		);
	}
	uploadImage(files: FileList) {
		this.fileToUpload = files.item(0);

		this.spinner.show();
		this.pointsMngmtService.uploadImage(this.fileToUpload, "badges").subscribe(
			(res: any) => {
				if (res.success) {
					this.badgeImage = res.data.fullpath;
					this.Form.patchValue({ image: this.badgeImage });
				} else {
					window.scrollTo(0, 0);
					this.ngxNotificationMsgService.open({
						status: NgxNotificationStatusMsg.FAILURE,
						header: "",
						messages: [res.error.message],
					});
				}
				this.spinner.hide();
			},
			(err) => {
				this.spinner.hide();
				this.ngxNotificationMsgService.open({
					status: NgxNotificationStatusMsg.FAILURE,
					header: "",
					messages: [
						"There are some errors, please try again after some time !",
						"Error",
					],
				});
			}
		);
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		if (this._formObservable) {
			this._formObservable.unsubscribe();
		}
	}
}
