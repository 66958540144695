import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { TimeService } from '../time.service';

@Component({
  selector: 'app-add-update-time',
  templateUrl: './add-update-time.component.html',
  styleUrls: ['./add-update-time.component.scss']
})
export class AddUpdateTimeComponent implements OnInit {

  public Form: FormGroup;
  submitted = false;
  _roleObservable:any;
  public _host = environment.url;
  user:any;
  token:any;
  ID:any;
  userRole:any;
  fileToUpload:any;
  dImg = '/assets/img/placeholder.jpg';
  imageBase:any;
  isEdit = false;
  private _formObservable: any;
  addId: any;
  headerTitle: string;
  submitTitle: string;
  userImage: any;
  
  
  constructor(
    private formBuilder: FormBuilder,
    private router:Router,
    private spinner: NgxSpinnerService,
    private timeService: TimeService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute
  ) {this.createForm(); }

  ngOnInit() {
    
    this.addId = this._activateRouter.snapshot.params['id'];
    // this.pageNo = this._activateRouter.snapshot.params['page'];

    if (this.addId) {
      
      this.headerTitle = 'Update Advertisement';
      this.submitTitle = 'Update'
      this.spinner.show();
      this.timeService.get(this.addId).subscribe((res: any) => {
        if (res.success) {
        
          this.Form.patchValue({
            title: res.data.title?res.data.title:'',
            position: res.data.position?res.data.position:'',
            description: res.data.description,
            redirectURL: res.data.redirectURL,
            image:res.data.image
          });
          this.userImage = res.data.image
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
         });
        }
        this.spinner.hide();
      },
        error => {
          this.spinner.hide();

          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [error]
         });
        });
    } else {
      // this.Form.controls['email'].enable();
      this.headerTitle = 'Add Total Time';
      this.submitTitle = 'Add'
    }

  }

  uploadImage(files: FileList) {
    
    this.fileToUpload = files.item(0)
 
    this.spinner.show();
    this.timeService.uploadImage(this.fileToUpload,'advertisement').subscribe((res: any) => {
      if (res.success) {
        this.userImage = res.data.imagePath[0];
        setTimeout(function(){   this.Form.patchValue({ image: this.userImage }) }, 40000000);
        this.Form.patchValue({ image: this.userImage })
      
      } else {
        window.scrollTo(0, 0);
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [res.error.message]
        });
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: '',
        messages: ['There are some errors, please try again after some time !', 'Error']
      });
      
    });
  }

  removeImage(image) {
    this.Form.controls.image.setValue('');
    this.userImage = '';
    let object = {
      imageName: image,
      modelName: 'advertisement'
    }
    this.timeService.deleteImage(object).subscribe((res: any) => {
      if (res.success) {
        this.userImage = '';        
        this.spinner.hide();
      } else {
        // this.toastr.error(res.error.message, 'Error');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }


  createForm() {
		this.Form = this.formBuilder.group({
      totalHours: ['', Validators.required],
      // toHours: ['', Validators.required],
      // image:['', Validators.required],
      // redirectURL:['', [Validators.required,Validators.pattern(reg)]],
      // position:['', Validators.required]
    });
  }
  get f() { return this.Form.controls; }

  onSubmit() {
    this.submitted = true;
   console.log(this.Form);
   
    if (!this.Form.invalid) {
      this.spinner.show();
      // if(this.addId){
        // let data= this.Form.value;
        // data['id'] = this.addId;
        
        // this._formObservable = this.timeService.updateAdd(data,this.addId).subscribe(res => {
        //   if (res.success) {
        //     this.ngxNotificationMsgService.open({
        //       status: NgxNotificationStatusMsg.SUCCESS,
        //       header: '',
        //       messages: [res.message]
        //    });
        //    this.router.navigate(['/advertisements']);
        //   } else {
          
        //    this.ngxNotificationMsgService.open({
        //     status: NgxNotificationStatusMsg.FAILURE,
        //     header: '',
        //     messages: [res.error.message]
        //  });
        //   }     
        //   this.spinner.hide();   
        // },
        //   error => {
        //      this.spinner.hide();
        //     this.ngxNotificationMsgService.open({
        //       status: NgxNotificationStatusMsg.FAILURE,
        //       header: '',
        //       messages: [error]
        //    });
        //   }
        // );
      // }else{
       
        // console.log( this.Form.value," this.Form.value")
        let data= this.Form.value;
       
        this._formObservable = this.timeService.postAdd(data).subscribe(res => {
          if (res.success) {
          
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
           });
           this.router.navigate(['/time-range']);
          } else {
          
           this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
         });
          }     
          this.spinner.hide();   
        },
          error => {
             this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [error]
           });
          }
        );
      // }
     
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this._formObservable){
      this._formObservable.unsubscribe();
    }
  }

}
