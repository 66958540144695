import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ProductsService } from '../products.service';
import { find, get, pull } from 'lodash';
import { HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-add-update-products',
  templateUrl: './add-update-products.component.html',
  styleUrls: ['./add-update-products.component.scss']
})
export class AddUpdateProductsComponent implements OnInit {

  @ViewChild('tagInput', { static: false }) tagInputRef: ElementRef;
  @ViewChild('tagInput2', { static: false }) tagInputRef2: ElementRef;
  @ViewChild('myFileInput', { static: false }) myFileInput
  uploadedImage: any = [];
  progress: number = 0;

  public Form: FormGroup;
  submitted = false;
  public _host = environment.url;
  user: any;
  fileToUpload: any;
  private _formObservable: any;
  productId: any;
  headerTitle: string;
  submitTitle: string;
  parentCategories: any = [];
  productImage: any;
  passeye = false;
  passceye = false;
  public temparr = [];
  public temparr2 = [];
  ipAddresses: any = []

  public vendorsData: Array<any> = [];

  filters: { role: string, status: string,isDeleted:false } = {
    role: 'vendor',
    status: 'active',
    isDeleted: false
  };
  imageLoader: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private productService: ProductsService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute
  ) { this.createForm(); }

  ngOnInit() {
    this.getParentCategories();
    this.getAllVendors();
    this.productId = this._activateRouter.snapshot.params['id'];

    if (this.productId) {

      this.headerTitle = 'Update Product';
      this.submitTitle = 'Update'
      this.spinner.show();
      this.productService.get(this.productId).subscribe((res: any) => {
        if (res.success) {

          this.Form.patchValue({
            name: res.data.name ? res.data.name : '',
            description: res.data.description ? res.data.description : '',
            category_id: res.data.category_id.id,
            price: res.data.price ? res.data.price : 0,
            quantity: res.data.quantity ? res.data.quantity : '',
            vendor: res.data.vendor ? res.data.vendor.id : '',
            tags: res.data.tags ? res.data.tags : []

          });
          this.uploadedImage = res.data.image
          this.ipAddresses = res.data.tags
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [res.error.message]
          });
        }
        this.spinner.hide();
      },
        error => {
          this.spinner.hide();

          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: '',
            messages: [error]
          });
        });
    } else {

      this.headerTitle = 'Add Product';
      this.submitTitle = 'Add'
    }

  }

  getParentCategories() {
    let data = {
      'page': 1,
      'count': 1000,
      'sortBy':'name asc'
    }
    this.productService.getAllCategories(data).subscribe((response) => {
      if (response.success) {
        this.parentCategories = response.data
      }
      this.spinner.hide();

    },
      error => {
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [error]
        });
      }
    );
  }

  getAllVendors() {
    this.spinner.show();

    this.productService.getAllVendors(this.filters).subscribe((response) => {
      if (response.data.length == 0) {
        this.vendorsData = [];
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: '',
          messages: [response.message]
        });
      } else {
        this.vendorsData = response.data.map(data => {
          return {
            id: data._id,
            name: data.fullName
          }
        });

        this.spinner.hide();
      }
    },
      error => {
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [error]
        });
      }
    );
  }


  /***Multiple file upload */

  uploadImage(files) {
    if (files) {
      const formData: FormData = new FormData();
      let uploadedImageArray: any = []
      // this.fileToUpload = files.item(0)
      for (let index = 0; index < files.length; index++) {
        let element = files[index];
        uploadedImageArray.push(element)
        this.fileToUpload = uploadedImageArray;
        formData.append('file', this.fileToUpload[index], this.fileToUpload[index].name);
      }
      formData.append('modelName', 'product');
      // let object = {
      //   data: files.item(0),
      //   type: 'category'
      // }
      // this.myInputVariable.nativeElement.value = "";

      this.imageLoader = true;
      let params = new HttpParams().set('?modelName', 'product')
      this.productService.uploadMultipleImage(params, formData).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            // console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            // console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            // console.log('User successfully created!', event.body);
            if (event.body.success) {

              if (this.uploadedImage.length > 0) {
                event.body.data.forEach(element => {
                  this.uploadedImage.push(element.fullpath)
                });
              } else {
                event.body.data.forEach(element => {
                  this.uploadedImage.push(element.fullpath)
                });
                // this.uploadedImage = event.body.data.imagePath
              }
              this.Form.patchValue({ image: this.uploadedImage })
              this.myFileInput.nativeElement.value = '';
              this.imageLoader = false;
            } else {
              window.scrollTo(0, 0);
             
              this.imageLoader = false;
            }
            setTimeout(() => {
              this.progress = 0;
              this.imageLoader = false;
            }, 100);

        }

        // this.imageLoader=false;
      }, err => {
        this.imageLoader = false;
        // this.toastr.error('There are some errors, please try again after some time !', 'Error');
      });



    }

  }
  removeImage(image) {
    this.Form.controls.image.setValue('');
    this.productImage = '';
    this.uploadedImage.pop(image)
    // let object = {
    //   imagename: image,
    //   modelName: 'product'
    // }
    // this.productService.deleteImage(object).subscribe((res: any) => {
    //   if (res.success) {
    //     this.productImage = '';
    //     this.spinner.hide();
    //   } else {
    //     // this.toastr.error(res.error.message, 'Error');
    //   }
    //   this.spinner.hide();
    // }, err => {
    //   this.spinner.hide();
    // });
  }


  focusTagInput(): void {
    this.tagInputRef.nativeElement.focus();
  }

  onKeyUp(event: KeyboardEvent): void {
    const inputValue: string = this.Form.controls.tags.value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag();
      return;
    } else {

      if (event.code === 'Comma') {
        console.log(event)
        if (!this.Form.invalid) {
          this.addTag(inputValue);
          this.Form.controls.tags.setValue('');
        }
      }

    }
  }

  addTag(tag: string): void {
    if (tag[tag.length - 1] === ',') {
      tag = tag.slice(0, -1);
    }
    if (tag.length > 0 && !find(this.ipAddresses, tag)) {
      //  this.register2.socialLinks.push(tag);
      this.temparr.push(tag);
      this.ipAddresses = this.temparr;
      console.log(this.ipAddresses)
    }
  }

  removeTag(tag?: string): void {
    if (!!tag) {
      pull(this.ipAddresses, tag);
    } else {
      this.ipAddresses.splice(-1);
    }
  }


  createForm() {
    this.Form = this.formBuilder.group({
      name: ['', Validators.required],
      category_id: ['', Validators.required],
      quantity : ['', [Validators.required, Validators.min(1),Validators.max(999999999999)]],
      price: ['', [Validators.required, Validators.min(1),Validators.max(999999999999)]],
      vendor: ['', Validators.required],
      description: [''],
      image: [],
      tags: []
    });
  }
  get f() { return this.Form.controls; }

  onSubmit() {
    this.submitted = true;
    if (!this.Form.invalid) {
      this.spinner.show();
      if (this.productId) {

        let data = this.Form.value;
        data['id'] = this.productId;
        data.tags = this.ipAddresses
        data.image = this.uploadedImage 
        this._formObservable = this.productService.updateProduct(data).subscribe(res => {
          if (res.success) {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
            });
            this.router.navigate(['/products']);
          } else {

            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [res.error.message]
            });
          }
          this.spinner.hide();
        },
          error => {
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [error]
            });
          }
        );
      } else {
       
        this.Form.patchValue({
          tags: this.ipAddresses,
        });
        this._formObservable = this.productService.addProduct(this.Form.value).subscribe(res => {
          if (res.success) {

            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: '',
              messages: [res.message]
            });
            this.router.navigate(['/products']);
          } else {

            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [res.error.message]
            });
          }
          this.spinner.hide();
        },
          error => {
            this.spinner.hide();
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: '',
              messages: [error]
            });
          }
        );
      }

    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this._formObservable) {
      this._formObservable.unsubscribe();
    }
  }


}
