// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: "https://stagingapi.rvmodshare.com/",
  chat_url: "https://stagingchat.rvmodshare.com/",
  front_url: "https://staging.rvmodshare.com/",
  admin_url:"https://stagingadmin.rvmodshare.com/",
  // url: "http://74.208.51.191:4023/",
  // url: 'https://endpoint.rvmodshare.com/',
  // url: "http://198.251.65.146:4055/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
