import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from "@swimlane/ngx-datatable";
import {
  NgxNotificationMsgService,
  NgxNotificationStatusMsg,
} from "ngx-notification-msg";
import { NgxSpinnerService } from "ngx-spinner";
import { CredentialsService } from "src/app/auth/credentials.service";
import { RolesService } from "../roles.service";

@Component({
  selector: "app-listing-roles",
  templateUrl: "./listing-roles.component.html",
  styleUrls: ["./listing-roles.component.scss"],
})
export class ListingRolesComponent implements OnInit {
  columns = [];
  ColumnMode = ColumnMode;
  _subscriberData: any;
  public rolesData: Array<any> = [];
  public response: any;
  public response2: any;

  totalItems = 0;
  page = 1;
  filters: { page: number; count: number; search: string } = {
    page: 1,
    count: 10,
    search: "",
  };

  constructor(
    private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private credentialService: CredentialsService,
    private router: Router,
    private roleService: RolesService
  ) {}

  ngOnInit() {
    // this.filters.addedBy = this.credentialService.credentials.id;
    // this.filters.manager_id = this.credentialService.credentials.manager_id;
    if (this._activateRouter.snapshot.params["page"]) {
      this.filters.page = JSON.parse(
        this._activateRouter.snapshot.params["page"]
      );
      this.page = this.filters.page;
      console.log(
        this.filters.page,
        typeof this._activateRouter.snapshot.params["page"]
      );
      Object.assign(this.filters, { page: this.filters.page });
      this.getAllRoles();
    } else {
      this.page = 1;
      this.getAllRoles();
    }
  }

  getAllRoles() {
    this.spinner.show();
    this._subscriberData = this.roleService.getAllRoles(this.filters).subscribe(
      (response) => {
        if (response.data.length == 0) {
          this.rolesData = [];
          this.totalItems = response.total;
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: "",
            messages: [response.message],
          });
        } else {
          this.rolesData = response.data.map((data) => {
            return {
              id: data._id,
              date: data.createdAt,
              role: data.name,
              label: data.label,
              createdAt: data.createdAt,
              updatedAt: data.updatedAt,
              status: data.status,
            };
          });
          this.totalItems = response.total;
          // this.isLoading = false;
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: "",
          messages: [error],
        });
      }
    );
  }

  setPage(e) {
    console.log(e);
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: this.page });
    // let route = '/companies/companies/' + this.page;
    // this.router.navigate([route]);
    this.getAllRoles();
  }

  searchValue() {
    this.page = 1;
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getAllRoles();
  }

  clearValue() {
    this.page = 1;
    this.filters.search = "";
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getAllRoles();
  }

  view(roleId) {
    // this.companiesService.actionType.next(type)
    let route = "/roles/view/" + roleId + "/" + this.page;
    this.router.navigate([route]);
  }

  edit(ID) {
    console.log(ID);
    let route = "/roles/edit/" + ID + "/" + this.page;
    this.router.navigate([route]);
  }

  /* Function use to remove Crop with crop id */
  deleteRecord(ID) {
    if (confirm("Do you want to delete this member?")) {
      this.spinner.show();

      let obj = {
        id: ID,
        model: "users",
      };

      this.roleService.deleteRecord(obj).subscribe(
        (res: any) => {
          if (res.success) {
            this.response = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.message],
            });
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.error.message],
            });
          }
          this.getAllRoles();
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: "",
            messages: [err],
          });
        }
      );
    }
  }

  changeStatus(data, ID) {
    let Status = "";
    let message = "Do you want to activate this  member?";
    if (data == "active") {
      Status = "deactive";
      message = "Do you want to deactivate this  member?";
    } else {
      Status = "active";
    }

    if (confirm(message)) {
      this.spinner.show();
      this.roleService.status(ID, "users", Status).subscribe(
        (res: any) => {
          if (res.success) {
            this.response2 = res;
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.SUCCESS,
              header: "",
              messages: [res.message],
            });

            this.getAllRoles();
          } else {
            this.ngxNotificationMsgService.open({
              status: NgxNotificationStatusMsg.FAILURE,
              header: "",
              messages: [res.error.message],
            });
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [err],
          });
        }
      );
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this._subscriberData) {
      this._subscriberData.unsubscribe();
    }
  }
}
