import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";

import { ContestMgmtRoutingModule } from "./contest-mgmt-routing.module";
import { AddUpdateComponent } from "./add-update/add-update.component";
import { ListContestComponent } from "./list-contest/list-contest.component";
import { ViewContestComponent } from "./view-contest/view-contest.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgxSpinnerModule } from "ngx-spinner";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { ViewModsComponent } from './view-mods/view-mods.component';
import { ModsNotInContestComponent } from './mods-not-in-contest/mods-not-in-contest.component';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
    AddUpdateComponent,
    ListContestComponent,
    ViewContestComponent,
    ViewModsComponent,
    ModsNotInContestComponent,
  ],
  imports: [
    CommonModule,
    ContestMgmtRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxSpinnerModule,
     OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgbDropdownModule,
  ],
  providers:[
  
    DatePipe
  ]
})
export class ContestMgmtModule {}
