// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-list-faq',
//   templateUrl: './list-faq.component.html',
//   styleUrls: ['./list-faq.component.scss']
// })
// export class ListFaqComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { FaqService } from '../faq.service';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';

@Component({
  selector: 'app-list-faq',
  templateUrl: './list-faq.component.html',
  styleUrls: ['./list-faq.component.scss']
})
export class ListFaqComponent implements OnInit {
actionType:any = 'active'
  public faqData: Array<any> = [];
  public response: any;
  public response2: any;
  totalItems:number=0
  rows = [];
  columns = [];
  ColumnMode = ColumnMode;
  _subscriberData: any;
  isLoading: boolean = false;
  page = 1;
  isDeleted = false
  filters: { page: number; search: string, isDeleted: boolean } = { page: 1, search: '', isDeleted: false };

  constructor(
    private spinner: NgxSpinnerService,
    private faqService: FaqService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private router: Router,
    private _activateRouter:ActivatedRoute
  ) {
    // this.faqService.actionType.subscribe(res=>{
     
    //   if(res!=='' && res!==undefined && res!==null){
    //     this.actionType = res
    //     if(this.actionType=='active'){
    //       this.filters.isDeleted = false;
        
    //     }else if(this.actionType=='deleted'){
    //       this.filters.isDeleted = true;
    //     }
    //   }else{
    //     this.actionType='active'
    //   }
    // })
    
   }

  ngOnInit(): void {
    if(this._activateRouter.snapshot.params['page']){
     
      this.filters.page = JSON.parse(this._activateRouter.snapshot.params['page'])  ;
      this.page  = this.filters.page; 
      Object.assign(this.filters, { page: this.filters.page });  
      this.getFaqs(); 
    }else{ 
      this.page = 1
      this.getFaqs(); 
    } 
    
  }

  edit(ID,type) {
    this.faqService.actionType.next(type)
    let route = '/faq/edit/' + ID+"/"+this.page;
    this.router.navigate([route]);
  }
  add() {
    let route = '/faq/add/';
    this.faqService.actionType.next('active')
    this.router.navigate([route]);
  }
  view(ID,type) {
    this.faqService.actionType.next(type) 
    let route = '/faq/list/' + ID+ "/"+this.page;
  
    this.router.navigate([route]);
  }

  /* Function use to remove Crop with crop id */
  remove(ID) {
    if (confirm("Do you want to delete this FAQ ?")) {
      this.spinner.show();

      // let obj = {
      //   id: ID,
      //   model: 'faq'
      // }
       let obj = {
        id: ID
      }

      this.faqService.deleteRecord(obj).subscribe((res: any) => {
        if (res.success) {
          this.response = res;
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
            messages: [res.message]
          });
           this.getFaqs();
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
            messages: [res.error.message]
         });
        }
       
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: '',
          messages: [err]
       });
    })
  }
}

  /* Function use to remove Crop with crop id */
  moveBack(ID) {
    if (confirm("Do you want to move back this FAQ to listing ?")) {
      this.spinner.show();

      let obj = {
        id: ID,
        model: 'faq'
      }

      this.faqService.moveBackRecord(obj).subscribe((res: any) => {
        if (res.success) {
          this.response = res;
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
            messages: [res.message]
         });
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
            messages: [res.error.message]
         });
        }
        this.getFaqs();
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: '',
          messages: [err]
       });
      });
    }
  }

  getFaqs() {
    this.spinner.show();
    // if (isDeleted) {
    //   this.isDeleted = isDeleted
    //   Object.assign(this.filters, { isDeleted: isDeleted });
    // }
    this._subscriberData = this.faqService.getAllFaqs(this.filters).subscribe((response) => {
      if (response.data.length == 0) {
        this.faqData = [];
      } else {
        this.faqData = response.data.map(cat => {
          return {
            id: cat.id,
            question: cat.question,
            answer: cat.answer,
            status: cat.status,
            createdAt: cat.createdAt
          }
        });
         this.totalItems = response.total
      } 
      this.spinner.hide();
    });
  }

  /* Function use to remove Crop with crop id */
  removePermanent(ID) {
    if (confirm("Do you want to delete this FAQ permanently?")) {
      this.spinner.show();

      let obj = {
        id: ID,
        model: 'faq'
      }

      this.faqService.deleteRecordPermanent(obj).subscribe((res: any) => {
        if (res.success) {
          this.response = res;
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
            messages: [res.message]
         });
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
            messages: [res.error.message]
         });
        }
        this.getFaqs();
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: '',
          messages: [err]
       });
      });
    }
  }

  changeStatus(data, ID) {
    let Status = '';
    let message = 'Do you want to activate this FAQ?';
    if (data == 'active') {
      Status = "deactive";
      message = 'Do you want to deactivate this FAQ?';
    } else {
      Status = "active";
    }

    if (confirm(message)) {
      this.spinner.show();
      this.faqService.status(ID, 'faq', Status).subscribe((res: any) => {
        if (res.success) {
          this.response2 = res;
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
            messages: [res.message]
         });
          this.getFaqs();
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
            messages: [res.error.message]
         });
        }
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: '',
          messages: [err]
       });
      });
    }
  }

  setPage(e) { 
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: this.page });
    let route = '/faq/faq/' + this.page;
    this.router.navigate([route]);
    this.getFaqs();
  }

  searchValue() {
    this.page = 1;
    Object.assign(this.filters, { page: this.page, search: this.filters.search });
    this.getFaqs();
  }

  clearValue() {
    this.page = 1;
    this.filters.search = '';
    Object.assign(this.filters, { page: this.page, search: this.filters.search });
    this.getFaqs();
  }
}
