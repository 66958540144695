import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectCheckAllComponent } from './select-check-all/select-check-all.component';
import { MatCheckboxModule, MatSelectModule } from '@angular/material';



@NgModule({
  imports: [
    MatSelectModule, MatCheckboxModule
  ],
  exports: [
    MatSelectModule, MatCheckboxModule, SelectCheckAllComponent
  ],
  declarations: [SelectCheckAllComponent]
})
export class CustomMaterialModule { }
