import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "src/app/auth/credentials.service";
import { GroupListingService} from "../group-listing.service"
import {
  NgxNotificationMsgService,
  NgxNotificationStatusMsg,
} from "ngx-notification-msg";

@Component({
   selector: 'app-group-post',
  templateUrl: './group-post.component.html',
  styleUrls: ['./group-post.component.scss']
})
export class GroupPostComponent implements OnInit {
  Allgroup = [];
  Allgroup1 = [];
  @Input() groupId: any
  @Input() posts: any
   @Input() totalPost :any
  columns = [];
  ColumnMode = ColumnMode;
  page: number;
  totalItems = 0;
  filters: {
    page: number;
    count: number;
    search: string;
    role: string;
    isDeleted: Boolean;
  } = {
    page: 1,
    count: 10,
    search: "",
    role: "",
    isDeleted: false,
  };

  constructor(  private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private credentialService: CredentialsService,
    private router: Router,
    private groupservice: GroupListingService ) { }

  ngOnInit() {
    console.log(this.groupId,"group id in group post")
  // this.getGroupPosts() 
 

  }

  
  viewGroup(slug) {
    console.log("testdata",slug)
    // this.companiesService.actionType.next(type)
    let route = "/groups/"+slug ;
    this.router.navigate([route]);
  }
  getGroupPosts() {
    this.spinner.show();
    let data = this.filters
    data['groupId']=this.groupId
    this.groupservice.getGroupPosts(this.filters).subscribe(
      (res: any) => {
        
        this.posts=res.data.map((data) => {
          console.log(data.isPrivate,"data.isPrivate")
           return {
            id: data.id,
            name: data.name,
            createdAt: data.createdAt,
            addedBy: data.userDetails.fullName,
            addedById:data.userDetails.id
          };
        
        });
         
        this.totalPost = res.total;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();

        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: "",
          messages: [error],
        });
      }
    );
  }

   deleteGroupPost(id) {
     
     if (confirm("Do you want to delete this Group post?")) {
        let data = {
          id: id
        };
        this.groupservice.deletePost(data).subscribe(
          (res: any) => {
            if (res.success) {
              this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
                messages: [res.message]
            
              });
              this.getGroupPosts()
            } else {
              this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
            messages: [res.error.message]
         });
            }
          },
          (err) => {
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: '',
          messages: [err]
       });
          }
        );
    }
  }
  searchValue() {
    this.page = 1;
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getGroupPosts();
  }
  setPage(e) {
    console.log(e);
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: this.page });
    // let route = '/companies/companies/' + this.page;
    // this.router.navigate([route]);
    this.getGroupPosts();
  }

  clearValue() {
    this.page = 1;
    this.filters.search = "";
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getGroupPosts();
  }
}
