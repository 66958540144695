import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  NgxNotificationMsgService,
  NgxNotificationStatusMsg,
} from "ngx-notification-msg";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { ContestMgmtService } from "../contest-mgmt.service";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: "app-view-contest",
  templateUrl: "./view-contest.component.html",
  styleUrls: ["./view-contest.component.scss"],
})
export class ViewContestComponent implements OnInit {
  contestId: any;
  contestData: any = {};
  public _host = environment.url;
  detailsHTML;
  votingHTML;

  constructor(
    private contestMgmtService: ContestMgmtService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute, private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.contestId = this._activateRouter.snapshot.params["id"];
    this.getDetail();
  }

  getDetail() {
    this.spinner.show();
    this.contestMgmtService.get(this.contestId).subscribe(
      (res: any) => {
        if (res.success) {
          // this.memberData = res.data[0];
          this.contestData = res.data;
          this.detailsHTML = this.sanitizer.bypassSecurityTrustHtml(res.data.details);
          this.votingHTML = this.sanitizer.bypassSecurityTrustHtml(res.data.votingDescription);
        } else {
          this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.FAILURE,
            header: "",
            messages: [res.error.message],
          });
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();

        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: "",
          messages: [error],
        });
      }
    );
  }

  viewPosts(userId) {
    // this.companiesService.actionType.next(type)
    let route = "/member/posts/" + userId;
    this.router.navigate([route]);
  }
}

