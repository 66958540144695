import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingContentComponent } from './listing-content/listing-content.component';
import { UpdateContentComponent } from './update-content/update-content.component';
import { ViewContentComponent } from './view-content/view-content.component';
import { ContentMgmtRoutingModule } from './content-mgmt-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxCkeditorModule } from "ngx-ckeditor4";

@NgModule({
  declarations: [
    ListingContentComponent, 
    UpdateContentComponent, 
    ViewContentComponent,
  ],

  
  imports: [
    CommonModule,  
    ContentMgmtRoutingModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxCkeditorModule.forRoot({
      url: "https://cdn.bootcss.com/ckeditor/4.11.3/ckeditor.js",
      config: {
        filebrowserUploadMethod: "xhr",
        filebrowserUploadUrl: "http://127.0.0.1:8000/index/index/uploads"
      }
    }),
 
  
  ]
})
export class ContentMgmtModule { }
