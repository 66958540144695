import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { NgxSpinnerService } from 'ngx-spinner';
import { VendorsService } from '../vendors.service';

@Component({
  selector: 'app-view-vendors',
  templateUrl: './view-vendors.component.html',
  styleUrls: ['./view-vendors.component.scss']
})
export class ViewVendorsComponent implements OnInit {
  vendorId: any;
  vendorData:any;
  constructor(
    private vendorService: VendorsService,
    private spinner: NgxSpinnerService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute
  ) { }

  ngOnInit() {
    this.vendorId = this._activateRouter.snapshot.params['id'];
    this.getDetail();
  }

  getDetail(){
    this.spinner.show();
    this.vendorService.get(this.vendorId).subscribe((res: any) => {
      if (res.success) {
      this.vendorData = res.data;
        
      } else {
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [res.error.message]
       });
      }
      this.spinner.hide();
    },
      error => {
        this.spinner.hide();
  
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: '',
          messages: [error]
       });
      });
  }

}
