import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "src/app/auth/credentials.service";
// import { MembersService } from "../gr.service";
import { GroupListingService} from "../group-listing.service"
import {
  NgxNotificationMsgService,
  NgxNotificationStatusMsg,
} from "ngx-notification-msg";

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent implements OnInit {
  Allgroup = [];
  Allgroup1 = [];
  columns = [];
  ColumnMode = ColumnMode;
  totalCount: any;
  page: number;
  totalItems = 0;
  filters: {
    page: number;
    count: number;
    search: string;
    role: string;
    isDeleted: Boolean;
  } = {
    page: 1,
    count: 10,
    search: "",
    role: "",
    isDeleted: false,
  };

  constructor(  private spinner: NgxSpinnerService,
    private _activateRouter: ActivatedRoute,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private credentialService: CredentialsService,
    private router: Router,
    private groupservice: GroupListingService ) { }

  ngOnInit() {
  this.getGroup() 
 

  }

  
  viewGroup(slug) {
    console.log("testdata",slug)
    // this.companiesService.actionType.next(type)
    let route = "/groups/"+slug ;
    this.router.navigate([route]);
  }
  getGroup() {
    this.spinner.show();
    this.groupservice.getAllGroups(this.filters).subscribe(
      (res: any) => {
        
        this.Allgroup=res.data
        this.Allgroup.map((data) => {
          console.log(data.isPrivate,"data.isPrivate")
          return {
            id: data.id,
            slug: data.slug,
              //  groupType: "Private",
            isPrivate: data.isPrivate,
            name: data.name
          };
        
        });
        this.totalCount = res.total;
      },
      (error) => {
        this.spinner.hide();

        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: "",
          messages: [error],
        });
      }
    );
  }

   deleteGroup(id) {
     
     if (confirm("Do you want to delete this Group?")) {
        let data = {
          id: id
        };
        this.groupservice.deleteGroup(data).subscribe(
          (res: any) => {
            if (res.success) {
              this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
                messages: [res.message]
            
              });
              this.getGroup()
            } else {
              this.ngxNotificationMsgService.open({
            status: NgxNotificationStatusMsg.SUCCESS,
            header: '',
            messages: [res.error.message]
         });
            }
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            this.spinner.hide();
        this.ngxNotificationMsgService.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: '',
          messages: [err]
       });
          }
        );
    }
  }
  searchValue() {
    this.page = 1;
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getGroup();
  }
  setPage(e) {
    console.log(e);
    this.page = e.offset + 1;
    Object.assign(this.filters, { page: this.page });
    // let route = '/companies/companies/' + this.page;
    // this.router.navigate([route]);
    this.getGroup();
  }

  clearValue() {
    this.page = 1;
    this.filters.search = "";
    Object.assign(this.filters, {
      page: this.page,
      search: this.filters.search,
    });
    this.getGroup();
  }
}
