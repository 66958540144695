import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.scss']
})
export class ViewReportComponent implements OnInit {
  postId: any;
  postData:any;
  public _host = environment.url;
  constructor(
    private spinner: NgxSpinnerService,
    private readonly ngxNotificationMsgService: NgxNotificationMsgService,
    private _activateRouter: ActivatedRoute,   private reportService: ReportsService
  ) { }
  
  ngOnInit() {
    this.postId = this._activateRouter.snapshot.params['id'];
    this.productDetail();
  }

  productDetail(){
    this.spinner.show();
    this.reportService.get(this.postId).subscribe((res: any) => {
      if (res.success) {
      this.postData = res.data;
      } else {
      }
      this.spinner.hide();
    },
      error => {
        this.spinner.hide();

      });
  }
  getUrl(img,detail) {

    let image = '';
    if(img  && ( detail.gId || detail.fbId) && (detail.gId!="" || detail.fbId!="")) {
      image = img;
    }
    else {
        image = this._host + img;
    }
    return image

  }
 }
