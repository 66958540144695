import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VendorsRoutingModule } from './vendors-routing.module';
import { ListingVendorsComponent } from './listing-vendors/listing-vendors.component';
import { AddUpdateVendorsComponent } from './add-update-vendors/add-update-vendors.component';
import { ViewVendorsComponent } from './view-vendors/view-vendors.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [ListingVendorsComponent, AddUpdateVendorsComponent, ViewVendorsComponent],
  imports: [
    CommonModule,
    VendorsRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxSpinnerModule
  ]
})

export class VendorsModule { }
