import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { UpdateContentComponent } from "../content-mgmt/update-content/update-content.component";
import { AddUpdateComponent } from "./add-update/add-update.component";
import { ListContestComponent } from "./list-contest/list-contest.component";
import { ModsNotInContestComponent } from "./mods-not-in-contest/mods-not-in-contest.component";
import { ViewContestComponent } from "./view-contest/view-contest.component";
import { ViewModsComponent } from "./view-mods/view-mods.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: ListContestComponent,
      },
      {
        path: "list",
        component: ListContestComponent,
      },
      {
        path: "list/:id",
        component: ViewContestComponent,
      },
      {
        path: "edit/:id",
        component: AddUpdateComponent,
      },
      {
        path: "edit/:id/:page",
        component: AddUpdateComponent,
      },
      {
        path: "add",
        component: AddUpdateComponent,
      },
      {
        path: "view/:id",
        component: ViewContestComponent,
      },
      {
        path: "view/:id/:page",
        component: ViewContestComponent,
      },
      {
        path: "view/mods/:id/:page",
        component: ViewModsComponent,
      },
      {
        path: "assign/mods/:id/:page",
        component: ModsNotInContestComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContestMgmtRoutingModule {}
