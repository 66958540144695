import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { GroupListingRoutingModule } from './group-listing-routing.module';
import { GroupListComponent } from './group-list/group-list.component';
import { GroupFileComponent } from './group-file/group-file.component';
import { GroupMemberComponent } from './group-member/group-member.component';
import { GroupPostComponent } from './group-post/group-post.component';
import { ViewGroupComponent } from './view-group/view-group.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [GroupListComponent, GroupFileComponent, GroupMemberComponent, GroupPostComponent, ViewGroupComponent],
  imports: [
    CommonModule,
    NgxDatatableModule,
    GroupListingRoutingModule,
    NgxSpinnerModule,
    FormsModule, 
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxSpinnerModule
  ]
})
export class GroupListingModule { }
