import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddUpdatewelcomeComponent } from './add-updatewelcome/add-updatewelcome.component';


const routes: Routes = [
  {
	  path: '',
	  children: [
	   {
		  path: '',
		  component: AddUpdatewelcomeComponent  
		}
	  ]
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WelcomepageRoutingModule { }
