import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import{ GroupFileComponent } from '../group-listing/group-file/group-file.component';
import{ GroupMemberComponent } from '../group-listing/group-member/group-member.component';
import{ GroupPostComponent } from '../group-listing/group-post/group-post.component'
import{GroupListComponent } from '../group-listing/group-list/group-list.component'
import { ViewGroupComponent } from './view-group/view-group.component';


const routes: Routes = [
  {
    path: '',
    data: {
      title: 'grouplisting'
    },
    children: [
      {
        path: '',
        component: GroupListComponent,
        data: {
          title: 'grouplist'
        }
      },
     {
        path: 'groups',
        component: GroupListComponent,
        data: {
          title: 'grouplist'
        }
      },
      {
        path: 'Groupfile',
        component: GroupFileComponent,
        data: {
          title: 'Groupfile'
        }
      },
      {
        path: 'groupmember',
        component: GroupMemberComponent,
        data: {
          title: 'groupmember'
        }
      },
       {
        path: ':id',
        component: ViewGroupComponent,
        data: {
          title: 'groupmember'
        }
      },
         {
        path: 'grouppost',
        component: GroupPostComponent,
        data: {
          title: 'grouppost'
        }
      }
      ]
      }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GroupListingRoutingModule { }
