import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductsRoutingModule } from './products-routing.module';
import { AddUpdateProductsComponent } from './add-update-products/add-update-products.component';
import { ListingProductsComponent } from './listing-products/listing-products.component';
import { ViewProductsComponent } from './view-products/view-products.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [AddUpdateProductsComponent, ListingProductsComponent, ViewProductsComponent],
  imports: [
    CommonModule,
    ProductsRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    NgxDatatableModule,
    NgxSpinnerModule
  ]
})
export class ProductsModule { }
